import { __assign, __rest } from "tslib";
import React from 'react';
import { Link, NotificationBar } from '@marvelapp/ui';
function NBBallpark(_a) {
    var clickedCTA = _a.clickedCTA, rest = __rest(_a, ["clickedCTA"]);
    return (React.createElement(NotificationBar, __assign({ bg: "marvelLight", color: "black", hasMenuButtonOnPage: true }, rest),
        "Introducing",
        ' ',
        React.createElement(Link, { color: "slate", hoverColor: "licorice", "data-testid": "ballpark-cta", hasUnderline: true, kind: "notificationBar", onClick: clickedCTA, href: "https://ballparkhq.com/?utm_campaign=Ballpark%20Launch%200422&utm_source=Marvel%20product&utm_medium=dashboard-banner", target: "_blank" }, "Ballpark"),
        ' ',
        "- prototype testing and surveys, powered by video"));
}
export default NBBallpark;
