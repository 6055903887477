import { __makeTemplateObject } from "tslib";
import { border, color, flexbox, grid, layout, opacity, position, shadow, space, typography, } from 'styled-system';
import styled, { css } from '@marvelapp/styled';
import theme, { breakpoints } from '@marvelapp/theme';
import { borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius, cursor, fill, filter, focusBg, focusBorderColor, focusBoxShadow, focusColor, hoverBg, hoverBorderColor, hoverBoxShadow, hoverColor, hoverFill, hoverOpacity, hoverTextDecorationColor, objectFit, overflow, overflowX, overflowY, pointerEvents, textDecorationColor, textDecorationLine, textDecorationThickness, textTransform, textUnderlineOffset, transform, transformOrigin, transition, userSelect, whiteSpace, wordBreak, wordWrap, } from './customProperties';
export var boxStyles = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  box-sizing: border-box;\n\n  @media (min-width: ", ") {\n    &:hover {\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n    }\n\n    &:focus {\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n    }\n  }\n\n  ", ";\n\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  box-sizing: border-box;\n\n  @media (min-width: ", ") {\n    &:hover {\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n    }\n\n    &:focus {\n      ", ";\n      ", ";\n      ", ";\n      ", ";\n    }\n  }\n\n  ", ";\n\n  ", ";\n"])), border, borderBottomLeftRadius, borderBottomRightRadius, borderTopLeftRadius, borderTopRightRadius, color, cursor, fill, filter, flexbox, grid, layout, objectFit, opacity, overflow, overflowX, overflowY, pointerEvents, position, shadow, space, textDecorationColor, textDecorationLine, textDecorationThickness, textTransform, textUnderlineOffset, transform, transformOrigin, transition, typography, userSelect, whiteSpace, wordBreak, wordWrap, breakpoints[0], hoverBg, hoverBorderColor, hoverBoxShadow, hoverColor, hoverFill, hoverOpacity, hoverTextDecorationColor, focusBg, focusBorderColor, focusBoxShadow, focusColor, function (props) {
    return props.truncated && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n    "], ["\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n    "])));
}, function (props) {
    return props.truncatedTwo && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      overflow: hidden;\n      text-overflow: ellipsis;\n      display: -webkit-box;\n      -webkit-line-clamp: 2;\n      -webkit-box-orient: vertical;\n    "], ["\n      overflow: hidden;\n      text-overflow: ellipsis;\n      display: -webkit-box;\n      -webkit-line-clamp: 2;\n      -webkit-box-orient: vertical;\n    "])));
});
/** @type {any} */
var Box = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n"], ["\n  ", ";\n"])), boxStyles);
Box.defaultProps = {
    theme: theme,
};
export default Box;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
