import { __assign, __rest } from "tslib";
import React from 'react';
import { Box, Button, HintBar, Image } from '@marvelapp/ui';
import { ModalLink } from '@marvelapp/ui-internal';
import money from './money.png';
import money2x from './money@2x.png';
export function HBSwitchToYearlySpecialAccount(_a) {
    var userInfo = _a.userInfo, onClickClose = _a.onClickClose, clickedCTA = _a.clickedCTA;
    return (React.createElement(HBSwitchToYearlySpecial, { clickedCTA: clickedCTA, mb: 0, mt: 6, onClickClose: onClickClose, userInfo: userInfo }));
}
export default function HBSwitchToYearlySpecial(_a) {
    var userInfo = _a.userInfo, onClickClose = _a.onClickClose, clickedCTA = _a.clickedCTA, rest = __rest(_a, ["userInfo", "onClickClose", "clickedCTA"]);
    return (React.createElement(HintBar, __assign({ bg: "steel", callToAction: React.createElement(ModalLink, { onClick: clickedCTA, to: "/upgrade/plan/DBLCG4fB3BrQdwhbdsw/billing-cycle/yearly" },
            React.createElement(Button, { kind: "withShadow" }, "View details")), description: React.createElement(Box, { maxWidth: [240, 300, 300, 480] }, "Switch to the yearly plan for just $84 per year. Less invoices, more Marvel."), image: React.createElement(Image, { alt: "Flying money emoji", display: "block", src: money, src2x: money2x }), mt: 4, onClickClose: onClickClose, title: React.createElement(Box, { maxWidth: [240, 240, 240, 480] }, "Special offer - Save 56% on your current Marvel Pro plan") }, rest)));
}
