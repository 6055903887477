import { __assign, __rest } from "tslib";
import React from 'react';
import { CLOSE_ICON } from '@marvelapp/theme';
import Box from '../Box';
import Flex from '../Flex';
import Icon from '../Icon';
import Text from '../Text';
import { sizes } from './sizes';
function NotificationBar(_a) {
    var bg = _a.bg, children = _a.children, _b = _a.color, color = _b === void 0 ? 'white' : _b, icon = _a.icon, onClickClose = _a.onClickClose, _c = _a.size, size = _c === void 0 ? 0 : _c, hasMenuButtonOnPage = _a.hasMenuButtonOnPage, rest = __rest(_a, ["bg", "children", "color", "icon", "onClickClose", "size", "hasMenuButtonOnPage"]);
    return (React.createElement(Flex, __assign({ alignItems: "center", bg: bg, justifyContent: "center", minHeight: sizes[size].minHeight, px: sizes[size].px, py: sizes[size].py, width: "100%" }, rest),
        React.createElement(Flex, { display: "flex", flex: 1, justifyContent: "center", pl: hasMenuButtonOnPage && [75, 75, 0, 0], pr: hasMenuButtonOnPage && [75, 75, 0, 0] },
            icon && (React.createElement(Box, null,
                React.createElement(Icon, { color: color, height: 18, mr: 2, paths: icon, width: 18 }))),
            React.createElement(Box, null,
                React.createElement(Text, { as: "span", color: color, fontSize: sizes[size].fontSize }, children))),
        onClickClose && (React.createElement(Box, { cursor: "pointer", onClick: onClickClose },
            React.createElement(Icon, { color: color, display: "block", hoverColor: "snowDark", mr: 2, paths: CLOSE_ICON })))));
}
NotificationBar.defaultProps = {
    bg: 'marvel',
};
export default NotificationBar;
