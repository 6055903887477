import { __makeTemplateObject } from "tslib";
import themeGet from '@styled-system/theme-get';
import { color, position, space } from 'styled-system';
import styled, { css } from '@marvelapp/styled';
import { BAR_HEIGHT, colors } from '@marvelapp/theme';
import { customProperties } from '@marvelapp/ui';
export var BarContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  top: 0;\n  z-index: ", ";\n  background-color: ", ";\n  width: 100%;\n  height: ", "px;\n  box-shadow: ", ";\n"], ["\n  ", ";\n  top: 0;\n  z-index: ", ";\n  background-color: ", ";\n  width: 100%;\n  height: ", "px;\n  box-shadow: ", ";\n"])), position, themeGet('zIndices.navigationBar'), colors.white, BAR_HEIGHT, themeGet('shadows.borders.bottom'));
export var BackElement = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  width: ", "px;\n  height: ", "px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  transition: color 150ms ease-in-out;\n\n  &:hover {\n    ", ";\n  }\n\n  &&:after {\n    width: 1px;\n    background-color: ", ";\n    position: absolute;\n    top: 15px;\n    right: 0;\n    bottom: 15px;\n    content: '';\n  }\n\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  width: ", "px;\n  height: ", "px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  transition: color 150ms ease-in-out;\n\n  &:hover {\n    ", ";\n  }\n\n  &&:after {\n    width: 1px;\n    background-color: ", ";\n    position: absolute;\n    top: 15px;\n    right: 0;\n    bottom: 15px;\n    content: '';\n  }\n\n  ", ";\n"])), color, space, BAR_HEIGHT, BAR_HEIGHT, customProperties.hoverColor, colors.smoke, function (props) {
    return props.disabled && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      cursor: auto;\n      pointer-events: none;\n    "], ["\n      cursor: auto;\n      pointer-events: none;\n    "])));
});
var templateObject_1, templateObject_2, templateObject_3;
