import { __makeTemplateObject } from "tslib";
import { color, layout, space } from 'styled-system';
import styled from '@marvelapp/styled';
import theme from '@marvelapp/theme';
import { cursor, fill, hoverColor, hoverFill, overflow, } from '../customProperties';
export var SvgElement = styled.svg(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    ", ";\n    ", ";\n  }\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    ", ";\n    ", ";\n  }\n"])), color, cursor, fill, layout, overflow, space, hoverColor, hoverFill);
SvgElement.defaultProps = {
    theme: theme,
};
var templateObject_1;
