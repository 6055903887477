import React from 'react';
import { zendesk } from '@marvelapp/help';
import { Button, HintBar, Image, Link } from '@marvelapp/ui';
import image from './image.png';
import image2x from './image@2x.png';
function HBPrototypeTutorial(_a) {
    var onClickClose = _a.onClickClose, clickedCTA = _a.clickedCTA;
    return (React.createElement(HintBar, { bg: "purple", callToAction: React.createElement(Link, { href: zendesk.USER_TESTING_INTRODUCTION, onClick: clickedCTA, target: "_blank" },
            React.createElement(Button, { kind: "withShadow" }, "Watch Video")), description: "Check out our short video on how to get started", image: React.createElement(Link, { "data-testid": 'upsell-usertest-image-link', href: zendesk.USER_TESTING_INTRODUCTION, onClick: clickedCTA, target: "_blank" },
            React.createElement(Image, { alt: "Video Preview", display: "block", height: 47, mb: [2, 0, 0, 0], src: image, src2x: image2x, width: "auto" })), mt: 4, onClickClose: onClickClose, title: "Create a user test and validate your prototype!", zIndex: 9 }));
}
export default HBPrototypeTutorial;
