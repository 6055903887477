import { __assign, __read } from "tslib";
export function decodeParams(raw) {
    return raw
        .replace(/^(#|\?)/, '')
        .split('&')
        .reduce(function (parsed, param) {
        var _a;
        var _b = __read(param.split('='), 2), key = _b[0], _c = _b[1], value = _c === void 0 ? '' : _c;
        return __assign(__assign({}, parsed), (_a = {}, _a[decodeURIComponent(key)] = decodeURIComponent(value), _a));
    }, {});
}
export function encodeParams(params) {
    return Object.keys(params)
        .map(function (key) { return "".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(params[key])); })
        .join('&');
}
/**
 * sends a request to the specified url from a form. this will change the window location.
 * @param {string} path the path to send the post request to
 * @param {object} params the parameters to add to the url
 * @param {string} [method=post] the method to use on the form
 */
export function postForm(path, params, method) {
    if (method === void 0) { method = 'post'; }
    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    var form = document.createElement('form');
    form.method = method;
    form.action = path;
    Object.keys(params).forEach(function (key) {
        var hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
    });
    document.body.appendChild(form);
    form.submit();
}
export function isSafeURL(url) {
    try {
        var protocol = new URL(url).protocol;
        // eslint-disable-next-line no-script-url
        return protocol !== 'javascript:';
    }
    catch (_a) {
        return true;
    }
}
