import React from 'react';
import { zendesk } from '@marvelapp/help';
import { Link } from '@marvelapp/ui';
import BaseError from './BaseError';
export default function UnhandledError() {
    var subheading = (React.createElement(React.Fragment, null,
        "It looks like there was an unexpected error. It pains us to ask, but please can you try refreshing the page? If the problem persists,",
        ' ',
        React.createElement(Link, { color: "marvelDark", hoverColor: "marvel", href: zendesk.NEW_TICKET, tag: "a", target: "_blank" }, "get in touch"),
        "."));
    var linkComponent = function (_a) {
        var url = _a.url, name = _a.name, target = _a.target;
        return (React.createElement(Link, { display: "block", href: url, key: url, kind: "marvel", mb: 2, tag: "a", target: target }, name));
    };
    return (React.createElement(BaseError, { heading: "Something went wrong", renderLinkComponent: linkComponent, subheading: subheading }));
}
