import { __assign } from "tslib";
import React, { Fragment } from 'react';
import { Button, Heading, Image, Text } from '@marvelapp/ui';
import Link from '../Link';
function EmptyState(_a) {
    var maxWidth = _a.maxWidth, image = _a.image, title = _a.title, subtitle = _a.subtitle, buttonText = _a.buttonText, to = _a.to, testId = _a.testId, children = _a.children;
    return (React.createElement(Fragment, null,
        React.createElement(Image, __assign({ mb: 4 }, image)),
        React.createElement(Heading, { "data-testid": testId, fontSize: [3, 3, 4], fontWeight: 500, maxWidth: maxWidth, mb: 2, textAlign: "center" }, title),
        React.createElement(Text, { fontSize: [2, 2, 3], maxWidth: maxWidth, mb: 3, textAlign: "center" }, subtitle),
        to && (React.createElement(Link, { to: to },
            React.createElement(Button, { kind: "ghost" }, buttonText))),
        children));
}
export default EmptyState;
