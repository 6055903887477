import React, { createContext, useContext } from 'react';
import { isInABTestVariant } from '@marvelapp/core';
import { PermissionsProvider } from './PermissionsProvider';
var AuthContext = createContext();
var BallparkContext = createContext();
var ConfigContext = createContext();
var FeatureFlagsContext = createContext();
var LastLocationContext = createContext();
var LogoutContext = createContext();
var SidebarContext = createContext();
var PusherContext = createContext();
var RouteConfigContext = createContext({
    loginUri: '/login',
});
var SubscriptionContext = createContext();
var UserContext = createContext();
var AppContainerContext = createContext();
function Marvel3Provider(_a) {
    var accessToken = _a.accessToken, children = _a.children, config = _a.config, featureFlags = _a.featureFlags, _b = _a.isBallpark, isBallpark = _b === void 0 ? false : _b, lastLocation = _a.lastLocation, logout = _a.logout, pusher = _a.pusher, refetchUserData = _a.refetchUserData, setSubscription = _a.setSubscription, sidebar = _a.sidebar, subscription = _a.subscription, transactionalKey = _a.transactionalKey, user = _a.user;
    return (React.createElement(AuthContext.Provider, { value: accessToken },
        React.createElement(FeatureFlagsContext.Provider, { value: featureFlags },
            React.createElement(ConfigContext.Provider, { value: config },
                React.createElement(LastLocationContext.Provider, { value: lastLocation },
                    React.createElement(SidebarContext.Provider, { value: sidebar },
                        React.createElement(PermissionsProvider, { subscription: subscription, user: user },
                            React.createElement(PusherContext.Provider, { value: { pusher: pusher, transactionalKey: transactionalKey } },
                                React.createElement(UserContext.Provider, { value: user },
                                    React.createElement(LogoutContext.Provider, { value: logout },
                                        React.createElement(SubscriptionContext.Provider, { value: [subscription, setSubscription] },
                                            React.createElement(BallparkContext.Provider, { value: isBallpark },
                                                React.createElement(AppContainerContext.Provider, { value: refetchUserData }, children)))))))))))));
}
var FeatureFlagsConsumer = FeatureFlagsContext.Consumer;
var ConfigConsumer = ConfigContext.Consumer;
var useAuth = function () { return useContext(AuthContext); };
var useBallparkContext = function () { return useContext(BallparkContext); };
var useConfig = function () { return useContext(ConfigContext); };
var useCurrentSubscription = function () { return useContext(SubscriptionContext); };
var useCurrentUser = function () { return useContext(UserContext); };
var useFeatureFlag = function (key) { return useContext(FeatureFlagsContext)[key]; };
var useLastLocation = function () { return useContext(LastLocationContext); };
var useLogout = function () { return useContext(LogoutContext); };
var usePusher = function () { return useContext(PusherContext); };
var useRouteConfig = function () { return useContext(RouteConfigContext); };
var useSidebar = function () { return useContext(SidebarContext); };
var useABTest = function (testKey) {
    var currentUser = useCurrentUser();
    return isInABTestVariant(testKey, currentUser);
};
var useRefetchUserData = function () { return useContext(AppContainerContext); };
export { Marvel3Provider, 
// contexts
FeatureFlagsContext, RouteConfigContext, 
// consumers for old components
FeatureFlagsConsumer, ConfigConsumer, 
// hooks
useAuth, useBallparkContext, useConfig, useCurrentSubscription, useCurrentUser, useABTest, useFeatureFlag, useLastLocation, useLogout, usePusher, useRouteConfig, useSidebar, useRefetchUserData, };
