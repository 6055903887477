import { get } from 'lodash-es';
import DetachedProjects from '../components/multiple/DetachedProjects';
import FloatingRoleSurvey from '../components/multiple/FloatingRoleSurvey';
import HBDashboardWhiteboadBeta from '../components/multiple/HBDashboardWhiteboadBeta';
import HBPrototypeTutorial from '../components/multiple/HBPrototypeTutorial';
import HBUserTestingTutorial from '../components/multiple/HBUserTestingTutorial';
import NBBallpark from '../components/multiple/NBBallpark';
import NBEnterpriseRenew from '../components/multiple/NBEnterpriseRenew';
import NBFreeTrial from '../components/multiple/NBFreeTrial';
import NBHitProjectLimits from '../components/multiple/NBHitProjectLimits';
import NBStunning from '../components/multiple/NBStunning';
import TUCreateFirstPrototype from '../components/multiple/TUCreateFirstPrototype';
import { getPLGUpsellContent } from '../components/multiple/TUPLGContent';
import TUUserTestingEbook from '../components/multiple/TUUserTestingEbook';
import * as id from '../identifiers';
import * as position from '../positions';
export default function upsells(_a) {
    var _b;
    var userInfo = _a.userInfo, stunning = _a.stunning;
    var remainingTrialDays = userInfo.remainingTrialDays, occupation = userInfo.occupation, isEnterprise = userInfo.isEnterprise, paysWithStripe = userInfo.paysWithStripe, isAccountLocked = userInfo.isAccountLocked, nextPaymentWithinExpiryWindow = userInfo.nextPaymentWithinExpiryWindow, monthsSinceSignup = userInfo.monthsSinceSignup, hasHitUserTestLimit = userInfo.hasHitUserTestLimit, prototypeProjectCount = userInfo.prototypeProjectCount, userTestProjectsOwnedCount = userInfo.userTestProjectsOwnedCount, hitAnyProjectsLimit = userInfo.hitAnyProjectsLimit, isWhiteboardEnabled = userInfo.isWhiteboardEnabled;
    return _b = {},
        _b[position.FLOATING] = [
            {
                identifier: id.ROLE_QUESTION,
                component: FloatingRoleSurvey,
                isClosable: true,
                condition: occupation === null,
                daysUntilReshownAfterClose: 30,
            },
        ],
        _b[position.FREE_TRIAL_NOTIFICATION_BAR] = [
            {
                identifier: id.FREE_TRIAL,
                component: NBFreeTrial,
                isClosable: false,
                condition: remainingTrialDays > 0,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.PROTOTYPE_HINT_BAR] = [
            {
                identifier: id.PROTOTYPE_TUTORIAL,
                component: HBPrototypeTutorial,
                isClosable: true,
                condition: userInfo.screensOwnedCount < 2 || userInfo.hotspotsOwnedCount === 0,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.DASHBOARD_TINY_UPSELLER] = [
            {
                identifier: id.DASHBOARD_FIRST_PROJECT,
                component: TUCreateFirstPrototype,
                isClosable: true,
                condition: !userInfo.hasHitPrototypeLimit &&
                    userInfo.prototypeProjectsOwnedCount === 0,
                daysUntilReshownAfterClose: 365,
            },
            getPLGUpsellContent({ monthsSinceSignup: monthsSinceSignup }),
            {
                identifier: id.DASHBOARD_USER_TESTING_EBOOK,
                component: TUUserTestingEbook,
                isClosable: true,
                condition: false,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.DASHBOARD_NOTIFICATION_BAR] = [
            {
                identifier: id.ENTERPRISE_RENEW,
                component: NBEnterpriseRenew,
                isClosable: false,
                condition: isEnterprise &&
                    !paysWithStripe &&
                    (isAccountLocked || nextPaymentWithinExpiryWindow),
                daysUntilReshownAfterClose: 365,
            },
            {
                identifier: id.STUNNING,
                component: NBStunning,
                isClosable: false,
                condition: get(stunning, 'response.status') === 'expiring' ||
                    get(stunning, 'response.status') === 'expired',
                daysUntilReshownAfterClose: 365,
            },
            {
                identifier: id.DASHBOARD_REACHED_PROJECT_LIMIT,
                component: NBHitProjectLimits,
                isClosable: false,
                condition: !remainingTrialDays && hitAnyProjectsLimit,
                daysUntilReshownAfterClose: 365,
            },
            {
                identifier: id.DASHBOARD_BALLPARK,
                component: NBBallpark,
                isClosable: true,
                condition: !isEnterprise,
                daysUntilReshownAfterClose: 30,
            },
        ],
        _b[position.DASHBOARD_HINT_BAR] = [
            {
                identifier: id.DASHBOARD_WHITEBOARD_BETA,
                component: HBDashboardWhiteboadBeta,
                isClosable: true,
                condition: isWhiteboardEnabled,
                daysUntilReshownAfterClose: 30,
            },
            {
                identifier: id.USER_TESTING_TUTORIAL,
                component: HBUserTestingTutorial,
                isClosable: true,
                condition: !hasHitUserTestLimit &&
                    userTestProjectsOwnedCount === 0 &&
                    prototypeProjectCount > 0,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.DASHBOARD_HINT_BAR_PERSONAL_PROJECTS] = [
            {
                identifier: id.DETACHED_PROJECTS,
                component: DetachedProjects,
                isClosable: false,
                condition: true,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b;
}
