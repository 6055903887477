import { __assign, __rest } from "tslib";
import React from 'react';
import Button from '../Button';
import Position from '../Position';
import { PulseAnimation } from './styles';
export default function PulsatingButton(_a) {
    var children = _a.children, shouldPulse = _a.shouldPulse, _b = _a.pulseColor, pulseColor = _b === void 0 ? 'smoke' : _b, props = __rest(_a, ["children", "shouldPulse", "pulseColor"]);
    if (!shouldPulse) {
        return React.createElement(Button, __assign({}, props), children);
    }
    return (React.createElement(PulseAnimation, { color: pulseColor },
        React.createElement(Position, { position: "relative" },
            React.createElement(Button, __assign({}, props), children))));
}
export { PulseAnimation };
