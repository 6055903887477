import { __assign, __rest } from "tslib";
import React from 'react';
import { toast } from 'react-toastify';
import { Link, ProgressBar } from '@marvelapp/ui';
import FailedText from '../FailedText';
import LoadingText from '../LoadingText';
import SketchText from '../SketchText';
import SuccessText from '../SuccessText';
/** @param {any} */
function showNotification(_a) {
    var toastId = _a.toastId, component = _a.component, options = _a.options;
    if (toastId && toast.isActive(toastId)) {
        return toast.update(toastId, __assign({ render: component }, options));
    }
    return toast(component, __assign(__assign({}, options), { toastId: toastId }));
}
/** @param {any} */
export function showLoadingNotification(_a) {
    var toastId = _a.toastId, content = _a.content, options = _a.options;
    return showNotification({
        toastId: toastId,
        component: (React.createElement(LoadingText, { color: "white", loaderColor: "white" }, content)),
        options: __assign({ type: toast.TYPE.INFO }, options),
    });
}
/** @param {any} */
export function showProgressNotification(_a) {
    var toastId = _a.toastId, content = _a.content, options = _a.options, progress = _a.progress;
    return showNotification({
        toastId: toastId,
        component: (React.createElement(LoadingText, { alignItems: "center", color: "white", loaderColor: "white" },
            content,
            React.createElement(ProgressBar, { mb: 1, mt: 2, progress: progress }))),
        options: __assign({ type: toast.TYPE.INFO }, options),
    });
}
/** @param {any} */
export function showSuccessNotification(_a) {
    var toastId = _a.toastId, content = _a.content, options = _a.options;
    return showNotification({
        toastId: toastId,
        component: (React.createElement(SuccessText, { bg: "white", borderColor: "white", color: "white", iconColor: "silver" }, content)),
        options: __assign({ type: toast.TYPE.SUCCESS }, options),
    });
}
/** @param {any} */
export function showErrorNotification(_a) {
    var toastId = _a.toastId, content = _a.content, retry = _a.retry, options = _a.options;
    return showNotification({
        toastId: toastId,
        component: (React.createElement(FailedText, { color: "white" },
            content,
            retry && (React.createElement(React.Fragment, null,
                ' ',
                React.createElement(Link, { fontSize: "inherit", hasUnderline: true, kind: "white", onClick: retry }, "Retry?"))))),
        options: __assign({ type: toast.TYPE.ERROR }, options),
    });
}
export function showWarningNotification(_a) {
    var options = _a.options, args = __rest(_a, ["options"]);
    return showErrorNotification(__assign(__assign({}, args), { options: __assign({ type: toast.TYPE.WARNING }, options) }));
}
export function showSketchNotification(_a) {
    var toastId = _a.toastId, content = _a.content, options = _a.options;
    return showNotification({
        toastId: toastId,
        component: React.createElement(SketchText, { color: "white" }, content),
        options: __assign({ type: toast.TYPE.INFO }, options),
    });
}
function getErrorMessage(statusCode, optionalMessage) {
    var message = optionalMessage || 'Oops, something went wrong';
    if (statusCode) {
        message += " (Error code: ".concat(statusCode, ")");
    }
    return "".concat(message, ".");
}
/** @param {any} */
export function showGenericServerError(_a) {
    var _b = _a === void 0 ? {} : _a, statusCode = _b.statusCode, toastId = _b.toastId, _c = _b.closeOnClick, closeOnClick = _c === void 0 ? false : _c, retry = _b.retry, content = _b.content;
    return showErrorNotification({
        toastId: toastId,
        content: getErrorMessage(statusCode, content),
        options: {
            // setting autoClose to 5000 in case the original toast
            // disabled autoClose (will be true for progress notifications)
            autoClose: 5000,
            // Prevent close when clicking the retry link
            closeOnClick: closeOnClick,
        },
        retry: retry,
    });
}
