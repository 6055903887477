import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useCurrentUser } from '@marvelapp/marvel-3-application';
import { useVideoOverlay } from './VideoOverlayContext';
import HelpButton from './components/HelpButton';
import WistiaFloatingVideo from './components/WistiaFloatingVideo';
import { createArticlesAndVideos } from './data';
import { getStorage, setVideoClosed } from './storage';
import { trackAutoShowVideo, trackClickHelp, trackClickHelpItem, } from './tracking';
var VideoOverlayRoot = function () {
    var user = useCurrentUser();
    var location = useLocation();
    var _a = useVideoOverlay(), video = _a.video, hide = _a.hide, show = _a.show;
    if (user.role === 'ANONYMOUS') {
        return null;
    }
    var currentPath = location.pathname;
    var darkPages = ['/project/*/screen/*'];
    var isDark = darkPages.some(function (path) {
        return matchPath(currentPath, {
            path: path,
            exact: true,
            strict: true,
        });
    });
    var onClickItem = function (item) {
        trackClickHelpItem({ link: item.link, url: location.pathname });
    };
    var onClick = function () {
        trackClickHelp({ url: location.pathname });
    };
    var onHideVideo = function () {
        hide();
        if (video)
            setVideoClosed(video.link);
    };
    var items = createArticlesAndVideos({ user: user }).filter(function (item) {
        return item.urls.some(function (path) {
            return matchPath(currentPath, {
                path: path,
                exact: true,
                strict: true,
            });
        });
    });
    items.sort(function (x, y) {
        return Number(y.recommended) - Number(x.recommended);
    });
    var showHelpButton = items.length > 0;
    var isVideo = function (obj) {
        return obj.type === 'video';
    };
    var videoItems = items.filter(isVideo);
    var storage = getStorage();
    var autoShowVideo = videoItems.find(function (item) { return item.autoShow && !storage.closedLinks.includes(item.link); });
    // If I'm watching a video already we shouldn't show anything automatically
    // Only show videos if the help button is allowed as they are in the A/B Test
    // Having to wrap this in a timeout due to a bug in onboarding that is failing to show
    // the video if you create a project
    setTimeout(function () {
        if (!video && autoShowVideo !== video) {
            show(autoShowVideo);
            trackAutoShowVideo({ link: autoShowVideo.link });
        }
    }, 2000);
    return (React.createElement(React.Fragment, null,
        showHelpButton && (React.createElement(HelpButton, { canPulse: !video, isDark: isDark, items: items, onClick: onClick, onClickItem: onClickItem })),
        video && React.createElement(WistiaFloatingVideo, { hide: onHideVideo, link: video.link })));
};
export default VideoOverlayRoot;
