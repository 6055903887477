import { __assign } from "tslib";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { has } from 'lodash-es';
import { fetchJSON, getCookie } from '@marvelapp/core';
import config from './config';
import { clearAuth, setAuth } from './utils/auth';
var CSRF_COOKIE_KEY = 'csrftoken';
var RETRIEVE_OAUTH_TOKEN_URI = '/api/oauth/retrieve/';
var CSRF_TOKEN = getCookie(CSRF_COOKIE_KEY);
export default function createGqlClient(_a) {
    var initialAccessToken = _a.accessToken;
    var accessToken = initialAccessToken;
    var refreshingPromise = null;
    var authorizationMiddleware = new ApolloLink(function (operation, forward) {
        var context = operation.getContext();
        // Don't ovveride the authorization header if the access token is undefined
        // or if the query/mutation has a custom authorization defined in the context
        if (accessToken && !has(context, 'headers.Authorization')) {
            operation.setContext({
                headers: {
                    Authorization: "Bearer ".concat(accessToken),
                },
            });
        }
        return forward(operation);
    });
    function fetchAndRefreshToken(uri, options) {
        return fetch(uri, options).then(function (response) {
            if (response.status !== 401) {
                return response;
            }
            // We cannot refresh the token on local development or Netlify previews
            // because the CSRF token is only injected in the browser's cookies by Django
            if (!CSRF_TOKEN) {
                // eslint-disable-next-line no-console
                console.warn('Unauthorized response, cannot refresh the OAuth token without a CSRF token');
                clearAuthAndRedirectToLoginPage();
            }
            // Refresh the OAuth token
            // This reference to the refreshingPromise let us check if we're already
            // refreshing the OAuth token
            if (!refreshingPromise) {
                refreshingPromise = fetchJSON(RETRIEVE_OAUTH_TOKEN_URI, {
                    method: 'POST',
                    headers: {
                        'X-CSRFToken': CSRF_TOKEN,
                    },
                }).catch(function () {
                    // Something went wrong when retrieving the new token
                    // There isn't much that we can do at this point 🤷🏻‍
                    clearAuthAndRedirectToLoginPage();
                });
            }
            return refreshingPromise.then(function (_a) {
                var newAccessToken = _a.token, expires = _a.expires;
                // Now that the refreshing promise has been executed, set it to null
                // and update the access token
                refreshingPromise = null;
                accessToken = newAccessToken;
                // TODO: /api/oauth/retrieve/ should respond with the current scopes
                // and expiry date in UNIX timestamp
                setAuth(newAccessToken, Date.parse(expires), config.pie.scopes);
                // Invoke fetch with the new access token.
                // If the initial request had errors, this fetch that is returned below
                // is the final result
                var newOptions = __assign(__assign({}, options), { headers: __assign(__assign({}, options.headers), { Authorization: "Bearer ".concat(accessToken) }) });
                return fetch(uri, newOptions);
            });
        });
    }
    return new ApolloClient({
        link: ApolloLink.from([
            onError(function (_a) {
                var graphQLErrors = _a.graphQLErrors;
                if (graphQLErrors) {
                    graphQLErrors.map(function (_a) {
                        var message = _a.message, locations = _a.locations, path = _a.path;
                        // eslint-disable-next-line no-console
                        return console.log("[GraphQL error]: Message: ".concat(message, ", Location: ").concat(locations, ", Path: ").concat(path));
                    });
                }
            }),
            authorizationMiddleware,
            new HttpLink({
                fetch: fetchAndRefreshToken,
                uri: "".concat(config.pie.host, "/graphql"),
                credentials: 'same-origin',
            }),
        ]),
        cache: new InMemoryCache({
            // The default implementation of dataIdFromObject looks for ID fields
            // named `id` or `_id`, so we override it to use `pk` instead
            dataIdFromObject: function (result) {
                if (result.__typename && result.pk) {
                    return "".concat(result.__typename, ":").concat(result.pk);
                }
                return null;
            },
        }),
        defaultOptions: {
            watchQuery: {
                notifyOnNetworkStatusChange: true,
                fetchPolicy: 'cache-and-network',
            },
        },
    });
}
function clearAuthAndRedirectToLoginPage() {
    clearAuth();
    window.location = '/login';
}
