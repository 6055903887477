import { __assign } from "tslib";
import { colors, fontSizes, radii, zIndices } from '@marvelapp/theme';
import { hexToRGB } from '../utils';
export function getTooltipStyle(_a) {
    var arrowRight = _a.arrowRight, cursor = _a.cursor, display = _a.display, _b = _a.fontSize, fontSize = _b === void 0 ? fontSizes[1] : _b, fontWeight = _a.fontWeight, maxWidth = _a.maxWidth, _c = _a.padding, padding = _c === void 0 ? '6px 10px 4px' : _c, pointerEvents = _a.pointerEvents, position = _a.position, _d = _a.whiteSpace, whiteSpace = _d === void 0 ? 'nowrap' : _d, width = _a.width;
    var extraArrowStyles = arrowRight && { right: arrowRight };
    return {
        wrapper: {
            cursor: cursor,
            display: display,
            fontWeight: fontWeight,
            zIndex: 'unset',
        },
        content: {
            backgroundColor: 'transparent',
            color: colors.white,
            display: 'block',
            fontSize: fontSize,
            lineHeight: 1.5,
            padding: 0,
            whiteSpace: whiteSpace,
            width: width,
            maxWidth: maxWidth,
        },
        tooltip: __assign({ backgroundColor: hexToRGB(colors.licorice, 0.9), borderRadius: radii[1], display: 'block', margin: 0, minWidth: 0, zIndex: zIndices.tooltip, padding: padding, pointerEvents: pointerEvents }, position.tooltip),
        arrow: __assign(__assign({}, position.arrow), extraArrowStyles),
        gap: __assign({}, position.gap),
    };
}
