import { __assign, __read } from "tslib";
import { debounce } from 'lodash-es';
import { useCallback, useMemo, useReducer } from 'react';
var SET_LATEST = 'SET_LATEST';
var SET_DEBOUNCED = 'SET_DEBOUNCED';
export function useDebouncedState(initialState, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.delay, delay = _c === void 0 ? 200 : _c;
    var initialInternalState = {
        latest: initialState,
        debounced: initialState,
    };
    var _d = __read(useReducer(function (prevState, action) {
        switch (action.type) {
            case SET_LATEST:
                return __assign(__assign({}, prevState), { latest: action.state });
            case SET_DEBOUNCED:
                return __assign(__assign({}, prevState), { debounced: action.state });
            default:
                throw new Error("Unrecognised action ".concat(action.type, " \uD83E\uDD37\uD83C\uDFFB\u200D how we got here"));
        }
    }, initialInternalState), 2), state = _d[0], dispatch = _d[1];
    var setDebouncedState = useMemo(function () {
        return debounce(function (nextState) {
            dispatch({ type: SET_DEBOUNCED, state: nextState });
        }, delay);
    }, [delay]);
    var setState = useCallback(function (nextState) {
        dispatch({ type: SET_LATEST, state: nextState });
        setDebouncedState(nextState);
    }, [setDebouncedState]);
    return [state.debounced, setState, state.latest];
}
