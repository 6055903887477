export var getCompany = function (user) { return user === null || user === void 0 ? void 0 : user.company; };
export var getCompanyName = function (user) { return getCompany(user).name; };
export var getCompanyAvatar = function (user) { return getCompany(user).iconUrl; };
export var getCompanyOwner = function (user) { return getCompany(user).owner; };
// Workspace Properties
export var hasUserTestProjects = function (user) {
    return getCompany(user).userTestProjectCount > 0;
};
export var projectQuantityPurchased = function (user) {
    return getCompany(user).billing.projectQuantityPurchased;
};
export var prototypeProjectCount = function (user) {
    return getCompany(user).prototypeProjectCount;
};
export var userTestProjectCount = function (user) {
    return getCompany(user).userTestProjectCount;
};
export var whiteboardProjectCount = function (user) {
    return getCompany(user).whiteboardProjectCount;
};
// User Properties
export var getUserRole = function (user) { return user === null || user === void 0 ? void 0 : user.role; };
export var getProperties = function (user) { return (user === null || user === void 0 ? void 0 : user.properties) || {}; };
export var screensOwnedCount = function (user) {
    return getProperties(user).screensOwnedCount;
};
export var teamsOwnedCount = function (user) { return getProperties(user).teamsOwnedCount; };
export var foldersOwnedCount = function (user) {
    return getProperties(user).foldersOwnedCount;
};
export var prototypeProjectsOwnedCount = function (user) {
    return getProperties(user).prototypeProjectsOwnedCount;
};
export var userTestProjectsOwnedCount = function (user) {
    return getProperties(user).userTestProjectsOwnedCount;
};
export var hotspotsOwnedCount = function (user) {
    return getProperties(user).hotspotsOwnedCount;
};
export var customerId = function (user) { return getCompany(user).billing.customerId; };
var getPlanLabel = function (user) { return getCompany(user).billing.plan.label; };
export var isFree = function (user) { return getPlanLabel(user) === 'FREE'; };
export var isPro = function (user) { return getPlanLabel(user) === 'PRO'; };
export var isTeam = function (user) { return getPlanLabel(user) === 'TEAM'; };
export var isTeamPlus = function (user) { return getPlanLabel(user) === 'COMPANY'; };
export var isEnterprise = function (user) { return getPlanLabel(user) === 'ENTERPRISE'; };
export var hasFreeTrialEnabled = function (user) { var _a; return (_a = getCompany(user).flags) === null || _a === void 0 ? void 0 : _a.unrestrictedProjectTest; };
export var hasHitPrototypeLimit = function (user) {
    var quantityPurchased = projectQuantityPurchased(user);
    return (!hasFreeTrialEnabled(user) &&
        isFree(user) &&
        prototypeProjectCount(user) >= quantityPurchased);
};
export var hasHitBallparkProjectLimit = function (user) {
    return projectQuantityPurchased(user) > 0 &&
        userTestProjectCount(user) >= projectQuantityPurchased(user);
};
export var hasHitUserTestLimit = function (user) {
    return !hasFreeTrialEnabled(user) && hasUserTestProjects(user) && isFree(user);
};
export var hasHitWhiteboardLimit = function (user) {
    return (!hasFreeTrialEnabled(user) &&
        whiteboardProjectCount(user) > 0 &&
        isFree(user));
};
export var hasHitAnyProjectsLimit = function (user) {
    return hasHitPrototypeLimit(user) ||
        hasHitUserTestLimit(user) ||
        hasHitWhiteboardLimit(user);
};
export var getCapitalisedPlanLabel = function (user) {
    return {
        FREE: 'Free',
        PRO: 'Pro',
        TEAM: 'Team',
        COMPANY: 'Team Plus',
        ENTERPRISE: 'Enterprise',
    }[getPlanLabel(user)];
};
export function canUnarchive(user, selectedPrototypeCount, selectedUserTestCount) {
    if (!isFree(user) || hasFreeTrialEnabled(user)) {
        // Only Free plan has limited project allowance
        return true;
    }
    var userTestCount = userTestProjectCount(user);
    if (selectedUserTestCount > 0 && userTestCount > 0) {
        // Free can only have 1 user test so you can't unarchive 1 if you have 1
        // already
        return false;
    }
    var prototypeAllowance = projectQuantityPurchased(user);
    var prototypeCount = prototypeProjectCount(user);
    if (prototypeCount + selectedPrototypeCount > prototypeAllowance) {
        return false;
    }
    return true;
}
