import { __makeTemplateObject, __read } from "tslib";
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { Occupation, heapTrackEvent, hubspotIdentify, occupationLabels, } from '@marvelapp/core';
import { useCurrentUser } from '@marvelapp/marvel-3-application';
import { zIndices } from '@marvelapp/theme';
import { Box, Button, Flex, Select, Upseller } from '@marvelapp/ui';
var UPDATE_CURRENT_USER = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  mutation UPDATE_CURRENT_USER($occupation: OccupationEnum) {\n    updateCurrentUser(input: { occupation: $occupation }) {\n      ok\n      user {\n        pk\n        occupation\n        occupationDisplayName\n      }\n      error {\n        code\n      }\n    }\n  }\n"], ["\n  mutation UPDATE_CURRENT_USER($occupation: OccupationEnum) {\n    updateCurrentUser(input: { occupation: $occupation }) {\n      ok\n      user {\n        pk\n        occupation\n        occupationDisplayName\n      }\n      error {\n        code\n      }\n    }\n  }\n"])));
function FloatingRoleSurvey(_a) {
    var onClickClose = _a.onClickClose, clickedCTA = _a.clickedCTA;
    var _b = useCurrentUser(), pk = _b.pk, email = _b.email;
    var _c = __read(useState(), 2), selectionIndex = _c[0], setSelectionIndex = _c[1];
    var _d = __read(useMutation(UPDATE_CURRENT_USER), 1), updateUserOccupation = _d[0];
    var options = [
        {
            value: Occupation.Designer,
            name: occupationLabels[Occupation.Designer],
        },
        {
            value: Occupation.Student,
            name: occupationLabels[Occupation.Student],
        },
        {
            value: Occupation.Developer,
            name: occupationLabels[Occupation.Developer],
        },
        {
            value: Occupation.Teacher,
            name: occupationLabels[Occupation.Teacher],
        },
        {
            value: Occupation.ProductManager,
            name: occupationLabels[Occupation.ProductManager],
        },
        {
            value: Occupation.Marketing,
            name: occupationLabels[Occupation.Marketing],
        },
        {
            value: Occupation.Head,
            name: occupationLabels[Occupation.Head],
        },
        {
            value: Occupation.Researcher,
            name: occupationLabels[Occupation.Researcher],
        },
        {
            value: Occupation.Founder,
            name: occupationLabels[Occupation.Founder],
        },
        {
            value: Occupation.Finance,
            name: occupationLabels[Occupation.Finance],
        },
        {
            value: Occupation.Sales,
            name: occupationLabels[Occupation.Sales],
        },
        {
            value: Occupation.Support,
            name: occupationLabels[Occupation.Support],
        },
        {
            value: Occupation.IT,
            name: occupationLabels[Occupation.IT],
        },
        {
            value: Occupation.Other,
            name: occupationLabels[Occupation.Other],
        },
    ];
    var onSubmit = function () {
        var _a = options[selectionIndex], occupation = _a.name, occupationEnum = _a.value;
        heapTrackEvent('Update Occupation Banner');
        if (window.heap && window.heap.loaded) {
            window.heap.addUserProperties({
                occupation: occupation,
            });
        }
        hubspotIdentify({
            email: email,
            occupation: occupation,
            user_id: pk,
        });
        updateUserOccupation({
            optimisticResponse: {
                updateCurrentUser: {
                    ok: true,
                    error: null,
                    user: {
                        pk: pk,
                        occupation: occupationEnum,
                        occupationDisplayName: occupation,
                        __typename: 'UserNode',
                    },
                    __typename: 'updateCurrentUser',
                },
            },
            variables: { occupation: occupationEnum },
        });
        onClickClose();
        clickedCTA();
    };
    var form = (React.createElement(Flex, { alignItems: "center", flexDirection: "column", px: 4 },
        React.createElement(Select, { onChange: function (object) {
                var index = options.findIndex(function (p) { return p.value === object.target.value; });
                setSelectionIndex(index);
            }, size: 1, defaultValue: "Pick a role", value: selectionIndex !== undefined
                ? options[selectionIndex].value
                : undefined }, options.map(function (item) { return (React.createElement("option", { key: item.value, value: item.value }, item.name)); })),
        React.createElement(Flex, { justifyContent: "center", mt: 3 },
            React.createElement(Button, { isDisabled: selectionIndex === undefined, kind: "withShadow", onClick: onSubmit }, "Submit"))));
    return (React.createElement(Box, { bottom: 20, position: "fixed", right: 20, zIndex: zIndices.windows },
        React.createElement(Upseller, { callToAction: form, onClickClose: onClickClose, subtitle: "This allows us to personalize your experience.", title: React.createElement(React.Fragment, null, "What's your role?") }, form)));
}
export default FloatingRoleSurvey;
var templateObject_1;
