import { style } from 'styled-system';
export var borderTopLeftRadius = style({
    prop: 'borderTopLeftRadius',
    cssProperty: 'border-top-left-radius',
    key: 'radii',
});
export var borderTopRightRadius = style({
    prop: 'borderTopRightRadius',
    cssProperty: 'border-top-right-radius',
    key: 'radii',
});
export var borderBottomRightRadius = style({
    prop: 'borderBottomRightRadius',
    cssProperty: 'border-bottom-right-radius',
    key: 'radii',
});
export var borderBottomLeftRadius = style({
    prop: 'borderBottomLeftRadius',
    cssProperty: 'border-bottom-left-radius',
    key: 'radii',
});
export var borderWidth = style({
    prop: 'borderWidth',
    cssProperty: 'border-width',
});
export var textShadow = style({
    prop: 'textShadow',
    cssProperty: 'text-shadow',
});
export var cursor = style({
    prop: 'cursor',
    cssProperty: 'cursor',
});
export var fill = style({
    prop: 'fill',
    cssProperty: 'fill',
    key: 'colors',
});
export var hoverColor = style({
    prop: 'hoverColor',
    cssProperty: 'color',
    key: 'colors',
});
export var hoverBorderColor = style({
    prop: 'hoverBorderColor',
    cssProperty: 'border-color',
    key: 'colors',
});
export var focusBorderColor = style({
    prop: 'focusBorderColor',
    cssProperty: 'border-color',
    key: 'colors',
});
export var hoverBg = style({
    prop: 'hoverBg',
    cssProperty: 'background-color',
    key: 'colors',
});
export var hoverFill = style({
    prop: 'hoverFill',
    cssProperty: 'fill',
    key: 'colors',
});
export var hoverOpacity = style({
    prop: 'hoverOpacity',
    cssProperty: 'opacity',
});
export var hoverBoxShadow = style({
    prop: 'hoverBoxShadow',
    cssProperty: 'box-shadow',
    key: 'shadows',
});
export var activeBoxShadow = style({
    prop: 'activeBoxShadow',
    cssProperty: 'box-shadow',
    key: 'shadows',
});
export var focusColor = style({
    prop: 'focusColor',
    cssProperty: 'color',
    key: 'colors',
});
export var focusBg = style({
    prop: 'focusBg',
    cssProperty: 'background-color',
    key: 'colors',
});
export var focusBoxShadow = style({
    prop: 'focusBoxShadow',
    cssProperty: 'box-shadow',
    key: 'shadows',
});
export var objectFit = style({
    prop: 'objectFit',
    cssProperty: 'object-fit',
});
export var objectPosition = style({
    prop: 'objectPosition',
    cssProperty: 'object-position',
});
export var transform = style({
    prop: 'transform',
    cssProperty: 'transform',
});
export var transformOrigin = style({
    prop: 'transformOrigin',
    cssProperty: 'transform-origin',
});
export var textTransform = style({
    prop: 'textTransform',
    cssProperty: 'text-transform',
});
export var pointerEvents = style({
    prop: 'pointerEvents',
    cssProperty: 'pointer-events',
});
export var whiteSpace = style({
    prop: 'whiteSpace',
    cssProperty: 'white-space',
});
export var overflow = style({
    prop: 'overflow',
    cssProperty: 'overflow',
});
export var overflowX = style({
    prop: 'overflowX',
    cssProperty: 'overflow-x',
});
export var overflowY = style({
    prop: 'overflowY',
    cssProperty: 'overflow-y',
});
export var outline = style({
    prop: 'outline',
    cssProperty: 'outline',
});
export var maxHeight = style({
    prop: 'maxHeight',
    cssProperty: 'max-height',
});
export var transition = style({
    prop: 'transition',
    cssProperty: 'transition',
    key: 'transitions',
});
export var transitionDuration = style({
    prop: 'transitionDuration',
    cssProperty: 'transition-duration',
});
export var resize = style({
    prop: 'resize',
    cssProperty: 'resize',
});
export var fontVariantNumeric = style({
    prop: 'fontVariantNumeric',
    cssProperty: 'font-variant-numeric',
});
export var textDecoration = style({
    prop: 'textDecoration',
    css: 'text-decoration',
});
export var wordWrap = style({
    prop: 'wordWrap',
    css: 'wordWrap',
});
export var wordBreak = style({
    prop: 'wordBreak',
    css: 'wordBreak',
});
export var filter = style({
    prop: 'filter',
    css: 'filter',
});
export var userSelect = style({
    prop: 'userSelect',
    cssProperty: 'user-select',
});
export var textDecorationLine = style({
    prop: 'textDecorationLine',
    cssProperty: 'text-decoration-line',
});
export var textDecorationColor = style({
    prop: 'textDecorationColor',
    cssProperty: 'text-decoration-color',
    key: 'colors',
});
export var textDecorationThickness = style({
    prop: 'textDecorationThickness',
    cssProperty: 'text-decoration-thickness',
});
export var textUnderlineOffset = style({
    prop: 'textUnderlineOffset',
    cssProperty: 'text-underline-offset',
});
export var hoverTextDecorationColor = style({
    prop: 'textDecorationColor',
    cssProperty: 'text-decoration-color',
    key: 'colors',
});
