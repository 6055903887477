import { __assign, __rest } from "tslib";
import React from 'react';
import { NotificationBar } from '@marvelapp/ui';
import { ModalLink } from '@marvelapp/ui-internal';
function NBHitProjectLimits(_a) {
    var userInfo = _a.userInfo, clickedCTA = _a.clickedCTA, rest = __rest(_a, ["userInfo", "clickedCTA"]);
    var type = 'projects';
    if (userInfo.hasHitPrototypeLimit && !userInfo.hasHitUserTestLimit) {
        type = 'prototype projects';
    }
    else if (userInfo.hasHitUserTestLimit && !userInfo.hasHitPrototypeLimit) {
        type = 'user test projects';
    }
    return (React.createElement(NotificationBar, __assign({ bg: "orange", hasMenuButtonOnPage: true }, rest),
        "Your team has run out of ",
        type,
        " on the free plan.",
        ' ',
        React.createElement(ModalLink, { "data-testid": "cta", hasUnderline: true, kind: "notificationBar", onClick: clickedCTA, to: "/plans" }, "Upgrade now to keep making projects!")));
}
export default NBHitProjectLimits;
