import React from 'react';
import { Button, Image, Link, TinyUpseller } from '@marvelapp/ui';
import image from './image.jpg';
import image2x from './image@2x.jpg';
export var EBOOK_URL = 'https://resources.marvelapp.com/free-ebook-user-testing-field-guide';
function TUUserTestingEbook(_a) {
    var onClickClose = _a.onClickClose, clickedCTA = _a.clickedCTA;
    return (React.createElement(TinyUpseller, { callToAction: React.createElement(Link, { href: EBOOK_URL, onClick: clickedCTA, target: "_blank" },
            React.createElement(Button, { kind: "withShadow" }, "Download")), image: React.createElement(Image, { alt: "eBook cover", display: "block", height: 110, src: image, src2x: image2x, width: "auto" }), onClickClose: onClickClose, title: "New! The User Testing Field Guide." }));
}
export default TUUserTestingEbook;
