export var DASHBOARD_YEARLY = 'dashboard-yearly';
export var DASHBOARD_YEARLY_SPECIAL = 'dashboard-yearly-special';
export var DASHBOARD_YEARLY_SPECIAL_TEAM = 'dashboard-yearly-special-team';
export var DASHBOARD_FIRST_PROJECT = 'dashboard-first-project';
export var DASHBOARD_FEEDBACK = 'dashboard-feedback';
export var DASHBOARD_USER_TESTING_EBOOK = 'dashboard-user-testing-ebook';
export var DASHBOARD_COMMENTS_FEEDBACK = 'dashboard-comments-feedback';
export var DASHBOARD_WEBINAR = 'dashboard-webinar';
export var DASHBOARD_REACHED_PROJECT_LIMIT = 'dashboard-reached-project-limit';
export var DASHBOARD_BALLPARK = 'dashboard-ballpark';
export var DASHBOARD_PLG_CONTENT = 'dashboard-plg-content';
export var DASHBOARD_WHITEBOARD_BETA = 'dashboard-whiteboard-beta';
export var DETACHED_PROJECTS = 'detached-projects';
export var ACCOUNT_YEARLY = 'account-yearly';
export var STUNNING = 'stunning';
export var PROTOTYPE_TUTORIAL = 'prototype-tutorial';
export var USER_TESTING_TUTORIAL = 'user-testing-tutorial';
export var ENTERPRISE_RENEW = 'enterprise-renew';
export var FREE_TRIAL = 'free-trial';
export var TYPEFORM = 'typeform';
export var ROLE_QUESTION = 'role_question';
export var DASHBOARD_CONTENT_CAROUSELS = 'content_carousels';
export var DASHBOARD_CONTENT_REMOTE_USABILITY = 'content_remote_usability';
export var DASHBOARD_CONTENT_DESIGN_THINKING = 'content_design_thinking';
export var DASHBOARD_CONTENT_PITCH_PRESENT = 'content_pitch_present';
export var DASHBOARD_CONTENT_DESIGN_LED = 'content_design_led';
