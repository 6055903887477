import { __extends } from "tslib";
import React, { Component, createContext } from 'react';
import { Switch } from 'react-router-dom';
export var ModalStackContext = createContext([]);
export var ModalIndexContext = createContext([]);
var ModalSwitch = /** @class */ (function (_super) {
    __extends(ModalSwitch, _super);
    function ModalSwitch() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // We can pass a location to <Switch/> that will tell it to ignore the
        // router's current location and use the location prop instead.
        //
        // We can also use "location state" to tell the app the user wants to go to a
        // location in a modal, rather than as the main page, keeping the base page
        // visible behind it.
        //
        // Normally, the base page wouldn't match the route for the modal. So, to get
        // both screens to render, we can save the old location and pass it to Switch,
        // so it will think the location is still the base page even though it's the
        // location of the modal
        _this.previousLocation = _this.props.location;
        _this.stack = [];
        return _this;
    }
    ModalSwitch.prototype.componentWillUpdate = function (nextProps) {
        var location = this.props.location;
        // set previousLocation if props.location is not modal
        if (nextProps.history.action !== 'POP') {
            if (!location.state || !location.state.modal) {
                this.previousLocation = this.props.location;
                this.stack = [];
            }
            if (nextProps.location.state && nextProps.location.state.modal) {
                // Turns out you can rage click Links which results in duplicate entries
                // being pushed to the history stack. So we check here that it's
                // (probably) not the same link clicked to prevent adding duplicate
                // modals to the stack (seems like the kind of thing react-router should
                // maybe guard against 🤔)
                if (this.props.location.pathname !== nextProps.location.pathname) {
                    if (nextProps.history.action === 'REPLACE') {
                        this.stack[this.stack.length - 1] = nextProps.location;
                    }
                    else {
                        this.stack.push(nextProps.location);
                    }
                }
            }
            return;
        }
        if (this.props.location.pathname !== nextProps.location.pathname) {
            var locationIndex = this.stack.findIndex(function (_a) {
                var pathname = _a.pathname;
                return nextProps.location.pathname === pathname;
            });
            if (locationIndex === -1) {
                this.stack = [];
                return;
            }
            this.stack = this.stack.slice(0, locationIndex + 1);
        }
    };
    ModalSwitch.prototype.render = function () {
        var _a = this.props, children = _a.children, location = _a.location;
        return (React.createElement(ModalStackContext.Provider, { value: this.stack },
            React.createElement(ModalIndexContext.Provider, { value: this.stack.length - 1 },
                React.createElement(Switch, { location: location }, children))));
    };
    return ModalSwitch;
}(Component));
export default ModalSwitch;
