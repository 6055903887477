import { __read } from "tslib";
import { createMemoryHistory } from 'history';
import { find } from 'lodash-es';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import { ARROW_LEFT_ICON } from '@marvelapp/theme';
import { Icon, IconButton } from '@marvelapp/ui';
import Link from '../Link';
import { BackElement } from './styles';
export var BackLinkContext = createContext();
var PATH_TO_ENTRY_POINTS = {
    '/project/:id': ['/projects/:filter', '/folder/:id'],
    '/folder/:id': ['/folders/:filter'],
    '/user-test/:id': ['/project/:id', '/projects/:filter', '/folder/:id'],
};
export default function ProjectBarBackLink(_a) {
    var defaultBackLink = _a.defaultBackLink;
    var backLink = useContext(BackLinkContext).backLink;
    return (React.createElement(BackElement, { marginRight: 3 },
        React.createElement(IconButton, { as: Link, color: "slate", "data-testid": "project-bar-back-link", hoverColor: "licorice", px: 0, to: backLink || defaultBackLink, width: 26 },
            React.createElement(Icon, { paths: ARROW_LEFT_ICON }))));
}
function getBackLink(shadowHistory) {
    if (!(shadowHistory.entries && shadowHistory.entries.length > 1)) {
        return {};
    }
    var entryPoints = find(PATH_TO_ENTRY_POINTS, function (_, path) {
        return matchPath(shadowHistory.location.pathname, { path: path, exact: true });
    });
    if (!entryPoints) {
        return {};
    }
    var i = shadowHistory.index - 1;
    while (i >= 0 &&
        // eslint-disable-next-line no-loop-func
        !entryPoints.some(function (entryPoint) {
            return matchPath(shadowHistory.entries[i].pathname, {
                path: entryPoint,
                exact: true,
            });
        })) {
        i -= 1;
    }
    if (i === -1) {
        return {};
    }
    return { backLink: shadowHistory.entries[i] };
}
export function BackLinkProvider(_a) {
    var children = _a.children;
    var history = useHistory();
    var _b = __read(useState({
        backLink: '/',
    }), 2), backLink = _b[0], setBackLink = _b[1];
    useEffect(function () {
        if (!history)
            return;
        // Replay navigation to memory history so we can inspect entries
        var shadowHistory = createMemoryHistory({
            initialEntries: [history.location],
        });
        var unlisten = history.listen(function (newLocation, action) {
            if (action === 'PUSH') {
                shadowHistory.push(newLocation);
            }
            else if (action === 'REPLACE') {
                shadowHistory.replace(newLocation);
            }
            else {
                shadowHistory.goBack();
            }
        });
        shadowHistory.listen(function () {
            setBackLink(getBackLink(shadowHistory));
        });
        return unlisten;
    }, [history]);
    return (React.createElement(BackLinkContext.Provider, { value: backLink }, children));
}
