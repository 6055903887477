import { __read } from "tslib";
/* eslint-env browser */
import React, { useState } from 'react';
import { Box } from '@marvelapp/ui';
import Window from '../Window';
import WistiaEmbed from '../WistiaEmbed';
var WistiaFloatingVideo = function (_a) {
    var link = _a.link, hide = _a.hide;
    var _b = __read(useState(undefined), 2), title = _b[0], setTitle = _b[1];
    return (React.createElement(Box, { height: "100vh", left: 0, pointerEvents: "none", position: "fixed", top: 0, userSelect: "none", width: "100vw", zIndex: 9999 },
        React.createElement(Window, { height: 265, lockAspectRatio: 16 / 9, lockAspectRatioExtraHeight: 40, minHeight: 265, minWidth: 400, onClose: hide, title: title, width: 400 },
            React.createElement(WistiaEmbed, { embedOptions: {
                    plugin: {
                        share: {
                            channels: '',
                        },
                    },
                }, link: link, onHasData: function (data) {
                    setTitle(data.name);
                } }))));
};
export default WistiaFloatingVideo;
