import { __makeTemplateObject, __read } from "tslib";
import gql from 'graphql-tag';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { COMMENT_ICON } from '@marvelapp/theme';
import { CircleButton, Icon, Position, Tooltip, UnreadBubble, } from '@marvelapp/ui';
import { showErrorNotification } from '@marvelapp/ui-internal';
export var GET_NEW_COMMENT_COUNT = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getNewCommentCount($project: Int) {\n    unseenCommentCount(project: $project)\n  }\n"], ["\n  query getNewCommentCount($project: Int) {\n    unseenCommentCount(project: $project)\n  }\n"])));
export var MARK_COMMENTS_SEEN = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation ($input: MarkCommentsAsSeenInput) {\n    markCommentsAsSeen(input: $input) {\n      ok\n    }\n  }\n"], ["\n  mutation ($input: MarkCommentsAsSeenInput) {\n    markCommentsAsSeen(input: $input) {\n      ok\n    }\n  }\n"])));
export default function CommentButton(_a) {
    var _b = _a.mr, mr = _b === void 0 ? 0 : _b, superOnClick = _a.onClick, projectPk = _a.projectPk, _c = _a.tooltipPosition, tooltipPosition = _c === void 0 ? 'bottom' : _c;
    var _d = useQuery(GET_NEW_COMMENT_COUNT, {
        variables: { project: projectPk },
        // Polling was causing a lot of noise in the console due to missing mocks errors,
        // so we've decided to disable polling in tests as it is not needed there
        pollInterval: process.env.NODE_ENV === 'test' ? 0 : 30000,
    }), data = _d.data, error = _d.error;
    var _e = __read(useMutation(MARK_COMMENTS_SEEN, {
        variables: { input: { project: projectPk } },
        onError: showError,
        optimisticResponse: {
            markCommentsAsSeen: {
                ok: true,
                __typename: 'MarkCommentsAsSeen',
            },
        },
        update: function (cache, _a) {
            var ok = _a.data.markCommentsAsSeen.ok;
            if (!ok) {
                showError();
                return;
            }
            cache.writeQuery({
                query: GET_NEW_COMMENT_COUNT,
                variables: { project: projectPk },
                data: {
                    unseenCommentCount: 0,
                },
            });
        },
    }), 1), markSeen = _e[0];
    function onClick() {
        markSeen();
        superOnClick();
    }
    return (React.createElement(Position, { mr: mr },
        React.createElement(Tooltip, { content: "Comments", position: tooltipPosition },
            React.createElement(CircleButton, { "data-testid": "toggle-comments-sidebar-button", onClick: onClick },
                React.createElement(Icon, { display: "block", paths: COMMENT_ICON }))),
        !error && (data === null || data === void 0 ? void 0 : data.unseenCommentCount) > 0 && (React.createElement(Position, { position: "absolute", right: -6, top: -6, zIndex: "tooltip" },
            React.createElement(UnreadBubble, null, data.unseenCommentCount < 10 ? data.unseenCommentCount : '9+')))));
}
function showError() {
    showErrorNotification({
        autoClose: 5000,
        content: 'There was a problem marking comments seen',
        toastId: 'mark-comments-seen',
    });
}
var templateObject_1, templateObject_2;
