export function hubspotInitialize(hubspotId) {
    if (process.env.NODE_ENV !== 'production' ||
        process.env.DEPLOY_ENV !== 'production') {
        return;
    }
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = "//js.hs-scripts.com/".concat(hubspotId, ".js");
    document.body.appendChild(script);
}
export function hubspotTrackEvent(eventId) {
    if (window._hsq) {
        window._hsq.push([
            'trackEvent',
            {
                id: eventId,
            },
        ]);
    }
}
export function hubspotIdentify(settings) {
    if (settings === void 0) { settings = {}; }
    if (window._hsq) {
        window._hsq.push(['identify', settings]);
        window._hsq.push(['trackPageView']);
    }
}
