import React from 'react';
import { colors } from '@marvelapp/theme';
import { NotificationBar, Semibold, hexToRGB } from '@marvelapp/ui';
import { ModalLink } from '@marvelapp/ui-internal';
export default function NBHitProjectLimits(_a) {
    var userInfo = _a.userInfo;
    var remainingTrialDays = userInfo.remainingTrialDays;
    return (React.createElement(NotificationBar, { bg: hexToRGB(colors.marvelExtraLight, 0.5), color: "marvelDark", hasMenuButtonOnPage: true }, remainingTrialDays > 1 ? (React.createElement(React.Fragment, null,
        "You have unlimited projects until your trial ends in",
        ' ',
        React.createElement(Semibold, null, remainingTrialDays),
        " days.")) : (React.createElement(React.Fragment, null,
        "It\u2019s the last day of your trial!",
        ' ',
        React.createElement(ModalLink, { color: "marvelDark", "data-testid": "free-trial-upgrade", fontWeight: 600, hasUnderline: true, hoverColor: "marvel", to: "/plans" }, "Upgrade now"),
        ' ',
        "to continue creating projects"))));
}
