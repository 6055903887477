/**
 * Users who are using Marvel with Google Translate translations will run into errors.
 * This is a known issue with React & Google translate and is documented here:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=872770
 *
 * As a workaround, Dan Abramov suggested the solution bellow, which according to the
 * Github issue may have some performance implications. It has been slightly
 * modified to observe if the document translation is enabled/disabled and modify
 * Node.prototype only when needed to avoid opting in for this for all users.
 * Taken from here: https://github.com/facebook/react/issues/11538#issuecomment-417504600
 */
export function dangerouslySetupGoogleTranslateObserver() {
    var originalRemoveChild = Node.prototype.removeChild;
    var originalInsertBefore = Node.prototype.insertBefore;
    var observer = new MutationObserver(function () {
        var documentHasGoogleTranslation = document.documentElement.className.match('translated');
        if (documentHasGoogleTranslation) {
            if (typeof Node === 'function' && Node.prototype) {
                Node.prototype.removeChild = function (child) {
                    if (child.parentNode !== this) {
                        if (console) {
                            // eslint-disable-next-line no-console
                            console.error('Cannot remove a child from a different parent', child, this);
                        }
                        return child;
                    }
                    // eslint-disable-next-line prefer-rest-params
                    return originalRemoveChild.apply(this, arguments);
                };
                Node.prototype.insertBefore = function (newNode, referenceNode) {
                    if (referenceNode && referenceNode.parentNode !== this) {
                        if (console) {
                            // eslint-disable-next-line no-console
                            console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
                        }
                        return newNode;
                    }
                    // eslint-disable-next-line prefer-rest-params
                    return originalInsertBefore.apply(this, arguments);
                };
            }
        }
        else {
            Node.prototype.insertBefore = originalInsertBefore;
            Node.prototype.removeChild = originalRemoveChild;
        }
    });
    observer.observe(document.documentElement, {
        attributes: true,
        attributeFilter: ['class'],
        childList: false,
        characterData: false,
    });
}
