import { __assign } from "tslib";
import { isObject } from 'lodash-es';
import React from 'react';
import Link from '../Link';
export default function ModalLink(props) {
    var origTo = props.to;
    var to;
    if (!origTo) {
        // Seems there are a bunch of places where 'to' is undefined until some data
        // or a feature flag is loaded. Should probably fix them ...
        to = origTo;
    }
    else if (isObject(origTo)) {
        to = __assign(__assign({}, origTo), { state: __assign(__assign({}, origTo.state), { modal: true }) });
    }
    else {
        var hashIndex = origTo.indexOf('#');
        var searchIndex = origTo.indexOf('?');
        if (hashIndex !== -1 || searchIndex !== -1) {
            throw new Error("To use ModalLink with hash or search params use the object form of the 'to' argument");
        }
        to = {
            pathname: origTo,
            state: { modal: true },
        };
    }
    return React.createElement(Link, __assign({}, props, { to: to }));
}
