import React from 'react';
import { WARNING_ICON } from '@marvelapp/theme';
import { Box, Flex, Icon, Text } from '@marvelapp/ui';
import IconCircle from '../IconCircle';
export default function FailedText(_a) {
    var alignItems = _a.alignItems, borderColor = _a.borderColor, children = _a.children, color = _a.color, fontSize = _a.fontSize;
    return (React.createElement(Flex, { alignItems: alignItems },
        React.createElement(Box, { mr: 12 },
            React.createElement(IconCircle, { borderColor: borderColor, color: color },
                React.createElement(Icon, { display: "block", paths: WARNING_ICON }))),
        React.createElement(Flex, { alignItems: "center", minHeight: "26px" },
            React.createElement(Text, { color: color, fontSize: fontSize }, children))));
}
FailedText.defaultProps = {
    alignItems: 'flex-start',
    color: 'red',
    fontSize: 14,
};
