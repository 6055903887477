import { __assign, __read, __rest } from "tslib";
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { easings } from '@marvelapp/theme';
import Position from '../Position';
import { Container, Cover, PopoverElement } from './styles';
export default function Popover(props) {
    var _a = props.align, align = _a === void 0 ? 'center' : _a, _b = props.animateOnMount, animateOnMount = _b === void 0 ? true : _b, _c = props.borderRadius, borderRadius = _c === void 0 ? 2 : _c, _d = props.boxShadow, boxShadow = _d === void 0 ? 'popover.default' : _d, children = props.children, _e = props.distance, distance = _e === void 0 ? 15 : _e, isActive = props.isActive, _f = props.position, position = _f === void 0 ? 'top' : _f, _g = props.showOn, showOn = _g === void 0 ? 'click' : _g, zIndex = props.zIndex, rest = __rest(props, ["align", "animateOnMount", "borderRadius", "boxShadow", "children", "distance", "isActive", "position", "showOn", "zIndex"]);
    var _h = __read(useState(false), 2), isPopoverVisible = _h[0], setIsPopoverVisible = _h[1];
    var toggleVisibility = function () {
        setIsPopoverVisible(!isPopoverVisible);
    };
    var onMouseEnter = function () {
        setIsPopoverVisible(true);
    };
    var onMouseLeave = function () {
        setIsPopoverVisible(false);
    };
    var isVisible = showOn === 'custom' ? isActive : isPopoverVisible;
    var hoverProps = showOn === 'hover' && {
        onMouseLeave: onMouseLeave,
        onMouseEnter: onMouseEnter,
    };
    var clickProps = showOn === 'click' && {
        onClick: toggleVisibility,
    };
    var animation = animateOnMount
        ? {
            animate: {
                scale: 1,
                opacity: 1,
                y: '0%',
                transition: {
                    duration: 0.35,
                    ease: easings.smooth.array,
                },
            },
            initial: {
                scale: 0.95,
                opacity: 1,
                y: position === 'top' ? '10%' : '-10%',
            },
            exit: {
                scale: 0.95,
                opacity: 0,
                y: position === 'top' ? '10%' : '-10%',
                transition: {
                    duration: 0.35,
                    ease: easings.smooth.array,
                },
            },
            transition: {
                duration: 0.35,
                ease: easings.smooth.array,
            },
        }
        : {
            animate: {},
            initial: {},
            exit: {},
            transition: {},
        };
    return (React.createElement(Position, __assign({ position: showOn === 'custom' ? 'unset' : 'relative', width: 1, zIndex: zIndex }, hoverProps),
        showOn !== 'custom' && React.createElement("span", __assign({}, clickProps), children[0]),
        React.createElement(AnimatePresence, null, isVisible && (React.createElement(Container, { animate: animation.animate, exit: animation.exit, initial: animation.initial, key: "popover", transition: animation.transition },
            React.createElement(PopoverElement, __assign({ align: align, bg: "white", borderRadius: borderRadius, boxShadow: boxShadow, distance: distance, position: position }, rest),
                showOn !== 'custom' ? children[1] : children,
                React.createElement(Cover, { distance: distance, position: position })))))));
}
