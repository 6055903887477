import { __extends } from "tslib";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            hasError: false,
        };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function () {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    };
    // When the location changes (back button clicked etc.) if the component
    // is in an error state, it is cleared enabling the children to be
    // rendered again.
    ErrorBoundary.prototype.componentDidMount = function () {
        var _this = this;
        this.unlistenHistory = this.props.history.listen(function () {
            if (_this.state.hasError) {
                _this.setState({
                    hasError: false,
                });
            }
        });
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        var _a;
        console.log(error);
        console.log(errorInfo);
        if (process.env.DEPLOY_ENV.includes('production') &&
            typeof window !== 'undefined' &&
            ((_a = window.LogRocket) === null || _a === void 0 ? void 0 : _a._isInitialized)) {
            // eslint-disable-next-line no-undef
            LogRocket.captureException(error, { extra: { errorInfo: errorInfo } });
        }
    };
    ErrorBoundary.prototype.componentWillUnmount = function () {
        if (this.unlistenHistory)
            this.unlistenHistory();
    };
    ErrorBoundary.prototype.render = function () {
        var ErrorComponent = this.props.component;
        if (this.state.hasError) {
            return React.createElement(ErrorComponent, null);
        }
        // should just this.props.children work here (it's in the docs but breaks
        // here unless wrapped in a fragment 🤔)
        return React.createElement(React.Fragment, null, this.props.children);
    };
    return ErrorBoundary;
}(Component));
export default withRouter(ErrorBoundary);
