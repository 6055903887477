import TUPLGContent from './TUPLGContent';
import { articles } from './articles';
export function getPLGUpsellContent(_a) {
    var monthsSinceSignup = _a.monthsSinceSignup;
    var id = (articles[monthsSinceSignup] || articles[0]).id;
    return {
        identifier: id,
        component: TUPLGContent,
        isClosable: true,
        condition: monthsSinceSignup < 5,
        daysUntilReshownAfterClose: 30,
    };
}
