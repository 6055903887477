import { get } from 'lodash-es';
import React from 'react';
import { NotificationBar } from '@marvelapp/ui';
import { Link } from '@marvelapp/ui-internal';
function NBStunning(_a) {
    var stunning = _a.stunning, clickedCTA = _a.clickedCTA;
    var status = get(stunning, 'response.status');
    var isExpiring = status === 'expiring';
    return (React.createElement(NotificationBar, { bg: "red", hasMenuButtonOnPage: true },
        isExpiring
            ? 'Action required: Your card on file is expiring soon! Please update your details to avoid any issues with your account.'
            : 'Action required: Your card has expired. Update your card to avoid your account being cancelled.',
        ' ',
        React.createElement(Link, { "data-testid": "cta", hasUnderline: true, kind: "notificationBar", onClick: clickedCTA, to: stunning.response.url }, "Update now")));
}
export default NBStunning;
