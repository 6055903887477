// TODO: Unify input, button and select sizes
export var selectSizes = [
    {
        fontSize: 1,
        borderRadius: 0,
        height: 26,
        lineHeight: '26px',
        pl: 2,
        pr: 24,
    },
    {
        fontSize: 2,
        borderRadius: 1,
        height: 30,
        lineHeight: '30px',
        pl: 3,
        pr: 30,
    },
    {
        fontSize: [1, 2, 2, 2],
        borderRadius: 1,
        height: [30, 40, 40, 40],
        lineHeight: ['30px', '40px', '40px', '40px'],
        pl: 3,
        pr: 40,
    },
    {
        fontSize: 3,
        borderRadius: 1,
        height: 50,
        lineHeight: '50px',
        pl: 3,
        pr: 50,
    },
];
export var loaderSizes = [
    {
        size: 12,
        borderWidth: 2,
    },
    {
        size: 16,
        borderWidth: 2,
    },
    {
        size: 20,
        borderWidth: 4,
    },
    {
        size: 24,
        borderWidth: 4,
    },
];
