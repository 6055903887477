import { __assign, __rest } from "tslib";
import React from 'react';
import { Rnd } from 'react-rnd';
/** @param {any} */
function Floaty(_a) {
    var children = _a.children, _b = _a.defaultWidth, defaultWidth = _b === void 0 ? 'auto' : _b, _c = _a.defaultHeight, defaultHeight = _c === void 0 ? 'auto' : _c, _d = _a.defaultX, defaultX = _d === void 0 ? 0 : _d, _e = _a.defaultY, defaultY = _e === void 0 ? 0 : _e, _f = _a.zIndex, zIndex = _f === void 0 ? 0 : _f, _g = _a.bounds, bounds = _g === void 0 ? 'parent' : _g, pointerEvents = _a.pointerEvents, onDragStart = _a.onDragStart, rest = __rest(_a, ["children", "defaultWidth", "defaultHeight", "defaultX", "defaultY", "zIndex", "bounds", "pointerEvents", "onDragStart"]);
    // this is needed for marvel-docs because during the build Rnd is not available, which breaks the build
    // please don't remove
    if (!Rnd) {
        return null;
    }
    return (React.createElement(Rnd, __assign({ bounds: bounds, default: {
            x: defaultX,
            y: defaultY,
            width: defaultWidth,
            height: defaultHeight,
        }, onDragStart: function (e) {
            onDragStart();
            e.preventDefault(); // Avoids cursor selection of elements behind the Floaty
        }, onResizeStart: function (e) {
            e.preventDefault(); // Avoids cursor selection of elements behind the Floaty
        }, style: { zIndex: zIndex, pointerEvents: pointerEvents } }, rest), children));
}
export default Floaty;
