import { __assign, __rest } from "tslib";
import React from 'react';
import { TRIANGLE_DOWN_ICON } from '@marvelapp/theme';
import Icon from '../Icon';
import Loader from '../Loader';
import Position from '../Position';
import kinds from './kinds';
import { loaderSizes, selectSizes } from './sizes';
import { Arrow, SelectElement } from './styles';
/** @param {any} */
function Select(_a) {
    var children = _a.children, defaultValue = _a.defaultValue, disabled = _a.disabled, _b = _a.iconColor, iconColor = _b === void 0 ? 'silver' : _b, iconSize = _a.iconSize, isLoading = _a.isLoading, kind = _a.kind, placeholder = _a.placeholder, showPlaceholder = _a.showPlaceholder, size = _a.size, props = __rest(_a, ["children", "defaultValue", "disabled", "iconColor", "iconSize", "isLoading", "kind", "placeholder", "showPlaceholder", "size"]);
    return (React.createElement(Position, { position: "relative" },
        React.createElement(SelectElement, __assign({ as: "select", defaultValue: defaultValue, disabled: disabled, pointerEvents: disabled ? 'none' : 'all', showPlaceholder: showPlaceholder }, selectSizes[size], kinds[kind], props),
            defaultValue && (React.createElement("option", { "data-testid": "select-placeholder", disabled: true, selected: true, value: defaultValue }, defaultValue)),
            children),
        React.createElement(Arrow, { color: iconColor, height: iconSize || selectSizes[size].height, width: iconSize || selectSizes[size].height }, isLoading ? (React.createElement(Loader, __assign({}, loaderSizes[size]))) : (React.createElement(Icon, __assign({ paths: TRIANGLE_DOWN_ICON }, loaderSizes[size]))))));
}
/** @type {any} */
Select.defaultProps = {
    bg: 'white',
    border: 'none',
    disabled: false,
    kind: 'ghost',
    size: 1,
    width: 1,
};
export default Select;
