import { __awaiter, __generator, __values } from "tslib";
/* GLOBALS __DEV__ */
import { ApolloProvider } from '@apollo/react-hooks';
import 'minireset.css/minireset.css';
import pathToRegexp from 'path-to-regexp';
import React from 'react';
// react-apollo is being replaced with @apollo/react-XXX
import { ApolloProvider as LegacyApolloProvider } from 'react-apollo';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider as ThemeUIProvider } from 'theme-ui';
import { ErrorBoundary } from '@marvelapp/marvel-3-application';
import { ThemeProvider } from '@marvelapp/styled';
import theme from '@marvelapp/theme';
import { UnhandledError } from '@marvelapp/ui-internal';
import AppContainer from '../components/AppContainer';
import createGqlClient from '../createGqlClient';
import { routingMap } from '../routingMap';
import { GlobalStyle } from '../theme/globalStyles';
import { getAuth } from '../utils/auth';
createApp();
export default function createApp() {
    return __awaiter(this, void 0, void 0, function () {
        var accessToken, routingMap_1, routingMap_1_1, route, regex, mountNode, client, app;
        var e_1, _a;
        return __generator(this, function (_b) {
            accessToken = getAuth();
            try {
                // While we are getting the info we need, we can preload the view
                // that we will be showing
                // eslint-disable-next-line no-restricted-syntax
                for (routingMap_1 = __values(routingMap), routingMap_1_1 = routingMap_1.next(); !routingMap_1_1.done; routingMap_1_1 = routingMap_1.next()) {
                    route = routingMap_1_1.value;
                    regex = pathToRegexp(route.path, [], { end: false });
                    if (regex.test(document.location.pathname)) {
                        route.preload();
                        break;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (routingMap_1_1 && !routingMap_1_1.done && (_a = routingMap_1.return)) _a.call(routingMap_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            mountNode = document.getElementById('root');
            client = createGqlClient({ accessToken: accessToken });
            app = (React.createElement(ThemeProvider, { theme: theme },
                React.createElement(ThemeUIProvider, { theme: theme },
                    React.createElement(GlobalStyle, { color: "slate", fontFamily: 0 }),
                    React.createElement(BrowserRouter, null,
                        React.createElement(ErrorBoundary, { component: UnhandledError },
                            React.createElement(ApolloProvider, { client: client },
                                React.createElement(LegacyApolloProvider, { client: client },
                                    React.createElement(AppContainer, { accessToken: accessToken }))))))));
            ReactDOM.render(app, mountNode);
            return [2 /*return*/];
        });
    });
}
