import { __assign, __rest } from "tslib";
import React from 'react';
import { Tooltip } from 'react-lightweight-tooltip';
import { positions } from './positions';
import { getTooltipStyle } from './styles';
/** @param {any} */
function StyledTooltip(_a) {
    var arrowRight = _a.arrowRight, children = _a.children, content = _a.content, cursor = _a.cursor, display = _a.display, fontSize = _a.fontSize, fontWeight = _a.fontWeight, maxWidth = _a.maxWidth, padding = _a.padding, pointerEvents = _a.pointerEvents, position = _a.position, whiteSpace = _a.whiteSpace, width = _a.width, props = __rest(_a, ["arrowRight", "children", "content", "cursor", "display", "fontSize", "fontWeight", "maxWidth", "padding", "pointerEvents", "position", "whiteSpace", "width"]);
    if (!content)
        return children;
    return (React.createElement(Tooltip, __assign({ content: content, styles: getTooltipStyle({
            fontSize: fontSize,
            arrowRight: arrowRight,
            cursor: cursor,
            display: display,
            fontWeight: fontWeight,
            maxWidth: maxWidth,
            padding: padding,
            pointerEvents: pointerEvents,
            position: positions[position],
            whiteSpace: whiteSpace,
            width: width,
        }) }, props), children));
}
StyledTooltip.defaultProps = {
    position: 'top',
    cursor: 'pointer',
    display: 'inline-block',
};
export default StyledTooltip;
