import { __assign, __rest } from "tslib";
import React from 'react';
import { pluralize } from '@marvelapp/core';
import { NotificationBar } from '@marvelapp/ui';
import { Link } from '@marvelapp/ui-internal';
function NBEnterpriseRenew(_a) {
    var userInfo = _a.userInfo, clickedCTA = _a.clickedCTA, rest = __rest(_a, ["userInfo", "clickedCTA"]);
    var isOwner = userInfo.isOwner, ownerEmail = userInfo.ownerEmail, daysToNextPayment = userInfo.daysToNextPayment, isAccountLocked = userInfo.isAccountLocked;
    return (React.createElement(NotificationBar, __assign({ bg: "orange", hasMenuButtonOnPage: true }, rest),
        React.createElement(ExpiryDetails, { daysToNextPayment: daysToNextPayment, isAccountLocked: isAccountLocked }),
        React.createElement(ContactDetails, { clickedCTA: clickedCTA, isOwner: isOwner, ownerEmail: ownerEmail })));
}
function ExpiryDetails(_a) {
    var isAccountLocked = _a.isAccountLocked, daysToNextPayment = _a.daysToNextPayment;
    var expiryString = daysToNextPayment < 1
        ? 'today'
        : "in ".concat(daysToNextPayment, " ").concat(pluralize(daysToNextPayment, 'day'));
    return isAccountLocked ? (React.createElement(React.Fragment, null, "Your account has expired. ")) : (React.createElement(React.Fragment, null,
        "Your account is due to renew ",
        expiryString,
        ". "));
}
function ContactDetails(_a) {
    var isOwner = _a.isOwner, clickedCTA = _a.clickedCTA, ownerEmail = _a.ownerEmail;
    return isOwner ? (React.createElement(ContactDetailsForOwner, { clickedCTA: clickedCTA })) : (React.createElement(ContactDetailsForMember, { clickedCTA: clickedCTA, ownerEmail: ownerEmail }));
}
function ContactDetailsForOwner(_a) {
    var clickedCTA = _a.clickedCTA;
    return (React.createElement(React.Fragment, null,
        "Please",
        ' ',
        React.createElement(Link, { "data-testid": "contact-details-cta", hasUnderline: true, href: "mailto:experience@marvelapp.com", kind: "white", onClick: clickedCTA }, "contact your Customer Experience Manager"),
        ' ',
        "to discuss a renewal, ensuring uninterrupted access to projects."));
}
function ContactDetailsForMember(_a) {
    var clickedCTA = _a.clickedCTA, ownerEmail = _a.ownerEmail;
    return (React.createElement(React.Fragment, null,
        "Please",
        ' ',
        React.createElement(Link, { hasUnderline: true, href: "mailto:".concat(ownerEmail), kind: "white", onClick: clickedCTA }, "contact your workspace owner"),
        ' ',
        "to ensure uninterrupted access to projects."));
}
export default NBEnterpriseRenew;
