import { __awaiter, __generator, __read } from "tslib";
import { useEffect, useState } from 'react';
import { useCurrentUser, userSelectors } from '@marvelapp/marvel-3-application';
export var useStunning = function () {
    var _a = __read(useState(null), 2), response = _a[0], setResponse = _a[1];
    var _b = __read(useState(null), 2), error = _b[0], setError = _b[1];
    var user = useCurrentUser();
    // Use as id cus_testcustomerid if you want to test the banner
    var stripeCustomerId = userSelectors.customerId(user);
    useEffect(function () {
        var endpoint = 'https://bar.stunning.co/pre_dunning_check/';
        var ckey = '653xfiuhzbplpezyxetrqwkc';
        var fullEndpoint = "".concat(endpoint + ckey, "/").concat(stripeCustomerId);
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var res, json, errorCatch_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch(fullEndpoint)];
                    case 1:
                        res = _a.sent();
                        return [4 /*yield*/, res.json()];
                    case 2:
                        json = _a.sent();
                        setResponse(json);
                        return [3 /*break*/, 4];
                    case 3:
                        errorCatch_1 = _a.sent();
                        setError(errorCatch_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        if (stripeCustomerId) {
            fetchData();
        }
    }, [stripeCustomerId]);
    return { response: response, error: error };
};
