import { camelCase as _camelCase, snakeCase as _snakeCase, isArray, isPlainObject, isString, } from 'lodash-es';
function walk(obj, transformFn) {
    if (isArray(obj)) {
        return obj.map(function (entry) { return walk(entry, transformFn); });
    }
    if (!isPlainObject(obj)) {
        return obj;
    }
    return Object.keys(obj).reduce(function (acc, key) {
        var newKey = transformFn(key);
        acc[newKey] = walk(obj[key], transformFn);
        return acc;
    }, {});
}
export function camelCase(obj) {
    if (isString(obj)) {
        return _camelCase(obj);
    }
    return walk(obj, _camelCase);
}
export function snakeCase(obj) {
    if (isString(obj)) {
        return _snakeCase(obj);
    }
    return walk(obj, _snakeCase);
}
