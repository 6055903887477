import { __assign, __makeTemplateObject, __read } from "tslib";
import { useMutation } from '@apollo/react-hooks';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { matchPath, useLocation } from 'react-router-dom';
import { heapTrackEvent } from '@marvelapp/core';
import { useCurrentUser } from '@marvelapp/marvel-3-application';
import styled from '@marvelapp/styled';
import { Box, Button, Text } from '@marvelapp/ui';
import ContentBox from './ContentBox';
import Mask from './Mask';
import { INTERVAL_MS } from './constants';
import data from './data';
import { useEventListener } from './hooks/useEventListener';
import { useInterval } from './hooks/useInterval';
import { UPDATE_USER_PROPERTIES } from './queries/mutations';
export default function MarvelTour() {
    // 💆‍♀️ State
    var location = useLocation();
    var user = useCurrentUser();
    var _a = __read(useState(null), 2), foundElementRect = _a[0], setFoundElementRect = _a[1];
    var _b = __read(useMutation(UPDATE_USER_PROPERTIES), 1), updateUserProperties = _b[0];
    // 🧚 Helpers
    var flowsWithMatchingPaths = data.filter(function (flow) {
        return flow.steps.find(function (step) { return isValidPath(location, step); });
    });
    var flows = flowsWithMatchingPaths.map(function (f) {
        var cs = user.properties[f.id];
        return {
            id: f.id,
            step: f.steps[cs],
            totalSteps: f.steps.length,
        };
    });
    var matchingFlow = flows[0];
    var id = matchingFlow === null || matchingFlow === void 0 ? void 0 : matchingFlow.id;
    var totalSteps = matchingFlow === null || matchingFlow === void 0 ? void 0 : matchingFlow.totalSteps;
    var step = matchingFlow === null || matchingFlow === void 0 ? void 0 : matchingFlow.step;
    var currentStep = user.properties[id];
    var showTour = step && foundElementRect;
    // 🧰 Actions
    var completeCurrentStep = useCallback(function () {
        var _a, _b;
        heapTrackEvent('Marvel Tour Completed', {
            identifier: id,
            step: currentStep,
        });
        setFoundElementRect(null);
        updateUserProperties({
            variables: (_a = {}, _a[id] = currentStep + 1, _a),
            optimisticResponse: {
                updateUserProperties: {
                    ok: true,
                    user: {
                        pk: user.pk,
                        properties: __assign(__assign({}, user.properties), (_b = {}, _b[id] = currentStep + 1, _b)),
                        __typename: 'UserNode',
                    },
                    __typename: 'updateUserProperties',
                },
            },
        });
    }, [id, currentStep, updateUserProperties, user.pk, user.properties]);
    var checkIfCompletedElementAppeared = useCallback(function () {
        if (!(step === null || step === void 0 ? void 0 : step.completeOnAppearance)) {
            return null;
        }
        var elementsAppeared = queryElementsVisible(step.completeOnAppearance, step.completeOnAppearanceExpected || 1);
        if (elementsAppeared && isValidPath(location, step)) {
            completeCurrentStep();
        }
    }, [completeCurrentStep, location, step]);
    var checkForTrigger = useCallback(function () {
        if (step) {
            var element = document.querySelector(step.trigger);
            if (element && htmlElementIsVisible(element)) {
                setFoundElementRect(element.getBoundingClientRect());
            }
            else {
                setFoundElementRect(null);
            }
        }
    }, [step]);
    var onClick = useCallback(function (e) {
        if ((step === null || step === void 0 ? void 0 : step.complete) &&
            e.target.matches("".concat(step.complete, ", ").concat(step.complete, " *"))) {
            completeCurrentStep();
        }
    }, [completeCurrentStep, step]);
    var skip = useCallback(function () {
        var _a;
        heapTrackEvent('Marvel Tour Skipped', {
            identifier: id,
            step: currentStep,
        });
        updateUserProperties({
            variables: (_a = {}, _a[id] = totalSteps, _a),
        });
    }, [currentStep, id, totalSteps, updateUserProperties]);
    // ⏰ Checks constantly for changes
    var inspectPage = useCallback(function () {
        checkForTrigger();
        checkIfCompletedElementAppeared();
    }, [checkForTrigger, checkIfCompletedElementAppeared]);
    // 🪝 Hooks
    useEventListener('click', onClick);
    useInterval(inspectPage, step ? INTERVAL_MS : null);
    // 📈 Analytics
    useEffect(function () {
        if (showTour && step) {
            heapTrackEvent('Marvel Tour Appeared', {
                identifier: step.id,
                step: currentStep,
            });
        }
    }, [currentStep, showTour, step]);
    var animation = {
        animate: {
            opacity: 1,
        },
        initial: {
            opacity: 0,
        },
        transition: {
            duration: 0.6,
        },
    };
    var zIndex = 'modal';
    return (React.createElement(AnimatePresence, null, showTour && (React.createElement(Animatable, { animate: animation.animate, initial: animation.initial, key: "tour", transition: animation.transition },
        React.createElement(Box, null,
            React.createElement(Mask, { disableInteraction: step.disableInteractionMask, padding: 10, rounded: 10, targetHeight: foundElementRect.height, targetLeft: foundElementRect.left, targetTop: foundElementRect.top, targetWidth: foundElementRect.width, windowHeight: window.innerHeight, windowWidth: window.innerWidth, zIndex: zIndex }),
            React.createElement(FocusLock, null,
                React.createElement(ContentBox, { currentStep: currentStep + 1, position: step.position, rect: foundElementRect, totalSteps: totalSteps, zIndex: zIndex },
                    React.createElement(Text, { fontSize: 2 }, step.content),
                    step.skippable && (React.createElement(Button, { kind: "ghost", mt: 3, onClick: skip, size: 0 }, "Skip tutorial")))))))));
}
function queryElementsVisible(query, total) {
    var completedElements = Array.from(document.querySelectorAll(query));
    var completedElementsVisible = completedElements.filter(function (e) {
        return htmlElementIsVisible(e);
    });
    return completedElementsVisible.length >= total;
}
function htmlElementIsVisible(el) {
    var style = getComputedStyle(el);
    var rect = el.getBoundingClientRect();
    var isZeroSize = (rect === null || rect === void 0 ? void 0 : rect.width) === 0 || (rect === null || rect === void 0 ? void 0 : rect.height) === 0;
    var isHidden = style.visibility === 'hidden' || style.display === 'none' || isZeroSize;
    return !isHidden;
}
function isValidPath(location, stepData) {
    var path = stepData === null || stepData === void 0 ? void 0 : stepData.path;
    if (!path) {
        return false;
    }
    return matchPath(location.pathname, {
        path: path,
        exact: true,
    });
}
var Animatable = styled(motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  z-index: ", ";\n  position: fixed;\n"], ["\n  z-index: ", ";\n  position: fixed;\n"])), function (props) { return props.theme.zIndices.modal; });
var templateObject_1;
