import { __read, __spreadArray } from "tslib";
import { cloneDeep, merge } from 'lodash-es';
import { devOverrides, isDebugMode } from '@marvelapp/core';
export default function applyUserOverrides(user, adminBillingData) {
    var _a;
    var billing = (_a = adminBillingData === null || adminBillingData === void 0 ? void 0 : adminBillingData.user) === null || _a === void 0 ? void 0 : _a.company.billing;
    var billingOverride = billing ? { company: { billing: billing } } : {};
    var storedOverrides = getStoredOverrides();
    return merge.apply(void 0, __spreadArray([cloneDeep(user), billingOverride], __read(storedOverrides), false));
}
function getStoredOverrides() {
    if (isDebugMode()) {
        var storedRole = devOverrides.role.get();
        var storedPlanLabel = devOverrides.plan.get();
        var roleOverride = storedRole ? { role: storedRole } : {};
        var planLabelOverride = storedPlanLabel
            ? { company: { billing: { plan: { label: storedPlanLabel } } } }
            : {};
        return [roleOverride, planLabelOverride];
    }
    return {};
}
