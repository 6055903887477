import { __assign, __makeTemplateObject } from "tslib";
import React from 'react';
import styled, { css } from '@marvelapp/styled';
import BlackOverlay from '../BlackOverlay';
import Box from '../Box';
import EscKeyHandler from '../EscKeyHandler';
import TransparentOverlay from '../TransparentOverlay';
function SidebarDialog(_a) {
    var children = _a.children, forwardedRef = _a.forwardedRef, isOpen = _a.isOpen, _b = _a.isRight, isRight = _b === void 0 ? false : _b, trigger = _a.trigger, _c = _a.width, width = _c === void 0 ? [1, 380, 380, 380] : _c, _d = _a.zIndex, zIndex = _d === void 0 ? 'modal' : _d;
    return (React.createElement(React.Fragment, null,
        React.createElement(BlackOverlay, { isVisible: isOpen, zIndex: zIndex }),
        isOpen && (React.createElement(React.Fragment, null,
            React.createElement(EscKeyHandler, { onEscapeKey: trigger }),
            React.createElement(TransparentOverlay, { onClick: trigger, zIndex: zIndex }))),
        React.createElement(Sidebar, { boxShadow: isRight ? 'sidebarOverlay.right' : 'sidebarOverlay.left', isRight: isRight, isVisible: isOpen, ref: forwardedRef, width: width, zIndex: zIndex }, isOpen && React.createElement(React.Fragment, null, children))));
}
export default React.forwardRef(function (props, ref) { return (React.createElement(SidebarDialog, __assign({}, props, { forwardedRef: ref }))); });
var Sidebar = styled(Box).attrs({
    bg: 'white',
    transition: 'smooth.medium',
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: fixed;\n  top: 0;\n  bottom: 0;\n\n  ", ";\n"], ["\n  position: fixed;\n  top: 0;\n  bottom: 0;\n\n  ", ";\n"])), function (props) {
    return props.isRight
        ? css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          right: 0;\n          transform: translateX(", ");\n        "], ["\n          right: 0;\n          transform: translateX(", ");\n        "])), props.isVisible ? '0' : '105%') : css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          left: 0;\n          transform: translateX(", ");\n        "], ["\n          left: 0;\n          transform: translateX(", ");\n        "])), props.isVisible ? '0' : '-105%');
});
var templateObject_1, templateObject_2, templateObject_3;
