import { __assign, __rest } from "tslib";
import React from 'react';
import EmptyState from '../EmptyState';
import EmptyStateWrapper from '../EmptyStateWrapper';
/** @type any */
var NoItems = function (_a) {
    var _b = _a.imgMaxWidth, imgMaxWidth = _b === void 0 ? 378 : _b, maxWidth = _a.maxWidth, src = _a.src, src2x = _a.src2x, subtitle = _a.subtitle, testId = _a.testId, title = _a.title, width = _a.width, wrapperMaxWidth = _a.wrapperMaxWidth, rest = __rest(_a, ["imgMaxWidth", "maxWidth", "src", "src2x", "subtitle", "testId", "title", "width", "wrapperMaxWidth"]);
    return (React.createElement(EmptyStateWrapper, __assign({ maxWidth: wrapperMaxWidth, mt: 1, width: width }, rest),
        React.createElement(EmptyState, { image: { src: src, src2x: src2x, maxWidth: imgMaxWidth, width: 1 }, maxWidth: maxWidth, subtitle: subtitle, testId: testId, title: title })));
};
export default NoItems;
