import React, { createContext, useContext, useMemo } from 'react';
import { createPermissions } from './createPermissions';
var PermissionsContext = createContext(null);
export var PermissionsProvider = function (props) {
    var permissions = useMemo(function () { return createPermissions(props.user, props.subscription); }, [props.user, props.subscription]);
    return (React.createElement(PermissionsContext.Provider, { value: permissions }, props.children));
};
export function usePermissions() {
    return useContext(PermissionsContext);
}
