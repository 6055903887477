import React from 'react';
import { Box, Flex, Heading } from '@marvelapp/ui';
import Cross from './Cross';
import IconButton from './IconButton';
export default function WindowHeader(_a) {
    var title = _a.title, onClose = _a.onClose, className = _a.className;
    return (React.createElement(Flex, { alignItems: "center", borderBottom: "1px solid", borderColor: "smoke", className: className, cursor: "move", justifyContent: "space-between", minHeight: 40, pl: 3, pr: 2 },
        React.createElement(Heading, { fontSize: 2, fontWeight: 500 }, title),
        React.createElement(Box, null, onClose && (React.createElement(IconButton, { onClick: onClose },
            React.createElement(Cross, null))))));
}
