import { __assign, __rest } from "tslib";
import React from 'react';
import { Button, Image, Link, TinyUpseller } from '@marvelapp/ui';
import { articles } from './articles';
export default function TUPLGContent(_a) {
    var userInfo = _a.userInfo, onClickClose = _a.onClickClose, clickedCTA = _a.clickedCTA, identifier = _a.identifier, rest = __rest(_a, ["userInfo", "onClickClose", "clickedCTA", "identifier"]);
    var data = articles.find(function (article) { return article.id === identifier; });
    return (React.createElement(TinyUpseller, __assign({ callToAction: React.createElement(Link, { href: data.url, onClick: clickedCTA, target: "_blank" },
            React.createElement(Button, { kind: "withShadow" }, "Read more")), image: React.createElement(Image, { display: "block", src: data.image, src2x: data.image2x, width: "100%" }), onClickClose: onClickClose, title: data.title }, rest)));
}
