export var devOverrides = {
    role: createLocalstorageOverrides('marvel-3-role-override', null),
    plan: createLocalstorageOverrides('marvel-3-plan-override', null),
    abTests: createLocalstorageOverrides('marvel-3-abTests-override', {}),
    featureFlags: createLocalstorageOverrides('marvel-3-flag-overrides', {}),
};
function createLocalstorageOverrides(key, fallbackValue) {
    return {
        get: function () { return getOverride(key, fallbackValue); },
        set: function (value) { return setOverride(key, value); },
        clear: function () { return clearOverride(key); },
    };
}
export function getOverride(storageKey, fallbackValue) {
    try {
        var rawData = window.sessionStorage.getItem(storageKey);
        return rawData ? JSON.parse(rawData) : fallbackValue;
    }
    catch (e) {
        return fallbackValue;
    }
}
export function setOverride(storageKey, value) {
    // can error if localstorage is full or if in private mode in some browsers
    try {
        window.sessionStorage.setItem(storageKey, JSON.stringify(value));
        // eslint-disable-next-line no-empty
    }
    catch (e) { }
}
export function clearOverride(storageKey) {
    window.sessionStorage.removeItem(storageKey);
}
