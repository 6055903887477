import { __read } from "tslib";
import { differenceInWeeks } from 'date-fns/esm';
import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { inject } from './inject';
function Delighted(_a) {
    var user = _a.user;
    var _b = __read(useState(false), 2), injectedScript = _b[0], setInjectedScript = _b[1];
    var _c = __read(useState(false), 2), surveyTriggered = _c[0], setSurveyTriggered = _c[1];
    var location = useLocation();
    var currentPath = location.pathname;
    useEffect(function () {
        if (!injectedScript) {
            inject();
            setInjectedScript(true);
        }
    }, [injectedScript]);
    useEffect(function () {
        var _a;
        if (surveyTriggered) {
            return;
        }
        var validRoutes = ['/projects', '/folders', '/archive', '/company'];
        var weeksSinceSignup = differenceInWeeks(new Date(), new Date(user.createdAt));
        var shouldTriggerSurvey = validRoutes.some(function (path) {
            return matchPath(currentPath, {
                path: path,
                exact: false,
                strict: false,
            });
        }) && weeksSinceSignup > 2;
        if (shouldTriggerSurvey) {
            var config = {
                email: user.email,
                name: user.username,
                properties: {
                    plan_label: (_a = user === null || user === void 0 ? void 0 : user.company) === null || _a === void 0 ? void 0 : _a.billing.plan.label,
                    occupation: user === null || user === void 0 ? void 0 : user.occupationDisplayName,
                },
            };
            window.delighted.survey(config);
            setSurveyTriggered(true);
        }
    }, [currentPath, location, surveyTriggered, user.email, user.username]);
    return null;
}
export default Delighted;
