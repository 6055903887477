import { __assign, __read, __rest } from "tslib";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { getFormattedImageUrl } from '@marvelapp/core';
import ContentLoader from '../ContentLoader';
import Image from '../Image';
import { kinds } from './kinds';
import { AnimationHolder, AvatarWrapper, Border, Online } from './styles';
import { usernameInitials } from './utils';
var GRAVATAR = 'gravatar.com';
/** @param {any} */
function Avatar(_a) {
    var alt = _a.alt, borderWidth = _a.borderWidth, _b = _a.borderColor, borderColor = _b === void 0 ? 'white' : _b, email = _a.email, bg = _a.bg, size = _a.size, src = _a.src, kind = _a.kind, username = _a.username, isOnline = _a.isOnline, greyscale = _a.greyscale, onlineDotBorderColor = _a.onlineDotBorderColor, rest = __rest(_a, ["alt", "borderWidth", "borderColor", "email", "bg", "size", "src", "kind", "username", "isOnline", "greyscale", "onlineDotBorderColor"]);
    var imageProps = {
        url: src,
        width: size,
        height: size,
        cropParams: '',
    };
    var croppedImageUrl = getFormattedImageUrl(__assign({ dpr: 1 }, imageProps));
    var croppedImageUrlRetina = getFormattedImageUrl(__assign({ dpr: 2 }, imageProps));
    // Temporary hack to remove gravatars
    // The API still returns them but we don't support them anymore
    if (src && src.includes(GRAVATAR)) {
        croppedImageUrl = undefined;
        croppedImageUrlRetina = undefined;
    }
    var initials = usernameInitials(username, email);
    var initialsUrl = "https://marvelapp.com/avatar/".concat(initials, ".svg?greyscale=").concat(greyscale);
    var hasBorder = borderWidth !== 0;
    var _c = __read(useState(false), 2), loadingSuccess = _c[0], setLoadingSuccess = _c[1];
    var _d = __read(useState(false), 2), loadingFinished = _d[0], setLoadingFinished = _d[1];
    return (React.createElement(AvatarWrapper, __assign({ bg: kinds[kind].bg, borderRadius: size / 2, "data-testid": "avatar", height: size, width: size }, rest),
        React.createElement(AnimationHolder, { opacity: loadingFinished ? 0 : 1 },
            React.createElement(ContentLoader, { borderRadius: size / 2, height: size, kind: kind, position: "absolute", top: 0, width: size })),
        React.createElement(AnimationHolder, { opacity: loadingFinished && !loadingSuccess ? 0 : 1 },
            React.createElement(Image, { alt: alt, borderRadius: size / 2, finishedLoading: function (result) {
                    setLoadingFinished(true);
                    setLoadingSuccess(result.success);
                }, height: size, objectFit: "cover", position: "absolute", src: croppedImageUrl || initialsUrl, src2x: croppedImageUrlRetina || initialsUrl, testId: "avatar-image", top: 0, width: size })),
        hasBorder && (React.createElement(Border, { border: "".concat(borderWidth, "px solid"), borderColor: borderColor, borderRadius: (size + 2) / 2 })),
        isOnline && (React.createElement(Online, { bg: "green", border: "2px solid", borderColor: onlineDotBorderColor }))));
}
/** @type {any} */
Avatar.propTypes = {
    onlineDotBorderColor: PropTypes.string,
    borderWidth: PropTypes.number,
    email: PropTypes.string,
    kind: PropTypes.oneOf(['medium', 'light', 'dark']),
    isOnline: PropTypes.bool,
    greyscale: PropTypes.bool,
    size: PropTypes.any,
    src: PropTypes.string,
    username: PropTypes.string,
};
/** @type {any} */
Avatar.defaultProps = {
    onlineDotBorderColor: 'white',
    borderWidth: 0,
    kind: 'medium',
    greyscale: false,
    isOnline: false,
};
export default Avatar;
