import { __assign, __rest } from "tslib";
import React, { forwardRef } from 'react';
import { inputKinds } from '../Input/kinds';
import { inputSizes } from './sizes';
import { TextareaElement } from './styles';
/** @type {any} */
var Textarea = forwardRef(function (_a, ref) {
    var _b = _a.disabled, disabled = _b === void 0 ? false : _b, _c = _a.display, display = _c === void 0 ? 'block' : _c, _d = _a.fontFamily, fontFamily = _d === void 0 ? 0 : _d, _e = _a.fontWeight, fontWeight = _e === void 0 ? 400 : _e, _f = _a.isLoading, isLoading = _f === void 0 ? false : _f, _g = _a.kind, kind = _g === void 0 ? 'default' : _g, _h = _a.size, size = _h === void 0 ? 1 : _h, props = __rest(_a, ["disabled", "display", "fontFamily", "fontWeight", "isLoading", "kind", "size"]);
    var allProps = __assign(__assign(__assign({}, inputSizes[size]), inputKinds[kind]), props);
    return (React.createElement(TextareaElement, __assign({ disabled: disabled, display: display, fontFamily: fontFamily, fontWeight: fontWeight, isLoading: isLoading }, allProps, { ref: ref })));
});
export default Textarea;
