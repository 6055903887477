export var NetworkStatusCode;
(function (NetworkStatusCode) {
    NetworkStatusCode[NetworkStatusCode["Loading"] = 1] = "Loading";
    NetworkStatusCode[NetworkStatusCode["SetVariables"] = 2] = "SetVariables";
    NetworkStatusCode[NetworkStatusCode["FetchMore"] = 3] = "FetchMore";
    NetworkStatusCode[NetworkStatusCode["Refetch"] = 4] = "Refetch";
    NetworkStatusCode[NetworkStatusCode["Poll"] = 6] = "Poll";
    NetworkStatusCode[NetworkStatusCode["Ready"] = 7] = "Ready";
    NetworkStatusCode[NetworkStatusCode["Error"] = 8] = "Error";
})(NetworkStatusCode || (NetworkStatusCode = {}));
export var isInFlight = function (networkStatusCode) {
    return networkStatusCode < 7;
};
