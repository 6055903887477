import { __assign } from "tslib";
import React from 'react';
import { ToastContainer, cssTransition, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalTransitionStyles, ToastCloseButton, ToastCloseWrap, ToastContainerElement, } from './styles';
var SlideIn = cssTransition({
    enter: 'slideIn',
    exit: 'slideOut',
});
// This component needs to be rendered once in your application,
// so add it to the root of any app that needs to support toast notifications
// Api reference can be found here: https://github.com/fkhadra/react-toastify#api
function StyledToastContainer(props) {
    return (React.createElement("div", { "data-testid": "toast-container" },
        React.createElement(GlobalTransitionStyles, null),
        React.createElement(ToastContainerElement, null,
            React.createElement(ToastContainer, __assign({}, props)))));
}
StyledToastContainer.defaultProps = {
    closeButton: (React.createElement(ToastCloseWrap, null,
        React.createElement(ToastCloseButton, null))),
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    transition: SlideIn,
};
export { showErrorNotification, showGenericServerError, showLoadingNotification, showProgressNotification, showSketchNotification, showSuccessNotification, showWarningNotification, } from './notifications';
export { toast };
export default StyledToastContainer;
