import React from 'react';
import { CHECKBOX_TICK_ICON } from '@marvelapp/theme';
import { Box, Flex, Icon, Text } from '@marvelapp/ui';
import IconCircle from '../IconCircle';
export default function SuccessText(_a) {
    var alignItems = _a.alignItems, bg = _a.bg, borderColor = _a.borderColor, children = _a.children, color = _a.color, fontSize = _a.fontSize, iconColor = _a.iconColor;
    return (React.createElement(Flex, { alignItems: alignItems, "data-testid": "success-toast" },
        React.createElement(Box, { mr: 12 },
            React.createElement(IconCircle, { bg: bg, borderColor: borderColor, color: iconColor || color },
                React.createElement(Icon, { display: "block", paths: CHECKBOX_TICK_ICON }))),
        React.createElement(Flex, { alignItems: "center", minHeight: "26px" },
            React.createElement(Text, { color: color, fontSize: fontSize }, children))));
}
SuccessText.defaultProps = {
    alignItems: 'flex-start',
    color: 'greenDark',
    fontSize: 14,
};
