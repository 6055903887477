import { __assign } from "tslib";
import React from 'react';
import { Svg } from './styles';
export default function SketchIcon(props) {
    return (React.createElement(Svg, __assign({ height: "28", viewBox: "0 0 494 447", width: "36" }, props),
        React.createElement("path", { d: "M247 447l-247-287 107-145 140-15 140 15 107 145z", fill: "#ffae00" }),
        React.createElement("path", { d: "M247 447l-247-287h494z", fill: "#ec6c00" }),
        React.createElement("path", { d: "M247 447l-147-287h294z", fill: "#ffae00" }),
        React.createElement("path", { d: "M247 0l-147 160h294z", fill: "#ffefb4" }),
        React.createElement("path", { d: "M107 15l-55 73-52 72h101zm280 0l55 73 52 72h-101z", fill: "#ffae00" }),
        React.createElement("path", { d: "M107 15l-7 145 147-160zm280 0l7 145-147-160z", fill: "#fed305" })));
}
