import React from 'react';
import { Box, Button, Flex, Logo, Text } from '@marvelapp/ui';
import { Link, Page } from '@marvelapp/ui-internal';
function PrototypeUnavailable(_a) {
    var status = _a.status;
    // Important to set this because embeds are transparent, this avoids the prototype looking odd when embedded on a red page.
    var isEmbedded = window.top !== window.self;
    var backgroundColor = isEmbedded ? 'snow' : 'white';
    return (React.createElement(Page, { title: "This prototype is ".concat(status) },
        React.createElement(Flex, { alignItems: "center", bg: backgroundColor, flexDirection: "column", justifyContent: "center", maxWidth: "100vw", minHeight: "100vh", px: 4, py: [4, 4, 5, 5] },
            React.createElement(Link, { href: "/", target: "_self" },
                React.createElement(Logo, { color: "marvel", mb: 3 })),
            React.createElement(Flex, { alignItems: "center", flexDirection: "column", justifyContent: "center", maxWidth: "90%" },
                React.createElement(Text, { color: "licorice", fontSize: [2, 3, 4, 5], fontWeight: 500, mb: 2, textAlign: "center" },
                    "This prototype is ",
                    status),
                React.createElement(Text, { color: "black", fontSize: [1, 2, 3, 3], maxWidth: 345, mb: 4, textAlign: "center" }, "Ask the owner to verify the share link and/or update permissions to play this prototype."),
                React.createElement(Button, { as: Link, "data-testid": "archived-page-cta", hoverColor: "white", kind: "marvel", size: 2, to: "https://marvelapp.com" }, "Visit Marvel")),
            React.createElement(Box, null))));
}
export function PrototypeLocked() {
    return React.createElement(PrototypeUnavailable, { status: "locked" });
}
export function PrototypeArchived() {
    return React.createElement(PrototypeUnavailable, { status: "archived" });
}
