import React, { useEffect } from 'react';
import { heapTrackEvent } from '@marvelapp/core';
import Link from '../Link';
import BaseError from './BaseError';
export default function NotFound(_a) {
    var _b = _a.heading, heading = _b === void 0 ? '404 — page not found' : _b, headingMaxWidth = _a.headingMaxWidth, _c = _a.subheading, subheading = _c === void 0 ? 'Check out some of the useful links below:' : _c, subheadingMaxWidth = _a.subheadingMaxWidth;
    useEffect(function () {
        heapTrackEvent('404 Appeared', { pathname: window.location.pathname });
    }, []);
    var linkComponent = function (_a) {
        var target = _a.target, url = _a.url, name = _a.name;
        return (React.createElement(Link, { display: "block", key: url, kind: "marvel", mb: 2, target: target, to: url }, name));
    };
    return (React.createElement(BaseError, { heading: heading, headingMaxWidth: headingMaxWidth, renderLinkComponent: linkComponent, subheading: subheading, subheadingMaxWidth: subheadingMaxWidth }));
}
