import { __assign, __rest } from "tslib";
import PropTypes from 'prop-types';
import React from 'react';
import theme from '@marvelapp/theme';
import { Inner, Outer } from './styles';
function ProgressBar(_a) {
    var progress = _a.progress, rest = __rest(_a, ["progress"]);
    return (React.createElement(Outer, __assign({ bg: "smoke" }, rest),
        React.createElement(Inner, { bg: "marvel", width: "".concat(progress, "%") })));
}
ProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
};
ProgressBar.defaultProps = {
    progress: 50,
    theme: theme,
};
export default ProgressBar;
