import { __makeTemplateObject } from "tslib";
import styled, { css } from '@marvelapp/styled';
import { colors } from '@marvelapp/theme';
import { boxStyles } from '../Box';
import { textDecoration } from '../customProperties';
import { underlineColor } from './colors';
export var LinkElement = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", ";\n  ", "\n\n  cursor: pointer;\n  transition-duration: 150ms;\n\n  &.", " {\n    color: ", ";\n    font-weight: 500;\n\n    &:hover {\n      color: ", ";\n    }\n  }\n\n  ", ";\n"], ["\n  ", ";\n  ", "\n\n  cursor: pointer;\n  transition-duration: 150ms;\n\n  &.", " {\n    color: ", ";\n    font-weight: 500;\n\n    &:hover {\n      color: ", ";\n    }\n  }\n\n  ", ";\n"])), boxStyles, textDecoration, 'active', colors.licorice, colors.licorice, function (props) {
    return props.hasUnderline && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      background-position: 0 95%;\n      background-repeat: repeat-x;\n      background-size: 2px 2px;\n\n      background-image: linear-gradient(\n        to bottom,\n        rgba(0, 0, 0, 0) 50%,\n        ", " 50%\n      );\n\n      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {\n        background-image: linear-gradient(\n          to bottom,\n          rgba(0, 0, 0, 0) 75%,\n          ", " 75%\n        );\n      }\n    "], ["\n      background-position: 0 95%;\n      background-repeat: repeat-x;\n      background-size: 2px 2px;\n\n      background-image: linear-gradient(\n        to bottom,\n        rgba(0, 0, 0, 0) 50%,\n        ", " 50%\n      );\n\n      @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {\n        background-image: linear-gradient(\n          to bottom,\n          rgba(0, 0, 0, 0) 75%,\n          ", " 75%\n        );\n      }\n    "])), underlineColor[props.color], underlineColor[props.color]);
});
var templateObject_1, templateObject_2;
