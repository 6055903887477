import querystring from 'query-string';
var FASTLY = 'freetls.fastly.net';
export function getFormattedImageUrl(options, fastlyUrl) {
    if (fastlyUrl === void 0) { fastlyUrl = FASTLY; }
    var url = options.url, _a = options.dpr, dpr = _a === void 0 ? window.devicePixelRatio : _a, _b = options.width, width = _b === void 0 ? 300 : _b, height = options.height, _c = options.aspectRatio, aspectRatio = _c === void 0 ? '1:1' : _c, _d = options.cropParams, cropParams = _d === void 0 ? ',smart' : _d, canvas = options.canvas, bgColor = options.bgColor, _e = options.crop, crop = _e === void 0 ? true : _e;
    // If not from fastly we can't crop it, so just fallback
    if (!url || url.indexOf(fastlyUrl) < 0)
        return url;
    // Crop and resize using Fastly:
    // Some API images like avatars might come back with already params on the images
    // therefore we don't wanna remove those and just replace some of them.
    var parsed = querystring.parseUrl(url);
    parsed.query.dpr = "".concat(dpr) || parsed.query.dpr || undefined;
    if (width) {
        parsed.query.width = width;
    }
    if (height) {
        parsed.query.height = height;
    }
    if (crop) {
        parsed.query.crop =
            aspectRatio + cropParams || parsed.query.crop || undefined;
    }
    parsed.query.canvas = canvas;
    parsed.query['bg-color'] = bgColor;
    if (parsed.query.crop && parsed.query.width) {
        // When using crop you can't have a height and width
        parsed.query.height = undefined;
    }
    if (!parsed.query.width || !parsed.query.height) {
        // when using fit you need both height and width
        parsed.query.fit = undefined;
    }
    var queryStringified = querystring.stringify(parsed.query, {
        encode: false,
    });
    return "".concat(parsed.url, "?").concat(queryStringified);
}
