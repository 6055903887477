import { __assign, __rest } from "tslib";
import React from 'react';
import { Box, Flex, Loader, Text } from '@marvelapp/ui';
export default function LoadingText(_a) {
    var alignItems = _a.alignItems, children = _a.children, color = _a.color, fontSize = _a.fontSize, loaderColor = _a.loaderColor, props = __rest(_a, ["alignItems", "children", "color", "fontSize", "loaderColor"]);
    return (React.createElement(Flex, { alignItems: alignItems },
        React.createElement(Box, { mr: 12 },
            React.createElement(Loader, __assign({ color: loaderColor, size: 24 }, props))),
        React.createElement(Flex, { flex: "1", flexDirection: "column", justifyContent: "center", minHeight: "26px" },
            React.createElement(Text, { color: color, fontSize: fontSize }, children))));
}
LoadingText.defaultProps = {
    alignItems: 'flex-start',
    color: 'slate',
    fontSize: 14,
};
