var useEffect = require('react').useEffect;
export function useInterval(cb, interval) {
    if (interval === void 0) { interval = 1000; }
    useEffect(function () {
        if (interval === null)
            return;
        var id = setInterval(function () {
            cb();
        }, interval);
        return function () {
            clearInterval(id);
        };
    }, [cb, interval]);
}
