import { __assign, __rest } from "tslib";
import React from 'react';
import { Box, Button, HintBar, Image } from '@marvelapp/ui';
import { ModalLink } from '@marvelapp/ui-internal';
import money from './money.png';
import money2x from './money@2x.png';
export function HBSwitchToYearlyAccount(_a) {
    var userInfo = _a.userInfo, onClickClose = _a.onClickClose, clickedCTA = _a.clickedCTA;
    return (React.createElement(HBSwitchToYearly, { clickedCTA: clickedCTA, mb: 0, mt: 6, onClickClose: onClickClose, userInfo: userInfo }));
}
function HBSwitchToYearly(_a) {
    var userInfo = _a.userInfo, onClickClose = _a.onClickClose, clickedCTA = _a.clickedCTA, rest = __rest(_a, ["userInfo", "onClickClose", "clickedCTA"]);
    var discount = userInfo.isPro ? '25%' : '13%';
    return (React.createElement(HintBar, __assign({ bg: "steel", callToAction: React.createElement(ModalLink, { onClick: clickedCTA, to: "/switch-to-yearly-plan" },
            React.createElement(Button, { kind: "withShadow" }, "View details")), description: React.createElement(Box, { maxWidth: [240, 300, 300, 480] }, "Switch to the yearly plan today and save. Fewer invoices. More Marvel."), image: React.createElement(Image, { alt: "Flying money emoji", display: "block", src: money, src2x: money2x }), mt: 4, onClickClose: onClickClose, title: React.createElement(Box, { maxWidth: [240, 240, 240, 480] },
            "Save ",
            discount,
            " off your monthly bill and get a single invoice") }, rest)));
}
export default HBSwitchToYearly;
