import { __assign, __extends } from "tslib";
export function fetchApi(url, params) {
    if (params === void 0) { params = {}; }
    var body = params.body, headers = params.headers, accessToken = params.accessToken, method = params.method, credentials = params.credentials;
    return fetch(url, __assign({ body: body, headers: __assign(__assign({}, (accessToken ? { Authorization: "Bearer ".concat(accessToken) } : {})), headers), method: method, mode: 'cors' }, (credentials ? { credentials: credentials } : {}))).then(function (response) {
        if (!response.ok && response.status === 401) {
            // TODO: refresh the access token
            window.localStorage.removeItem('accessToken');
            window.location.href = '/login';
            Promise.resolve(response);
        }
        return response.ok && response.status >= 200 && response.status < 300
            ? Promise.resolve(response)
            : Promise.reject(response);
    });
}
var ResponseError = /** @class */ (function (_super) {
    __extends(ResponseError, _super);
    function ResponseError(_a) {
        var status = _a.status, data = _a.data;
        var _this = _super.call(this) || this;
        _this.status = status;
        _this.data = data;
        return _this;
    }
    return ResponseError;
}(Error));
export function fetchJSON(url, params) {
    var method = params.method, payload = params.payload, accessToken = params.accessToken, headers = params.headers;
    return fetchApi(url, {
        method: method,
        accessToken: accessToken,
        headers: __assign({ 'Content-Type': 'application/json' }, headers),
        body: JSON.stringify(payload),
    })
        .then(function (response) {
        var _a;
        if ((_a = response.headers.get('Content-Type')) === null || _a === void 0 ? void 0 : _a.startsWith('application/json')) {
            return response.json();
        }
        return {};
    })
        .catch(function (error) {
        if (!(error instanceof Response)) {
            throw error;
        }
        var response = error;
        return response.json().then(function (data) {
            throw new ResponseError({ status: response.status, data: data });
        });
    });
}
