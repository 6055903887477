/**
 *
 * Loader
 *
 */
import PropTypes from 'prop-types';
import React from 'react';
import Position from '../Position';
import { Spinny, Static } from './styles';
function Loader(_a) {
    var size = _a.size, color = _a.color, borderWidth = _a.borderWidth, testId = _a.testId, _b = _a.speed, speed = _b === void 0 ? 500 : _b;
    return (React.createElement(Position, { "data-testid": testId, display: "block", position: "relative" },
        React.createElement(Static, { borderColor: color, borderWidth: borderWidth, height: size, width: size }),
        React.createElement(Spinny, { borderColor: color, borderWidth: borderWidth, height: size, width: size, speed: speed })));
}
Loader.propTypes = {
    borderWidth: PropTypes.any,
    color: PropTypes.any,
    size: PropTypes.any,
    testId: PropTypes.string,
};
Loader.defaultProps = {
    borderWidth: '4px',
    color: 'silver',
    size: 24,
};
export default Loader;
