import { __extends } from "tslib";
import { throttle } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
var InfiniteScroll = /** @class */ (function (_super) {
    __extends(InfiniteScroll, _super);
    function InfiniteScroll(props) {
        var _this = _super.call(this, props) || this;
        _this.sentinelRef = createRef();
        // We need to keep a reference to the element we are adding listeners to
        // because the ref is null by the time we try to destroy
        _this.listenerElement = null;
        _this.checkScroll = function () {
            if (_this.props.isLoading) {
                return;
            }
            var isUsingWindow = !_this.props.containerEl;
            var sentinelOffset = _this.sentinelRef.current.getBoundingClientRect().bottom;
            var containerOffset = isUsingWindow
                ? // window is static so `innerHeight` will always be equivalent to its bottom value
                    window.innerHeight
                : _this.props.containerEl.getBoundingClientRect().bottom;
            var _a = _this.props, hasMore = _a.hasMore, threshold = _a.threshold, onLoadMore = _a.onLoadMore;
            // We measure how far below the container the sentinel currently is
            if (hasMore && sentinelOffset - containerOffset < threshold) {
                onLoadMore();
            }
        };
        var handler = throttle(_this.checkScroll, props.throttle);
        _this.scrollHandler = handler;
        _this.resizeHandler = handler;
        _this.sentinelPosition = { top: null, left: null };
        return _this;
    }
    InfiniteScroll.prototype.componentDidMount = function () {
        var container = this.getContainer();
        // On first mount the ref we pass in might be null because the parent has
        // not mounted yet. React calls componentDidMount and applies refs upwards
        if (container) {
            this.setupListeners(container);
        }
    };
    InfiniteScroll.prototype.componentDidUpdate = function () {
        var container = this.getContainer();
        if (container !== this.listenerElement) {
            if (this.listenerElement) {
                this.destroyListeners(this.listenerElement);
            }
            if (container) {
                this.setupListeners(container);
            }
        }
        var position = this.sentinelRef.current.getBoundingClientRect();
        var prevPosition = this.sentinelPosition;
        if (position.top !== prevPosition.top ||
            position.left !== prevPosition.left) {
            this.checkScroll();
            this.sentinelPosition = position;
        }
    };
    InfiniteScroll.prototype.componentWillUnmount = function () {
        var container = this.getContainer();
        this.destroyListeners(container);
    };
    /* eslint-disable class-methods-use-this */
    InfiniteScroll.prototype.getContainer = function () {
        // If we have passed in prop.containerRef, it means that we want to use a
        // custom container rather than window. So we should NEVER retun window in
        // this situation
        if (this.props.containerEl) {
            return this.props.containerEl;
        }
        return window;
    };
    InfiniteScroll.prototype.setupListeners = function (container) {
        container.addEventListener('scroll', this.scrollHandler);
        container.addEventListener('resize', this.resizeHandler);
        this.checkScroll();
        // After setting up, store a reference to the container to both allow us
        // to destory in the future and mark the listeners as set up
        this.listenerElement = container;
    };
    InfiniteScroll.prototype.destroyListeners = function (container) {
        if (!container)
            return;
        container.removeEventListener('scroll', this.scrollHandler);
        container.removeEventListener('resize', this.resizeHandler);
        this.listenerElement = null;
    };
    InfiniteScroll.prototype.render = function () {
        return (React.createElement("div", null,
            this.props.children,
            React.createElement("div", { ref: this.sentinelRef })));
    };
    return InfiniteScroll;
}(Component));
export default InfiniteScroll;
InfiniteScroll.propTypes = {
    // Does the resource have more entities,
    hasMore: PropTypes.bool,
    // Should show loading
    isLoading: PropTypes.bool.isRequired,
    // Callback to load more entities
    onLoadMore: PropTypes.func.isRequired,
    // Scroll threshold
    threshold: PropTypes.number.isRequired,
    // Throttle rate
    throttle: PropTypes.number.isRequired,
    // Your content
    children: PropTypes.node.isRequired,
    containerEl: PropTypes.object,
};
InfiniteScroll.defaultProps = {
    threshold: 100,
    throttle: 64,
};
