import { __makeTemplateObject } from "tslib";
import themeGet from '@styled-system/theme-get';
import { motion } from 'framer-motion';
import { border, color, flexbox, grid, layout, position, shadow, space, typography, } from 'styled-system';
import styled from '@marvelapp/styled';
import theme from '@marvelapp/theme';
import { activeBoxShadow, fill, hoverBg, hoverBoxShadow, hoverColor, hoverFill, pointerEvents, } from '../customProperties';
/** @type {any} */
export var ButtonElement = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0;\n  padding: 0;\n  border: none;\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  appearance: none;\n  box-sizing: border-box;\n  cursor: pointer;\n  outline-style: none;\n  text-align: center;\n  text-decoration: none;\n  user-select: none;\n  white-space: nowrap;\n  transition: all 300ms ", ";\n  will-change: transform;\n\n  &:hover {\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n    transform: ", ";\n  }\n\n  &:active {\n    ", ";\n    transform: ", ";\n  }\n"], ["\n  margin: 0;\n  padding: 0;\n  border: none;\n\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  appearance: none;\n  box-sizing: border-box;\n  cursor: pointer;\n  outline-style: none;\n  text-align: center;\n  text-decoration: none;\n  user-select: none;\n  white-space: nowrap;\n  transition: all 300ms ", ";\n  will-change: transform;\n\n  &:hover {\n    ", ";\n    ", ";\n    ", ";\n    ", ";\n    transform: ", ";\n  }\n\n  &:active {\n    ", ";\n    transform: ", ";\n  }\n"])), border, color, fill, flexbox, grid, layout, pointerEvents, position, shadow, space, typography, themeGet('easings.smooth.string'), hoverBg, hoverBoxShadow, hoverColor, hoverFill, function (props) {
    return props.translateOnHover ? 'translate3d(0px, -1px, 0px)' : 'none';
}, activeBoxShadow, function (props) {
    return props.translateOnHover ? 'translate3d(0px, 1px, 0px)' : 'none';
});
ButtonElement.defaultProps = {
    theme: theme,
};
export var IconContainer = styled(motion.div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var IconContainerWithText = styled(motion.div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n"], ["\n  flex: 1;\n"])));
IconContainer.defaultProps = {
    theme: theme,
};
var templateObject_1, templateObject_2, templateObject_3;
