import React from 'react';
import { Box, Flex, PulseAnimation, Text } from '@marvelapp/ui';
export default function CommentFooter(_a) {
    var isUnread = _a.isUnread, projectName = _a.projectName, projectPk = _a.projectPk, screenName = _a.screenName;
    return (React.createElement(Flex, { alignItems: "center" },
        isUnread && (React.createElement(PulseAnimation, { color: "red", isSmall: true, marginRight: 2 },
            React.createElement(Box, { bg: "red", borderRadius: "50%", boxShadow: "button.marvel", "data-testid": "unread-comment-marker", height: 6, width: 6 }))),
        React.createElement(Text, { fontSize: 12 }, projectPk ? (React.createElement(React.Fragment, null,
            "on",
            ' ',
            React.createElement(Text, { color: "marvelDark", display: "inline", fontSize: "13" }, screenName))) : (React.createElement(React.Fragment, null,
            "in",
            ' ',
            React.createElement(Text, { color: "marvelDark", display: "inline", fontSize: "13" }, projectName))))));
}
