export function usernameInitials(username, email) {
    var initials;
    var emailSplit;
    var firstLetter;
    var secondLetter;
    if (username) {
        try {
            initials = username
                .match(/\b(\w)/g)
                .join('')
                .substr(0, 2);
        }
        catch (e) {
            // Nope!
        }
    }
    if (!initials) {
        if (typeof email === 'undefined' || email === undefined) {
            return '';
        }
        emailSplit = email.split('@');
        if (emailSplit.length > 1) {
            firstLetter = emailSplit[0].charAt(0);
            secondLetter = emailSplit[1].charAt(0);
            initials = firstLetter + secondLetter;
        }
        else {
            initials = email.charAt(0);
        }
    }
    return initials;
}
