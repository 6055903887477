var _a;
// eslint-disable-next-line import/no-cycle
import { devOverrides } from './devOverrides';
import { Tool } from './tools';
export var ABTest;
(function (ABTest) {
    ABTest["WHITEBOARD_UI"] = "WHITEBOARD_UI";
    ABTest["FIGMA_USERTESTS"] = "FIGMA_USERTESTS";
})(ABTest || (ABTest = {}));
var abTestTriggers = (_a = {},
    _a[ABTest.WHITEBOARD_UI] = function () { return true; },
    _a[ABTest.FIGMA_USERTESTS] = function (user) {
        // Whiteboard UI is enabled for 100% of paying users
        return user.tool === Tool.Figma;
    },
    _a);
export var isInABTestVariant = function (testKey, user) {
    var overrides = devOverrides.abTests.get();
    var forcedOverride = overrides[testKey];
    if (forcedOverride) {
        return forcedOverride === 'variant';
    }
    return abTestTriggers[testKey](user) === true;
};
