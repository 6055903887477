import { __read } from "tslib";
import { useEffect, useState } from 'react';
function getWindowDimensions() {
    var width = window.innerWidth, height = window.innerHeight;
    return {
        width: width,
        height: height,
    };
}
export default function useWindowDimensions() {
    var _a = __read(useState(getWindowDimensions), 2), windowDimensions = _a[0], setWindowDimensions = _a[1];
    useEffect(function () {
        var handleResize = function () { return setWindowDimensions(getWindowDimensions()); };
        window.addEventListener('resize', handleResize);
        return function () { return window.removeEventListener('resize', handleResize); };
    }, []);
    return windowDimensions;
}
