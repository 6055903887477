export var Occupation;
(function (Occupation) {
    Occupation["Developer"] = "OCCUPATION_DEVELOPER";
    Occupation["Designer"] = "OCCUPATION_DESIGNER";
    Occupation["Marketing"] = "OCCUPATION_MARKETING_MANAGER";
    Occupation["ProductManager"] = "OCCUPATION_PRODUCT_MANAGER";
    Occupation["Head"] = "OCCUPATION_DIRECTOR";
    Occupation["Other"] = "OCCUPATION_OTHER";
    Occupation["Researcher"] = "OCCUPATION_RESEARCHER";
    Occupation["Founder"] = "OCCUPATION_FOUNDER";
    Occupation["Student"] = "OCCUPATION_STUDENT";
    Occupation["Teacher"] = "OCCUPATION_TEACHER";
    Occupation["Finance"] = "OCCUPATION_FINANCE";
    Occupation["Sales"] = "OCCUPATION_SALES";
    Occupation["Support"] = "OCCUPATION_SUPPORT";
    Occupation["IT"] = "OCCUPATION_IT";
})(Occupation || (Occupation = {}));
export var occupationLabels = {
    OCCUPATION_DEVELOPER: 'Developer',
    OCCUPATION_DESIGNER: 'Designer',
    OCCUPATION_MARKETING_MANAGER: 'Marketing',
    OCCUPATION_PRODUCT_MANAGER: 'Product Manager',
    OCCUPATION_DIRECTOR: 'Head / Director / VP',
    OCCUPATION_OTHER: 'Other',
    OCCUPATION_RESEARCHER: 'Researcher',
    OCCUPATION_FOUNDER: 'Founder / Entrepreneur',
    OCCUPATION_STUDENT: 'Student',
    OCCUPATION_TEACHER: 'Teacher',
    OCCUPATION_FINANCE: 'Finance',
    OCCUPATION_SALES: 'Sales',
    OCCUPATION_SUPPORT: 'Support',
    OCCUPATION_IT: 'IT',
};
