import { __assign } from "tslib";
import { get } from 'lodash-es';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { withApollo } from 'react-apollo';
import Drift from 'react-driftjs';
import { Redirect, matchPath, useLocation } from 'react-router-dom';
import { LastLocationProvider, useLastLocation, } from 'react-router-last-location';
import compose from 'recompose/compose';
import { EmailAddressType, Tool, heapTrackEvent } from '@marvelapp/core';
import { VideoOverlayProvider, VideoOverlayRoot } from '@marvelapp/help';
import { FeatureFlagsContext, Marvel3Provider, PublicRoute, useConfig, useCurrentUser, userSelectors, } from '@marvelapp/marvel-3-application';
import Tour from '@marvelapp/tour';
import { ModalPortalDestination } from '@marvelapp/ui';
import { BackLinkProvider, CenteredLoader, ModalSwitch, MultipleSessionBlocker, NotFound, ToastContainer, } from '@marvelapp/ui-internal';
import { FloatingUpsell } from '@marvelapp/upsells';
import { routingMap } from '../../routingMap';
import { dangerouslySetupGoogleTranslateObserver } from '../../utils/googleTranslateObserver';
import logout from '../../utils/logout';
import Delighted from '../Delighted';
import { PrototypeArchived, PrototypeLocked } from '../PrototypeUnavailable';
import Sidebar from '../Sidebar';
dangerouslySetupGoogleTranslateObserver();
var VALID_HEAP_ROUTES = ['/whiteboard', '/project/:projectId/design'];
function App(_a) {
    var _b, _c, _d;
    var apolloClient = _a.client, accessToken = _a.accessToken, user = _a.user, config = _a.config, pusher = _a.pusher, flags = _a.flags, refetchUserData = _a.refetchUserData, subscription = _a.subscription, setSubscription = _a.setSubscription, transactionalKey = _a.transactionalKey;
    var isProduction = process.env.NODE_ENV === 'production';
    // whiteboards are loading slowly and are potentially being blocked by drift so we are temporarily disabling it
    var disableDrift = true;
    var lastLocation = useLastLocation();
    var sidebar = useMemo(function () { return React.createElement(Sidebar, { user: user }); }, [user]);
    var modalContainerRef = useRef();
    return (React.createElement(React.Suspense, { fallback: React.createElement(CenteredLoader, null) },
        React.createElement(FeatureFlagsContext.Provider, { value: flags },
            React.createElement(Marvel3Provider, { accessToken: accessToken, apolloClient: apolloClient, config: config, featureFlags: flags, lastLocation: lastLocation, logout: logout, pusher: pusher, refetchUserData: refetchUserData, setSubscription: setSubscription, sidebar: sidebar, subscription: subscription, transactionalKey: transactionalKey, user: user },
                React.createElement(MultipleSessionBlocker, { accessToken: accessToken, config: config, logout: logout, pusher: pusher, user: user },
                    React.createElement(LastLocationProvider, null,
                        React.createElement(VideoOverlayProvider, null,
                            React.createElement(PublicRoute, null, function (routeProps) { return (React.createElement(BackLinkProvider, null,
                                React.createElement(ModalSwitch, __assign({}, routeProps, { containerRef: modalContainerRef }),
                                    React.createElement(Redirect, { exact: true, from: "/", to: "/projects" }),
                                    React.createElement(PublicRoute, { component: PrototypeLocked, exact: true, path: "/prototype-locked" }),
                                    React.createElement(PublicRoute, { component: PrototypeArchived, exact: true, path: "/prototype-archived" }),
                                    routingMap.map(function (route) {
                                        var path = route.path, Application = route.Application, flag = route.flag;
                                        if (flag && !flags[flag])
                                            return null;
                                        return (React.createElement(PublicRoute, { key: path, path: path, render: function (_a) {
                                                var history = _a.history;
                                                return (React.createElement(Application
                                                // TODO refactor all the apps to get these values from
                                                // the useXXX hooks
                                                , { 
                                                    // TODO refactor all the apps to get these values from
                                                    // the useXXX hooks
                                                    accessToken: accessToken, apolloClient: apolloClient, config: config, featureFlags: flags, history: history, lastLocation: lastLocation, pusher: pusher, setSubscription: setSubscription, sidebar: sidebar, subscription: subscription, transactionalKey: transactionalKey, user: user }));
                                            } }));
                                    }),
                                    React.createElement(PublicRoute, { component: NotFound })))); }),
                            React.createElement(VideoOverlayRoot, null),
                            React.createElement(Tour, null)),
                        isProduction &&
                            disableDrift === false &&
                            user.role !== 'ANONYMOUS' &&
                            user.emailAddressType !== EmailAddressType.EDUCATION_EMAIL && (React.createElement(React.Fragment, null,
                            React.createElement(Drift, { appId: "eubrzsg8ymzi", attributes: {
                                    id: user.id,
                                    username: user.username,
                                    companyId: (_b = user.company) === null || _b === void 0 ? void 0 : _b.id,
                                    email: user.email,
                                    company: (_c = user.company) === null || _c === void 0 ? void 0 : _c.name,
                                    role: user.role,
                                    is_sketch: user.isSketch,
                                    occupationDisplayName: user.occupationDisplayName || '',
                                    planType: (_d = user.company) === null || _d === void 0 ? void 0 : _d.billing.plan.label,
                                    emailAddressType: user.emailAddressType,
                                    userJwt: user.signedToken.token,
                                    design_tool: user.tool || Tool.None,
                                }, eventHandlers: [
                                    {
                                        event: 'conversation:buttonClicked',
                                        function: trackDriftChatOpenedWithHeap,
                                    },
                                    {
                                        event: 'conversation:firstInteraction',
                                        function: trackDriftChatInteractionWithHeap,
                                    },
                                ], userId: user.pk }),
                            React.createElement(DriftHelper, null)))),
                    React.createElement("div", { ref: modalContainerRef }),
                    React.createElement(ToastContainer, null),
                    React.createElement(ModalPortalDestination, null),
                    user && React.createElement(Delighted, { user: user }),
                    user && subscription && React.createElement(FloatingUpsell, null),
                    React.createElement(HeapController, null))))));
}
function HeapController() {
    var location = useLocation();
    var currentPath = location.pathname;
    var config = useConfig();
    var user = useCurrentUser();
    useEffect(function () {
        var _a, _b;
        if (!window.heap)
            return;
        var isValidRoute = VALID_HEAP_ROUTES.some(function (path) {
            return matchPath(currentPath, {
                path: path,
                exact: false,
                strict: false,
            });
        });
        var isValidUser = user.pk && !(userSelectors.isFree(user) || userSelectors.isPro(user));
        if (isValidUser || isValidRoute) {
            // if load has already been called then the the load function will have been removed
            if (window.heap.load) {
                window.heap.load(config.heap.appId);
            }
            if (user.pk) {
                window.heap.identify(user.pk);
                window.heap.addUserProperties({
                    role: user.role,
                    plan_label: get(user, 'company.billing.plan.label'),
                    email: user.email,
                    username: user.username,
                    test_group_number: user.testGroup,
                    company_id: get(user, 'company.pk'),
                    company_name: get(user, 'company.name'),
                    occupation: user.occupationDisplayName,
                    account_id: (_b = (_a = user === null || user === void 0 ? void 0 : user.company) === null || _a === void 0 ? void 0 : _a.billing) === null || _b === void 0 ? void 0 : _b.accountId,
                    is_sketch: user.isSketch,
                    design_tool: user.tool,
                });
            }
        }
    }, [config.heap.appId, currentPath, user]);
    return null;
}
function DriftHelper() {
    var location = useLocation();
    var currentPath = location.pathname;
    var validRoutes = ['/user', '/company', '/plans', '/upgrade'];
    useEffect(function () {
        var _a, _b, _c;
        if (!window.drift)
            return;
        var isValidRoute = validRoutes.some(function (path) {
            return matchPath(currentPath, {
                path: path,
                exact: false,
                strict: false,
            });
        });
        if (!isValidRoute) {
            // Only call the drift page if it's a valid route
            (_c = (_b = (_a = window.drift) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.widget) === null || _c === void 0 ? void 0 : _c.hide();
        }
        else {
            window.drift.page(); // Ensure the drift page event is called
        }
    }, [currentPath]);
    var onReady = useCallback(function (api) { var _a; return (_a = api === null || api === void 0 ? void 0 : api.widget) === null || _a === void 0 ? void 0 : _a.hide(); }, []);
    var onChatClose = useCallback(function () {
        var _a, _b, _c;
        (_c = (_b = (_a = window.drift) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.widget) === null || _c === void 0 ? void 0 : _c.hide();
    }, []);
    useEffect(function () {
        var _a, _b;
        (_a = window.drift) === null || _a === void 0 ? void 0 : _a.on('ready', onReady);
        (_b = window.drift) === null || _b === void 0 ? void 0 : _b.on('chatClose', onChatClose);
        return function () {
            var _a, _b;
            (_a = window.drift) === null || _a === void 0 ? void 0 : _a.off('ready', onReady);
            (_b = window.drift) === null || _b === void 0 ? void 0 : _b.off('chatClose', onChatClose);
        };
    }, [onReady, onChatClose]);
    return null;
}
function trackDriftChatOpenedWithHeap() {
    heapTrackEvent('Drift chat opened');
}
function trackDriftChatInteractionWithHeap() {
    heapTrackEvent('Drift chat interaction');
}
export default compose(withApollo)(App);
