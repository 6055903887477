import { __read } from "tslib";
import React, { useState } from 'react';
import { CommentButton, CommentsSidebar } from '@marvelapp/comments';
import { SIDEBAR_WIDTH } from '@marvelapp/theme';
import { Box, LogoText, PinTR, Sidebar as SidebarElement } from '@marvelapp/ui';
import { Link } from '@marvelapp/ui-internal';
import NavLinks from './NavLinks';
import UserBlock from './UserBlock';
export default function Sidebar() {
    var _a = __read(useState(false), 2), isCommentsSidebarOpen = _a[0], setIsCommentsSidebarOpen = _a[1];
    // TODO: On mobile, if the nav sidebar is extended then navigating will close the sidebar.
    // This happens because the component is unmounted on navigation and then mounted again with the default false state.
    // The fix would be to pull the Sidbar to a top level, rather than rendering it individually with every Application component
    var _b = __read(useState(false), 2), isSidebarExtendedOnMobile = _b[0], setIsSidebarExtendedOnMobile = _b[1];
    var toggleSidebarOnMobile = function (isExtended) { return function () {
        return setIsSidebarExtendedOnMobile(isExtended);
    }; };
    var toggleCommentsSidebar = function (isOpen) { return function () {
        return setIsCommentsSidebarOpen(isOpen);
    }; };
    return (React.createElement(React.Fragment, null,
        React.createElement(SidebarElement, { closeTop: 37, height: "100vh", isOpen: isSidebarExtendedOnMobile, onMenuButtonClick: toggleSidebarOnMobile(!isSidebarExtendedOnMobile), sidebarWidth: SIDEBAR_WIDTH },
            React.createElement(Box, { overflow: "auto", width: 1 },
                React.createElement(Box, { position: "relative", pt: [5, 44], px: 3 },
                    React.createElement(PinTR, { padding: 12, top: [38, 38, 38, 0] },
                        React.createElement(CommentButton, { onClick: toggleCommentsSidebar(true), tooltipPosition: "bottomRight" })),
                    React.createElement(Box, { mb: [4, 5], p: 1, px: 3 },
                        React.createElement(Link, { to: "/" },
                            React.createElement(LogoText, null))),
                    React.createElement(NavLinks, null))),
            React.createElement(UserBlock, null)),
        React.createElement(CommentsSidebar, { isOpen: isCommentsSidebarOpen, toggleSidebar: toggleCommentsSidebar(!isCommentsSidebarOpen) })));
}
