import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, HintBar } from '@marvelapp/ui';
function HBDashboardWhiteboadBeta(_a) {
    var onClickClose = _a.onClickClose, clickedCTA = _a.clickedCTA;
    var history = useHistory();
    var onCreateWhiteboard = function (event) {
        clickedCTA(event);
        history.push('/whiteboard/create');
    };
    return (React.createElement(HintBar, { bg: "purple", callToAction: React.createElement(Button, { kind: "withShadow", onClick: onCreateWhiteboard }, "Get Started"), description: React.createElement(Box, { maxWidth: [240, 300, 300, 480] }, "Brainstorm ideas, map customer journeys, create flowcharts and more with Whiteboard. Try it out today!"), mt: 4, onClickClose: onClickClose, title: React.createElement(Box, { maxWidth: [240, 240, 240, 480] }, "Start collaborating with Whiteboard (beta)") }));
}
export default HBDashboardWhiteboadBeta;
