import { differenceInMonths } from 'date-fns/esm';
import { get } from 'lodash-es';
import { UserRole, getDaysToDate } from '@marvelapp/core';
import { userSelectors } from '@marvelapp/marvel-3-application';
import { didDowngradeInPastHour, getAssists, seenPlansInPastHour, } from './storage-assist';
var EXPIRY_WINDOW_DAYS = 14;
var SOURCE_STRIPE = 2;
export function getUserInfo(user, subscription, isWhiteboardEnabled) {
    var pk = user.pk, createdAt = user.createdAt, role = user.role, company = user.company, testGroup = user.testGroup, occupation = user.occupation;
    // Owner (Can touch billing)
    var isOwner = role === UserRole.Owner;
    var ownerUsername = get(company, 'owner.username');
    var ownerEmail = get(company, 'owner.email');
    var paysWithStripe = (subscription === null || subscription === void 0 ? void 0 : subscription.source) === SOURCE_STRIPE;
    var monthsSinceSignup = differenceInMonths(new Date(), new Date(createdAt));
    var remainingTrialDays = get(company, 'billing.trialDaysRemaining');
    var planIsYearly = get(company, 'billing.billingCycle') === 'yearly';
    var planPayment = get(company, 'billing.payment');
    var isAccountLocked = get(company, 'accountLockOverride');
    var nextPaymentDate = get(company, 'billing.nextPaymentDate');
    var daysToNextPayment = getDaysToDate(new Date(nextPaymentDate));
    var nextPaymentWithinExpiryWindow = daysToNextPayment < EXPIRY_WINDOW_DAYS;
    var assists = getAssists();
    var hasSeenPlansInPastHour = seenPlansInPastHour(assists);
    var hasDowngradedInPastHour = didDowngradeInPastHour(assists);
    return {
        pk: pk,
        role: role,
        testGroup: testGroup,
        isOwner: isOwner,
        ownerUsername: ownerUsername,
        ownerEmail: ownerEmail,
        nextPaymentWithinExpiryWindow: nextPaymentWithinExpiryWindow,
        isAccountLocked: isAccountLocked,
        daysToNextPayment: daysToNextPayment,
        planIsYearly: planIsYearly,
        occupation: occupation,
        planPayment: planPayment,
        hasSeenPlansInPastHour: hasSeenPlansInPastHour,
        hasDowngradedInPastHour: hasDowngradedInPastHour,
        remainingTrialDays: remainingTrialDays,
        paysWithStripe: paysWithStripe,
        monthsSinceSignup: monthsSinceSignup,
        isWhiteboardEnabled: isWhiteboardEnabled,
        isFree: userSelectors.isFree(user),
        isTeam: userSelectors.isTeam(user),
        isTeamPlus: userSelectors.isTeamPlus(user),
        isPro: userSelectors.isPro(user),
        isEnterprise: userSelectors.isEnterprise(user),
        prototypeProjectCount: userSelectors.prototypeProjectCount(user),
        hasUserTestProjects: userSelectors.hasUserTestProjects(user),
        hasHitPrototypeLimit: userSelectors.hasHitPrototypeLimit(user),
        hasHitUserTestLimit: userSelectors.hasHitUserTestLimit(user),
        hitAnyProjectsLimit: userSelectors.hasHitAnyProjectsLimit(user),
        screensOwnedCount: userSelectors.screensOwnedCount(user),
        teamsOwnedCount: userSelectors.teamsOwnedCount(user),
        foldersOwnedCount: userSelectors.foldersOwnedCount(user),
        prototypeProjectsOwnedCount: userSelectors.prototypeProjectsOwnedCount(user),
        userTestProjectsOwnedCount: userSelectors.userTestProjectsOwnedCount(user),
        hotspotsOwnedCount: userSelectors.hotspotsOwnedCount(user),
    };
}
