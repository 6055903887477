import React from 'react';
import { Link } from '@marvelapp/ui';
import Item from '../Item';
import ArticleIcon from './Icon';
var ItemArticle = function (_a) {
    var item = _a.item, onClick = _a.onClick;
    return (React.createElement(Link, { display: "block", href: item.link, onClick: function () { return onClick(); }, target: "_blank" },
        React.createElement(Item, { icon: React.createElement(ArticleIcon, null), item: item }, item.title)));
};
export default ItemArticle;
