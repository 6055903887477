import { decodeParams } from './url';
var M3_MODE_STORAGE_KEY = 'marvel-3-mode';
var M3_DEBUG_MODE_VALUE = 'debug';
// This function checks whether developer functionality should be enabled.
// Currently, it is used to enable the dev toolbar on production, but can also be used for
// other things in the future. To enable debug mode on production, you can either:
// Add ?mode=debug to the URL OR Set a localstorage flag: sessionStorage.setItem('marvel-3-mode', 'debug')
export function isDebugMode() {
    var isDebugModeEnabled = false;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (__DEV__ || isDebugModeEnabled) {
        return true;
    }
    var storedMode = getModeStorageValue();
    var params = decodeParams(window.location.search);
    // If debug mode is enabled we set the flag
    // and don't check the URL or storage again during the app's lifetime.
    isDebugModeEnabled =
        params.mode === M3_DEBUG_MODE_VALUE || storedMode === M3_DEBUG_MODE_VALUE;
    if (isDebugModeEnabled) {
        setModeStorageValue(M3_DEBUG_MODE_VALUE);
    }
    return isDebugModeEnabled;
}
function getModeStorageValue() {
    if (window && window.sessionStorage) {
        return window.sessionStorage.getItem(M3_MODE_STORAGE_KEY);
    }
    return null;
}
function setModeStorageValue(value) {
    if (window && window.sessionStorage) {
        window.sessionStorage.setItem(M3_MODE_STORAGE_KEY, value);
    }
}
