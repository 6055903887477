import { heapTrackEvent } from '@marvelapp/core';
export function trackClickHelp(_a) {
    var url = _a.url;
    heapTrackEvent('Help Clicked', { url: url });
}
export function trackAutoShowVideo(_a) {
    var link = _a.link;
    heapTrackEvent('Wistia Auto Show', { link: link });
}
export function trackPlayVideo(_a) {
    var link = _a.link;
    heapTrackEvent('Wistia Play', { link: link });
}
export function trackClickHelpItem(_a) {
    var url = _a.url, link = _a.link;
    heapTrackEvent('Help Item Clicked', { link: link, url: url });
}
