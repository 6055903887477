import { __read } from "tslib";
import { noop } from 'lodash-es';
import React, { createContext, useContext, useState } from 'react';
var VideoOverlayContext = createContext({
    show: noop,
    hide: noop,
});
export var VideoOverlayProvider = function (_a) {
    var children = _a.children;
    var _b = __read(useState(undefined), 2), video = _b[0], setVideo = _b[1];
    var show = function (v) {
        setVideo(v);
    };
    var hide = function () { return setVideo(undefined); };
    return (React.createElement(VideoOverlayContext.Provider, { value: { video: video, show: show, hide: hide } }, children));
};
export var useVideoOverlay = function () {
    return useContext(VideoOverlayContext);
};
