import { __read } from "tslib";
import { parseISO } from 'date-fns/esm';
import React, { useState } from 'react';
import { formatDate } from '@marvelapp/core';
import { useInterval } from '@marvelapp/hooks';
import { Text } from '@marvelapp/ui';
export default function LiveDate(_a) {
    var date = _a.date, _b = _a.interval, interval = _b === void 0 ? 120000 : _b;
    var _c = __read(useState(formatDate(parseISO(date))), 2), formattedDate = _c[0], setFormattedDate = _c[1];
    useInterval(function () {
        setFormattedDate(formatDate(parseISO(date)));
    }, interval);
    return (React.createElement(Text, { as: "time", datetime: date, display: "inline", fontSize: 12, opacity: 0.9, paddingTop: "2px" }, formattedDate));
}
