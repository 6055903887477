import { __assign, __rest } from "tslib";
import React from 'react';
import { Textfit } from 'react-textfit';
import { fontSizes } from '@marvelapp/theme';
import { TextElement } from './styles';
/** @param {any} */
function Text(_a) {
    var children = _a.children, tag = _a.tag, downScaleToFit = _a.downScaleToFit, fontSize = _a.fontSize, props = __rest(_a, ["children", "tag", "downScaleToFit", "fontSize"]);
    var maxFontSize;
    if (Array.isArray(fontSize)) {
        /* eslint-disable prefer-destructuring */
        // In the future we would need a parser to set the maxFontSize to the current breakpoint fontSize.
        // For now, if you use an array of font sizes, the max font should always be the smallest size, i.e. first size in the array.
        maxFontSize = fontSize[0];
    }
    else {
        maxFontSize = fontSize;
    }
    return (React.createElement(TextElement, __assign({ as: tag, fontSize: fontSize }, props), downScaleToFit ? (React.createElement(Textfit, { max: fontSizes[maxFontSize], min: 9, mode: "single" }, children)) : (children)));
}
Text.defaultProps = {
    tag: 'div',
    downScaleToFit: false,
    lineHeight: '1.5',
    fontSize: 3,
};
export default Text;
