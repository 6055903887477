export var key = 'help';
export function setVideoClosed(link) {
    var storage = getStorage();
    storage.closedLinks.push(link);
    saveStorage(storage);
}
export function saveStorage(storage) {
    window.localStorage.setItem(key, JSON.stringify(storage));
}
export function getStorage() {
    var string = window.localStorage.getItem(key);
    return string ? JSON.parse(string) : { closedLinks: [] };
}
