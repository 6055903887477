import { isEmpty as defaultIsEmpty } from 'lodash-es';
import React, { useRef } from 'react';
import { createPaginationUpdateQuery } from '@marvelapp/marvel-3-application';
import InfiniteScroll from './InfiniteScroll';
/** @param {any} */
export default function PaginatedList(_a) {
    var containerEl = _a.containerEl, data = _a.data, error = _a.error, fetchMore = _a.fetchMore, getDataConnection = _a.getDataConnection, _b = _a.isEmpty, isEmpty = _b === void 0 ? defaultIsEmpty : _b, loading = _a.loading, renderInitialLoadingState = _a.renderInitialLoadingState, renderList = _a.renderList, renderNoItems = _a.renderNoItems;
    var fetchingCursorRef = useRef(null);
    if (error) {
        console.error(error);
        return null;
    }
    // Data is possibly empty when loading is false so the
    // second part of the condition guards against that.
    // Note: loading || isEmpty(data) would be incorrect here
    // because data will not be empty after initial load.
    if ((loading && isEmpty(data)) || isEmpty(data)) {
        return renderInitialLoadingState();
    }
    var _c = getDataConnection(data), pageInfo = _c.pageInfo, edges = _c.edges;
    var endCursor = pageInfo.endCursor, hasNextPage = pageInfo.hasNextPage;
    if (!edges.length && !loading) {
        return renderNoItems();
    }
    var loadMore = function () {
        if (fetchingCursorRef.current === endCursor) {
            return;
        }
        fetchingCursorRef.current = endCursor;
        fetchMore({
            variables: {
                cursor: endCursor,
            },
            updateQuery: createPaginationUpdateQuery(getDataConnection),
        });
    };
    return (React.createElement(InfiniteScroll, { containerEl: containerEl, hasMore: hasNextPage, isLoading: loading, onLoadMore: loadMore }, renderList({ edges: edges, loading: loading })));
}
