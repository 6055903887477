import { __read } from "tslib";
import { includes, join, noop } from 'lodash-es';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// eslint-disable-next-line import/named
import { PlanLabel, fetchApi, heapTrackEvent } from '@marvelapp/core';
import { createPermissions } from '@marvelapp/marvel-3-application';
import { Box, Button, Flex, Link, Text } from '@marvelapp/ui';
import { BaseError } from '../Error';
import ToastContainer, { showErrorNotification } from '../ToastContainer';
import { BaseSessionBlocker, stealStatus } from './BaseSessionBlocker';
export default function MultipleSessionBlocker(_a) {
    var _b, _c, _d;
    var accessToken = _a.accessToken, config = _a.config, children = _a.children, logout = _a.logout, pusher = _a.pusher, user = _a.user;
    var _e = __read(useState(stealStatus.STATUS_OK), 2), status = _e[0], setStatus = _e[1];
    var _f = __read(useState(false), 2), isRequestLoading = _f[0], setRequestLoading = _f[1];
    var history = useHistory();
    var permissions = createPermissions(user);
    var canInvitePeopleToCompany = permissions.canInvitePeopleToCompany();
    var userId = user === null || user === void 0 ? void 0 : user.pk;
    var userEmail = user === null || user === void 0 ? void 0 : user.email;
    var isEnterprise = ((_d = (_c = (_b = user === null || user === void 0 ? void 0 : user.company) === null || _b === void 0 ? void 0 : _b.billing) === null || _c === void 0 ? void 0 : _c.plan) === null || _d === void 0 ? void 0 : _d.label) === PlanLabel.Enterprise;
    var prototypeURLs = [
        '/prototype',
        '/prototype-archived',
        '/prototype-locked',
    ];
    var isPrototypeURL = prototypeURLs.some(function (url) { var _a; return ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.href.indexOf(url)) > -1; });
    var whitelistedEmails = [
        'enterprisetestaccount@marvelqa.com',
        'admin@marvelqa.com',
        'editor@marvelqa.com',
        'reviewer@marvelqa.com',
        'externalreviewer@marvelqa.com',
        'guesteditor@marvelqa.com',
    ];
    if (!userEmail) {
        return children;
    }
    if (whitelistedEmails.includes(userEmail) ||
        userEmail.includes('@marvelapp.com') ||
        userEmail.includes('@salesforce.com') ||
        userEmail.includes('@innovaccer.com') ||
        isEnterprise ||
        isPrototypeURL) {
        return children;
    }
    function onSubscribe(_a) {
        var channel = _a.channel, membersData = _a.data;
        if (membersData.count > 1) {
            var sessionIds = join(Object.keys(membersData.members), ',');
            heapTrackEvent('Account Session IDs', { sessionIds: sessionIds });
            heapTrackEvent('Account already in use');
            setStatus(stealStatus.STATUS_DUPLICATE_USERS);
        }
        channel.bind('event_duplicate_sessions_detected', function (data) {
            var currentUserPusherId = channel.members.me.id;
            var logCurrentUserOut = includes(data.content.logout_users, currentUserPusherId);
            if (!logCurrentUserOut) {
                return;
            }
            pusher.disconnect();
            logout();
            setStatus(stealStatus.STATUS_STOLEN);
        });
    }
    function stealSessionAndInvite() {
        stealSession(function () {
            // React router is not initialised yet, so have to use window
            history.push('/company/people/add');
        });
    }
    function stealSession(callback) {
        if (callback === void 0) { callback = noop; }
        setRequestLoading(true);
        fetchApi('/api/pusher/logout/', {
            accessToken: accessToken,
            method: 'POST',
        })
            .then(function () {
            setStatus(stealStatus.STATUS_OK);
            setRequestLoading(false);
            callback();
        })
            .catch(function () {
            setRequestLoading(false);
            showErrorNotification({
                content: React.createElement(ErrorMessage, { message: "There was an unexpected issue" }),
            });
        });
    }
    if (status === stealStatus.STATUS_STOLEN) {
        return (React.createElement(React.Fragment, null,
            React.createElement(BaseError, { heading: "We\u2019ve had to log you out", subheading: React.createElement(React.Fragment, null,
                    "Your account was being used on another device so you\u2019ve been signed out of this session.",
                    React.createElement(Box, { marginTop: 4 },
                        React.createElement(Button, { as: "a", href: "/", kind: "marvel", size: 2 }, "Back to homepage"))), subheadingMaxWidth: 300 }),
            React.createElement(ToastContainer, null)));
    }
    return (React.createElement(BaseSessionBlocker, { channelName: "".concat(config.pusher.presenceChannelPrefix).concat(userId), onSubscribe: onSubscribe, pusher: pusher, renderError: function () { return (React.createElement(React.Fragment, null,
            React.createElement(BaseError, { heading: "Looks like your account is already in use", headingMaxWidth: 420, subheading: React.createElement(React.Fragment, null,
                    "We\u2019ve noticed you\u2019re already signed in on another device. You can either continue and end the other session or sign out.",
                    React.createElement(Flex, { marginTop: 4 },
                        React.createElement(Button, { "data-testid": "steal-session", isDisabled: isRequestLoading, kind: "green", onClick: function () { return stealSession(); }, size: 2 }, "Continue"),
                        React.createElement(Button, { "data-testid": "multi-session-logout", isDisabled: isRequestLoading, kind: "ghost", marginLeft: 2, onClick: function () {
                                logout()
                                    .then(function () {
                                    window.location.reload();
                                })
                                    .catch(function () {
                                    showErrorNotification({
                                        content: (React.createElement(ErrorMessage, { message: "There was an issue logging you out" })),
                                    });
                                });
                            }, size: 2 }, "Sign out")),
                    canInvitePeopleToCompany && (React.createElement(Text, { marginTop: 5 },
                        "Or",
                        ' ',
                        React.createElement(Link, { "data-testid": "invite-people-from-session-blocker", hasUnderline: true, kind: isRequestLoading ? 'silver' : 'marvel', onClick: isRequestLoading ? noop : stealSessionAndInvite, pointerEvents: isRequestLoading ? 'none' : 'all' }, "continue and invite"),
                        ' ',
                        "your teammates to Marvel"))), subheadingMaxWidth: 390 }),
            React.createElement(ToastContainer, null))); }, status: status, user: user }, children));
}
function ErrorMessage(_a) {
    var message = _a.message;
    return (React.createElement(React.Fragment, null,
        message,
        ". Please",
        ' ',
        React.createElement(Link, { hasUnderline: true, href: "mailto:support@marvelapp.com", kind: "white" }, "contact support"),
        ' ',
        "if this issue persists."));
}
