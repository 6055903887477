import { __assign, __read, __rest } from "tslib";
import React, { useState } from 'react';
import { useWindowDimensions } from '@marvelapp/hooks';
import { zIndices } from '@marvelapp/theme';
import { Box, Flex, Floaty } from '@marvelapp/ui';
import WindowHeader from './WindowHeader';
function Window(_a) {
    var children = _a.children, _b = _a.width, width = _b === void 0 ? 300 : _b, _c = _a.height, height = _c === void 0 ? 300 : _c, _d = _a.isResizable, isResizable = _d === void 0 ? true : _d, title = _a.title, onClose = _a.onClose, rest = __rest(_a, ["children", "width", "height", "isResizable", "title", "onClose"]);
    var _e = useWindowDimensions(), windowWidth = _e.width, windowHeight = _e.height;
    var padding = 20;
    var offset = 50;
    var defaultX = windowWidth - width - padding;
    var defaulyY = windowHeight - height - padding - offset;
    var maxX = windowWidth - width;
    var maxY = windowHeight - height;
    var _f = __read(useState({
        x: defaultX,
        y: defaulyY,
        width: width,
        height: height,
    }), 2), coordinates = _f[0], setCoordinates = _f[1];
    var safeCoordinates = __assign(__assign({}, coordinates), { x: Math.min(coordinates.x, maxX), y: Math.min(coordinates.y, maxY) });
    return (React.createElement(Box, { bottom: 0, left: 0, position: "absolute", right: 0, top: 0 },
        React.createElement(Floaty, __assign({ bounds: "window", dragHandleClassName: "header", enableResizing: {
                top: isResizable,
                right: isResizable,
                bottom: isResizable,
                left: isResizable,
                topRight: isResizable,
                bottomRight: isResizable,
                bottomLeft: isResizable,
                topLeft: isResizable,
            }, minHeight: 150, minWidth: 150, onDragStop: function (e, d) {
                setCoordinates(__assign(__assign({}, coordinates), { x: d.x, y: d.y }));
            }, onResize: function (e, direction, ref, delta, position) {
                setCoordinates(__assign({ width: ref.offsetWidth, height: ref.offsetHeight }, position));
            }, position: {
                x: safeCoordinates.x,
                y: safeCoordinates.y,
            }, size: { width: coordinates.width, height: coordinates.height }, style: { pointerEvents: 'auto' }, zIndex: zIndices.designTool.windows }, rest),
            React.createElement(Flex, { bg: "white", borderRadius: 2, boxShadow: "popover.raised", flexDirection: "column", height: "100%", overflow: "hidden", position: "relative", width: "100%" },
                React.createElement(WindowHeader, { className: "header", onClose: onClose, title: title }),
                React.createElement(Box, { flex: 1 }, children)))));
}
export default Window;
