import { __read } from "tslib";
import React, { useState } from 'react';
import { getRoleDisplayLabel } from '@marvelapp/core';
import { useCurrentUser, useFeatureFlag, useLogout, userSelectors, } from '@marvelapp/marvel-3-application';
import { IMAGE_ICON } from '@marvelapp/theme';
import { Avatar, Box, Flex, Heading, Icon, Image, Popover, Text, } from '@marvelapp/ui';
import { Link } from '@marvelapp/ui-internal';
import { DashboardTinyUpseller } from '@marvelapp/upsells';
import { getUserBlockLinks } from './links';
export default function UserBlock() {
    var logout = useLogout();
    var userBlockLink = '/user/account/personal';
    var hasStaticUi = useFeatureFlag('marvel-3-static-ui');
    var _a = __read(useState(false), 2), isLoggingOut = _a[0], setIsLoggingOut = _a[1];
    var user = useCurrentUser();
    var image = user.image, username = user.username, email = user.email, role = user.role, company = user.company;
    var isFree = userSelectors.isFree(user);
    var links = getUserBlockLinks({ isFree: isFree, hasStaticUi: hasStaticUi });
    return (React.createElement(Box, { bg: "white", bottom: 0, px: 3, width: "100%" },
        React.createElement(Flex, { alignItems: "flex-end", flexGrow: 1, justifyContent: "center", pb: 2, width: "100%" },
            React.createElement(DashboardTinyUpseller, null)),
        React.createElement(Popover, { align: "left", showOn: "hover", width: 210 },
            React.createElement(Link, { display: "block", to: userBlockLink, width: 1 },
                React.createElement(Flex, { alignItems: "center", pb: 3, pt: 1 },
                    React.createElement(Avatar, { email: email, mr: 10, size: 24, src: image, username: username }),
                    React.createElement(Box, null,
                        React.createElement(Text, { color: "licorice", fontSize: 2, fontWeight: "500", maxWidth: 136, truncated: true }, username)))),
            React.createElement(Box, null,
                company && (React.createElement(Flex, { alignItems: "center", borderBottom: "1px solid", borderColor: "smoke", padding: 3 },
                    React.createElement(Box, { marginRight: 14 }, company.iconUrl ? (React.createElement(Image, { alt: company.name, borderRadius: 2, height: 42, src: company.iconUrl, src2x: company.iconUrl, width: 42 })) : (React.createElement(Flex, { alignItems: "center", bg: "snowDark", borderRadius: 2, height: 42, justifyContent: "center", width: 42 },
                        React.createElement(Icon, { fill: "smokeExtraDark", height: 18, paths: IMAGE_ICON, width: 18 })))),
                    React.createElement(Box, null,
                        React.createElement(Heading, { fontSize: 14, fontWeight: 500, paddingTop: "1px" }, company.name),
                        React.createElement(Text, { fontSize: 2, marginTop: "2px" }, getRoleDisplayLabel(role))))),
                React.createElement(Box, { py: 15 }, links.map(function (link) { return (React.createElement(Link, { display: "block", fontSize: 2, hoverBg: "snowDark", key: link.name, px: 20, py: 1, target: link.target, to: link.to }, link.name)); })),
                React.createElement(Box, { borderTop: "1px solid", borderColor: "smoke", py: 10 },
                    React.createElement(Link, { color: "slate", display: "block", fontSize: 2, forceExternal: true, hoverBg: "snowDark", hoverColor: "licorice", 
                        // we need to prevent accidental double taps, to avoid a backend error
                        isDisabled: isLoggingOut, onClick: function () {
                            setIsLoggingOut(true);
                            logout().finally(function () {
                                window.location.href = '/';
                            });
                        }, px: 20, py: 1, target: "_self" }, "Sign out"))))));
}
