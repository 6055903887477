export var Tool;
(function (Tool) {
    Tool["None"] = "TOOL_NONE";
    Tool["Sketch"] = "TOOL_SKETCH";
    Tool["Invision"] = "TOOL_INVISION";
    Tool["AdobeXD"] = "TOOL_ADOBE_XD";
    Tool["Figma"] = "TOOL_FIGMA";
    Tool["Photoshop"] = "TOOL_PHOTOSHOP";
    Tool["Canva"] = "TOOL_CANVA";
    Tool["Keynote"] = "TOOL_KEYNOTE";
    Tool["Illustrator"] = "TOOL_ILLUSTRATOR";
    Tool["Balsamiq"] = "TOOL_BALSAMIQ";
    Tool["Powerpoint"] = "TOOL_POWERPOINT";
    Tool["PenAndPaper"] = "TOOL_PEN_AND_PAPER";
})(Tool || (Tool = {}));
export var toolLabels = {
    TOOL_NONE: "I don't use any design software",
    TOOL_SKETCH: 'Sketch (Bohemian Coding)',
    TOOL_INVISION: 'Invision',
    TOOL_ADOBE_XD: 'Adobe XD',
    TOOL_FIGMA: 'Figma',
    TOOL_PHOTOSHOP: 'Photoshop',
    TOOL_CANVA: 'Canva',
    TOOL_KEYNOTE: 'Keynote',
    TOOL_ILLUSTRATOR: 'Illustrator',
    TOOL_BALSAMIQ: 'Balsamiq',
    TOOL_POWERPOINT: 'Powerpoint',
    TOOL_PEN_AND_PAPER: 'Pen and paper',
};
