import { useEffect } from 'react';
export var stealStatus = {
    STATUS_DUPLICATE_USERS: 'duplicate-users',
    STATUS_OK: 'ok',
    STATUS_STOLEN: 'stolen',
};
export function BaseSessionBlocker(_a) {
    var channelName = _a.channelName, children = _a.children, onSubscribe = _a.onSubscribe, pusher = _a.pusher, renderError = _a.renderError, status = _a.status, user = _a.user;
    var userId = user === null || user === void 0 ? void 0 : user.pk;
    useEffect(function () {
        if (!userId) {
            return;
        }
        var _a = pusher.subscribe(channelName), channel = _a.channel, unsubscribe = _a.unsubscribe;
        channel.bind('pusher:subscription_succeeded', function (data) {
            onSubscribe({ data: data, channel: channel, unsubscribe: unsubscribe });
        });
        return function () { return unsubscribe(); };
        // We need to pass in an empty array here to avoid this
        // code being called multiple times
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    if (status === stealStatus.STATUS_OK) {
        return children;
    }
    if (status === stealStatus.STATUS_DUPLICATE_USERS) {
        return renderError();
    }
    return children;
}
