import { heapTrackEvent } from '@marvelapp/core';
export function trackUpsellAppearance(_a) {
    var identifier = _a.identifier, position = _a.position;
    heapTrackEvent('Upsell Appeared', { identifier: identifier, position: position });
}
export function trackUpsellClosing(_a) {
    var identifier = _a.identifier, position = _a.position;
    heapTrackEvent('Upsell Close Clicked', { identifier: identifier, position: position });
}
export function trackUpsellCTA(_a) {
    var identifier = _a.identifier, position = _a.position;
    heapTrackEvent('Upsell CTA Clicked', { identifier: identifier, position: position });
}
