export var PlanLabel;
(function (PlanLabel) {
    PlanLabel["Company"] = "COMPANY";
    PlanLabel["Enterprise"] = "ENTERPRISE";
    PlanLabel["Free"] = "FREE";
    PlanLabel["Pro"] = "PRO";
    PlanLabel["Team"] = "TEAM";
})(PlanLabel || (PlanLabel = {}));
var BallparkPlanLabel;
(function (BallparkPlanLabel) {
    BallparkPlanLabel["Business"] = "Business";
    BallparkPlanLabel["Starter"] = "Starter";
    BallparkPlanLabel["Free"] = "Free";
    BallparkPlanLabel["Enterprise"] = "Enterprise";
})(BallparkPlanLabel || (BallparkPlanLabel = {}));
export function mapMarvelPlanLabelsToBallpark(planLabel) {
    switch (planLabel) {
        case PlanLabel.Team:
            return BallparkPlanLabel.Business;
        case PlanLabel.Pro:
            return BallparkPlanLabel.Starter;
        case PlanLabel.Free:
            return BallparkPlanLabel.Free;
        case PlanLabel.Enterprise:
            return BallparkPlanLabel.Enterprise;
        default:
            throw Error('This plan does not exist');
    }
}
