import { zendesk } from '@marvelapp/help';
export var links = [
    {
        id: 0,
        name: 'Search our Help Center',
        url: zendesk.URL,
        target: '_blank',
    },
    {
        id: 1,
        name: 'Go to the homepage',
        url: '/',
        target: '_self',
    },
    {
        id: 2,
        name: 'Contact support',
        url: zendesk.NEW_TICKET,
        target: '_blank',
    },
];
