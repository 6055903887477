import React from 'react';
import { Box, Flex, Text } from '@marvelapp/ui';
import Link from '../Link';
import SketchIcon from '../SketchIcon';
export default function SketchText(_a) {
    var color = _a.color, fontSize = _a.fontSize, alignItems = _a.alignItems;
    return (React.createElement(Flex, { alignItems: alignItems },
        React.createElement(Box, { mr: 12 },
            React.createElement(SketchIcon, null)),
        React.createElement(Flex, { alignItems: "center", minHeight: "26px" },
            React.createElement(Text, { color: color, fontSize: fontSize },
                "Looks like you are trying to upload some Sketch files. Please use our",
                ' ',
                React.createElement(Link, { kind: "marvel", to: "integrations/sketch" }, "Sketch plugin"),
                "instead."))));
}
SketchText.defaultProps = {
    alignItems: 'flex-start',
    color: 'slate',
    fontSize: 14,
};
