import { __assign, __rest } from "tslib";
import React from 'react';
import { colors } from '@marvelapp/theme';
import { hexToRGB } from '@marvelapp/ui';
import { Link } from '@marvelapp/ui-internal';
function NavLink(_a) {
    var active = _a.active, _b = _a.kind, kind = _b === void 0 ? 'licorice' : _b, children = _a.children, isSublink = _a.isSublink, rest = __rest(_a, ["active", "kind", "children", "isSublink"]);
    return (React.createElement(Link, __assign({ bg: active ? hexToRGB(colors.snowDark, 0.6) : 'white', borderRadius: 2, color: active ? 'licorice' : 'slate', display: "block", fontSize: 14, fontWeight: active ? 500 : 400, height: "32px", hoverBg: active ? 'snowDark' : 'snow', hoverColor: "licorice", kind: kind, lineHeight: "32px", marginBottom: 2, pl: isSublink ? 26 : 3, pr: isSublink ? 26 : 3, pt: "1px" }, rest), children));
}
export default NavLink;
