import React from 'react';
import { Box, Flex, Logo, Text } from '@marvelapp/ui';
import Link from '../Link';
import Page from '../Page';
import { links } from './links';
export default function BaseError(_a) {
    var heading = _a.heading, _b = _a.headingMaxWidth, headingMaxWidth = _b === void 0 ? 500 : _b, renderLinkComponent = _a.renderLinkComponent, subheading = _a.subheading, _c = _a.subheadingMaxWidth, subheadingMaxWidth = _c === void 0 ? 500 : _c;
    return (React.createElement(Page, { title: heading },
        React.createElement(Flex, { alignItems: "flex-start", flexDirection: "column", justifyContent: ['center', 'center', 'space-between', 'space-between'], m: "0 auto", maxWidth: "1000px", minHeight: "100vh", px: 4, py: [4, 4, 5, 5] },
            React.createElement(Link, { href: "/", target: "_self" },
                React.createElement(Logo, { color: "marvel", mb: 4 })),
            React.createElement(Flex, { alignItems: "center", flexDirection: ['column', 'column', 'row', 'row'] },
                React.createElement(Text, { tag: "div" },
                    React.createElement(Text, { as: "h1", color: "licorice", fontSize: [5, 6, 7, 7], fontWeight: 600, lineHeight: 1.2, maxWidth: headingMaxWidth, mb: 3 }, heading),
                    React.createElement(Text, { as: "div", fontSize: [3, 3, 4, 4], maxWidth: subheadingMaxWidth, mb: 30 }, subheading),
                    renderLinkComponent &&
                        links.map(function (link) { return renderLinkComponent(link); }))),
            React.createElement(Box, null))));
}
