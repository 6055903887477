import React from 'react';
import { Box, Flex, Text } from '@marvelapp/ui';
import SuggestedIcon from './SuggestedIcon';
var Item = function (_a) {
    var item = _a.item, icon = _a.icon, children = _a.children;
    var fillColor = item.type === 'video' ? 'orange' : 'green';
    return (React.createElement(Box, { hoverBg: "snowDark", px: 18, py: "6px" },
        React.createElement(Box, null,
            React.createElement(Text, { color: "black", fontSize: 2, pt: "2px" }, children)),
        React.createElement(Flex, { alignItems: "center", height: 22, justifyContent: "flex-start" },
            React.createElement(Flex, { alignItems: "center", color: fillColor, justifyContent: "flex-start", pr: 1 },
                icon,
                item.recommended && (React.createElement(Box, { color: "yellow", display: "inline-block", pl: 1 },
                    React.createElement(SuggestedIcon, null)))),
            React.createElement(Text, { color: "silver", fontSize: 1, textTransform: "capitalize" },
                item.type,
                " ",
                item.recommended && '(Suggested)'))));
};
export default Item;
