import { isString } from 'lodash-es';
import Pusher from 'pusher-js';
import uuidv4 from 'uuid/v4';
var userId = uuidv4();
export function connectPusher(_a) {
    var accessToken = _a.accessToken, apiKey = _a.apiKey;
    if (!isString(accessToken)) {
        throw new Error("Invalid accessToken, ".concat(accessToken));
    }
    if (!isString(apiKey)) {
        throw new Error("Invalid apiKey, ".concat(apiKey));
    }
    var pusher = new Pusher(apiKey, {
        encrypted: true,
        authEndpoint: '/api/pusher/auth/',
        auth: {
            headers: {
                authorization: "Bearer ".concat(accessToken),
            },
            params: {
                user_id: userId,
            },
        },
    });
    pusher.connection.bind('error', function (err) {
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.error('pusher: ', err);
        }
    });
    var subscribe = function (channelName) {
        var channel = pusher.subscribe(channelName);
        var unsubscribe = function () { return pusher.unsubscribe(channelName); };
        return { channel: channel, unsubscribe: unsubscribe };
    };
    var disconnect = function () { return pusher.disconnect(); };
    return { subscribe: subscribe, disconnect: disconnect };
}
