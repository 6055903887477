import React from 'react';
import Box from '../Box';
import CloseButton from '../CloseButton';
import Flex from '../Flex';
import Heading from '../Heading';
import Position from '../Position';
export default function SidebarHeading(_a) {
    var onCloseClick = _a.onCloseClick, heading = _a.heading, children = _a.children;
    return (React.createElement(Position, { bg: "white", boxShadow: "projectBar", left: 0, position: "sticky", top: 0, width: 1, zIndex: 1 },
        React.createElement(Flex, { alignItems: "center", pt: 3, px: 3, width: 1 },
            React.createElement(Box, { flex: 1, paddingRight: 3 },
                React.createElement(Heading, { fontSize: 3 }, heading)),
            React.createElement(CloseButton, { onClick: onCloseClick, size: 1 })),
        children));
}
