// TODO: move to @marvelapp/marvel-3-application and convert to a hook
import config from '../config';
var KEY = 'accessToken';
var requiredScopes = config.pie.scopes;
export function getAuth() {
    var auth = window.localStorage.getItem(KEY);
    if (!auth)
        return false;
    try {
        var _a = JSON.parse(auth), accessToken = _a.accessToken, expiry = _a.expiry, scopes = _a.scopes;
        // Check it has not expired
        if (Date.now() > expiry) {
            clearAuth();
            return false;
        }
        // Check the token is authenticated for all the scopes we need
        var authenticatedScopes_1 = new Set(scopes);
        var hasAllScopes = requiredScopes.every(function (scope) {
            return authenticatedScopes_1.has(scope);
        });
        if (!hasAllScopes) {
            clearAuth();
            return false;
        }
        return accessToken;
    }
    catch (e) {
        // This means it's corrupted and we don't know how to read it,
        // so delete the entry and start over
        clearAuth();
        return false;
    }
}
export function setAuth(accessToken, expiry, scopes) {
    window.localStorage.setItem(KEY, JSON.stringify({
        accessToken: accessToken,
        expiry: expiry,
        scopes: scopes,
    }));
}
export function clearAuth() {
    window.localStorage.removeItem(KEY);
}
