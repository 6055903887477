import { useEffect, useRef } from 'react';
export function useInterval(callback, delay) {
    var savedCallback = useRef();
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(function () {
        if (delay !== null) {
            var id_1 = setInterval(function () {
                savedCallback.current();
            }, delay);
            return function () { return clearInterval(id_1); };
        }
    }, [delay]);
}
