import { __read } from "tslib";
import { noop } from 'lodash-es';
import React, { useState } from 'react';
import { SEARCH_ICON } from '@marvelapp/theme';
import { Flex, Icon, Input, PinTL, Position, SearchCloseButton, } from '@marvelapp/ui';
/** @type {any} */
var SimpleSearchInput = React.forwardRef(function (_a, ref) {
    var _b = _a.boxShadow, boxShadow = _b === void 0 ? 'input.defaultTransparent' : _b, id = _a.id, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, isLoading = _a.isLoading, _d = _a.onBlur, onBlur = _d === void 0 ? noop : _d, onChange = _a.onChange, onClear = _a.onClear, _e = _a.onFocus, onFocus = _e === void 0 ? noop : _e, placeholder = _a.placeholder, showClearButton = _a.showClearButton, _f = _a.size, size = _f === void 0 ? 36 : _f, value = _a.value, _g = _a.testId, testId = _g === void 0 ? 'search-input' : _g;
    var _h = __read(useState('slate'), 2), iconColor = _h[0], setIconColor = _h[1];
    var onInputFocus = function () {
        onFocus();
        setIconColor('marvel');
    };
    var onInputBlur = function () {
        onBlur();
        setIconColor('slate');
    };
    return (React.createElement(Position, { position: "relative" },
        React.createElement(PinTL, null,
            React.createElement(Flex, { alignItems: "center", height: size, justifyContent: "center", width: size },
                React.createElement(Icon, { color: iconColor, display: "block", height: 24, paths: SEARCH_ICON, width: 24 }))),
        React.createElement(Input, { boxShadow: boxShadow, "data-testid": testId, disabled: isLoading || isDisabled, height: size, id: id, onBlur: onInputBlur, onChange: onChange, onFocus: onInputFocus, paddingLeft: size, placeholder: placeholder, ref: ref, size: 2, value: value, width: 1 }),
        showClearButton && React.createElement(SearchCloseButton, { onClick: onClear })));
});
export default SimpleSearchInput;
