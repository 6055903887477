import { __makeTemplateObject } from "tslib";
import themeGet from '@styled-system/theme-get';
import styled, { createGlobalStyle } from '@marvelapp/styled';
import { CLOSE_ICON, colors } from '@marvelapp/theme';
import { Icon, hexToRGB } from '@marvelapp/ui';
var black09 = hexToRGB(colors.black, 0.9);
export var ToastCloseWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  right: -10px;\n  top: -10px;\n  background: white;\n  border-radius: 50%;\n  opacity: 0;\n  transition: 250ms ease-in-out;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);\n"], ["\n  position: absolute;\n  right: -10px;\n  top: -10px;\n  background: white;\n  border-radius: 50%;\n  opacity: 0;\n  transition: 250ms ease-in-out;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);\n"])));
export var ToastCloseButton = styled(Icon).attrs({
    paths: CLOSE_ICON,
    display: 'block',
    width: 20,
    height: 20,
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  transition: 250ms ease-in-out;\n  fill: ", ";\n\n  &:hover {\n    fill: ", ";\n  }\n"], ["\n  transition: 250ms ease-in-out;\n  fill: ", ";\n\n  &:hover {\n    fill: ", ";\n  }\n"])), themeGet('colors.silver'), themeGet('colors.slate'));
// More classes available for the toast message can be found here:
// https://github.com/fkhadra/react-toastify/blob/master/dist/ReactToastify.css
export var ToastContainerElement = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  .Toastify__toast-container {\n    padding: 8px;\n    width: 400px;\n    bottom: 0;\n    right: 1em;\n    height: min-content;\n  }\n\n  .Toastify__toast {\n    overflow: visible;\n    display: inline-block;\n    min-height: 0px;\n    border-radius: 6px;\n    font-family: inherit;\n    font-size: 14px;\n    line-height: 1.3;\n    margin-bottom: 20px;\n    padding: 16px;\n    padding-right: 20px;\n    box-shadow: ", ";\n    color: ", ";\n    background-color: ", ";\n\n    .Toastify__progress-bar {\n      background: ", ";\n    }\n\n    &:hover {\n      ", " {\n        opacity: 1;\n      }\n    }\n  }\n"], ["\n  .Toastify__toast-container {\n    padding: 8px;\n    width: 400px;\n    bottom: 0;\n    right: 1em;\n    height: min-content;\n  }\n\n  .Toastify__toast {\n    overflow: visible;\n    display: inline-block;\n    min-height: 0px;\n    border-radius: 6px;\n    font-family: inherit;\n    font-size: 14px;\n    line-height: 1.3;\n    margin-bottom: 20px;\n    padding: 16px;\n    padding-right: 20px;\n    box-shadow: ", ";\n    color: ", ";\n    background-color: ", ";\n\n    .Toastify__progress-bar {\n      background: ", ";\n    }\n\n    &:hover {\n      ", " {\n        opacity: 1;\n      }\n    }\n  }\n"])), themeGet('shadows.toast'), themeGet('colors.white'), black09, themeGet('colors.snowDark'), ToastCloseWrap);
// The easiest way to define a custom transition for the toasties is with global styles
// https://github.com/fkhadra/react-toastify#replace-the-default-transition
export var GlobalTransitionStyles = createGlobalStyle(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  @keyframes slideIn {\n    from {\n      opacity: 0;\n      transform: translate3d(-200px, 0, 0px);\n    }\n\n    50% {\n      opacity: 1;\n    }\n  }\n\n  .slideIn {\n    animation-name: slideIn;\n    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);\n  }\n\n  @keyframes slideOut {\n    from {\n      opacity: 1;\n    }\n\n    50% {\n      opacity: 0;\n      transform: translate3d(-100px, 0, 0px);\n    }\n\n    to {\n      opacity: 0;\n    }\n  }\n\n  .slideOut {\n    animation-name: slideOut;\n    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);\n  }\n"], ["\n  @keyframes slideIn {\n    from {\n      opacity: 0;\n      transform: translate3d(-200px, 0, 0px);\n    }\n\n    50% {\n      opacity: 1;\n    }\n  }\n\n  .slideIn {\n    animation-name: slideIn;\n    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);\n  }\n\n  @keyframes slideOut {\n    from {\n      opacity: 1;\n    }\n\n    50% {\n      opacity: 0;\n      transform: translate3d(-100px, 0, 0px);\n    }\n\n    to {\n      opacity: 0;\n    }\n  }\n\n  .slideOut {\n    animation-name: slideOut;\n    animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
