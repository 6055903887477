// Documentation
// Workspace roles: https://marvelapp.quip.com/Ja9zAloQPGLF
// Permissions: https://marvelapp.quip.com/UyEPA806lomN
var _a, _b, _c, _d, _e;
export var UserRole;
(function (UserRole) {
    UserRole["Owner"] = "OWNER";
    UserRole["Admin"] = "ADMIN";
    UserRole["Editor"] = "EDITOR";
    UserRole["InternalReviewer"] = "INTERNAL_REVIEWER";
    UserRole["ExternalEditor"] = "EXTERNAL_EDITOR";
    UserRole["ExternalReviewer"] = "EXTERNAL_REVIEWER";
})(UserRole || (UserRole = {}));
export var UserRoleType;
(function (UserRoleType) {
    UserRoleType["Member"] = "MEMBER";
    UserRoleType["Guest"] = "GUEST";
})(UserRoleType || (UserRoleType = {}));
export var userRoleTypes = (_a = {},
    _a[UserRole.Owner] = UserRoleType.Member,
    _a[UserRole.Admin] = UserRoleType.Member,
    _a[UserRole.Editor] = UserRoleType.Member,
    _a[UserRole.InternalReviewer] = UserRoleType.Member,
    _a[UserRole.ExternalReviewer] = UserRoleType.Guest,
    _a[UserRole.ExternalEditor] = UserRoleType.Guest,
    _a);
export var roleDisplayLabels = (_b = {},
    _b[UserRole.Owner] = 'Owner',
    _b[UserRole.Admin] = 'Admin',
    _b[UserRole.Editor] = 'Member (Can edit)',
    _b[UserRole.InternalReviewer] = 'Member (View only)',
    _b[UserRole.ExternalReviewer] = 'Contributor (View only)',
    _b[UserRole.ExternalEditor] = 'Contributor (Can edit)',
    _b);
export var ballparkRoleDisplayLabels = (_c = {},
    _c[UserRole.Owner] = 'Owner',
    _c[UserRole.Admin] = 'Admin',
    _c[UserRole.Editor] = 'Member (Can edit)',
    _c[UserRole.InternalReviewer] = 'Member (View only)',
    _c[UserRole.ExternalReviewer] = 'Guest (View only)',
    _c[UserRole.ExternalEditor] = 'Guest (Can edit)',
    _c);
export var getRoleDisplayLabel = function (role) {
    return roleDisplayLabels[role];
};
export function isGuest(user) {
    return userRoleTypes[user.role] === UserRoleType.Guest;
}
export function isGuestEditor(user) {
    return user.role === UserRole.ExternalEditor;
}
export function isMember(user) {
    return userRoleTypes[user.role] === UserRoleType.Member;
}
export function isReviewer(role) {
    return (role === UserRole.InternalReviewer || role === UserRole.ExternalReviewer);
}
// Used by legacy role APIs, where we are required to
// send the numerical code for a specific role.
// This is a 2-way mapping to map from codes to UserRole
export var legacyUserRoleCodes = (_d = {},
    _d[UserRole.Owner] = 60,
    _d['60'] = UserRole.Owner,
    _d[UserRole.Admin] = 50,
    _d['50'] = UserRole.Admin,
    _d[UserRole.Editor] = 30,
    _d['30'] = UserRole.Editor,
    _d[UserRole.InternalReviewer] = 20,
    _d['20'] = UserRole.InternalReviewer,
    _d[UserRole.ExternalEditor] = 15,
    _d['15'] = UserRole.ExternalEditor,
    _d[UserRole.ExternalReviewer] = 10,
    _d['10'] = UserRole.ExternalReviewer,
    _d);
// A 2-way mapping from legacy user roles returned by old rest API
// to new enum UserRole. Please don't use it for labels.
export var legacyUserRoles = (_e = {
        Owner: UserRole.Owner
    },
    _e[UserRole.Owner] = 'Owner',
    _e.Admin = UserRole.Admin,
    _e[UserRole.Admin] = 'Admin',
    _e.Editor = UserRole.Editor,
    _e[UserRole.Editor] = 'Editor',
    _e['Internal Reviewer'] = UserRole.InternalReviewer,
    _e[UserRole.InternalReviewer] = 'Internal Reviewer',
    _e['External Reviewer'] = UserRole.ExternalReviewer,
    _e[UserRole.ExternalReviewer] = 'External Reviewer',
    _e['External Editor'] = UserRole.ExternalEditor,
    _e[UserRole.ExternalEditor] = 'External Editor',
    _e);
// TODO: Kill this Role. ANONYMOUS is not a real user role, and is not supported by the backend.
// We use this role in a few places to refer to users who are not authenticated, but still have
// access to public parts of the application (like folders). A better solution would be to have
// an isAuthenticated on the user object.
export var ANONYMOUS = 'ANONYMOUS';
