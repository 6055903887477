/* eslint-env browser */
export var key = 'upsells';
export function getClosedUpsells(_a) {
    var userPk = _a.userPk;
    try {
        var closedUpsells = JSON.parse(window.localStorage.getItem(key) || '[]');
        var now_1 = new Date().getTime();
        return closedUpsells
            .filter(function (upsell) { return upsell.expireTime > now_1; })
            .filter(function (upsell) { return upsell.userPk === userPk; });
    }
    catch (error) {
        console.log(error);
        return [];
    }
}
export function setClosedUpsell(_a) {
    var identifier = _a.identifier, userPk = _a.userPk, position = _a.position, daysUntilReshownAfterClose = _a.daysUntilReshownAfterClose;
    var closed = getClosedUpsells({ userPk: userPk });
    // This can never be below 2 days as there has to be at least 1 day
    // inbetween showing upsells in a position therefore this could
    // trigger a situation that the banners coming after this one never get shown.
    var daysToExpiry = daysUntilReshownAfterClose;
    if (daysToExpiry < 2) {
        console.log("Days until expired can't be less than 1 days. See the code. (".concat(identifier, ")"));
        daysToExpiry = 2;
    }
    var now = new Date();
    var futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + daysToExpiry);
    var expireTime = futureDate.getTime();
    var createdTime = now.getTime();
    closed.push({
        identifier: identifier,
        expireTime: expireTime,
        createdTime: createdTime,
        userPk: userPk,
        position: position,
    });
    try {
        window.localStorage.setItem(key, JSON.stringify(closed));
        return closed;
    }
    catch (error) {
        console.log(error);
        return [];
    }
}
export function canBeShownAgain(_a) {
    var identifier = _a.identifier, closed = _a.closed, position = _a.position;
    // Check if closed before
    var closedWithIdentifier = closed.filter(function (el) { return el.identifier === identifier; });
    if (closedWithIdentifier.length > 0) {
        return false;
    }
    // Check if anything shown in the position in the past 24h
    var oneDayAgo = new Date(Date.now());
    oneDayAgo.setDate(oneDayAgo.getDate() - 1);
    var oneDayAgoTime = oneDayAgo.getTime();
    var closedInThePastDay = closed.filter(function (el) {
        return el.position === position && el.createdTime > oneDayAgoTime;
    });
    if (closedInThePastDay.length > 0) {
        return false;
    }
    return true;
}
