export var URL = 'https://marvelapp.wistia.com';
var link = function (id) { return "".concat(URL, "/medias/").concat(id); };
export var CREATE_GROUPS = link('tqsfctacr1');
export var COMMENTS_ANNOTATIONS = link('eoxfxmb7zq');
export var CREATE_FIRST_PROTOTYPE = link('vz54v4k7v5');
export var DESIGN_WIREFRAME = link('jvvy7ob9ec');
export var SECTIONS = link('49r30c9d7v');
export var COPY_SCREENS = link('tce6m8g9w0');
export var DOWNLOAD_PDF = link('cnm54xuvwg');
export var ADD_GROUPS_TO_PROJECTS_AND_FOLDERS = link('78pu24zrz0');
export var INVITE_COLLABS = link('dj2ferhhdu');
export var HOW_TO_EMBED_PROTOTYPES = link('hkcctawsmh');
export var HOW_TO_DOWNLOAD_YOUR_PROTOTYPE_OFFLINE = link('1rorbwbu3l');
export var HOW_TO_LINK_YOUR_DESIGNS_USING_HOTSPOTS = link('di9mtdhd1s');
export var HOW_TO_USE_ANCHORS = link('2h3mpxiayg');
export var HOW_TO_USE_VIDEO_EMBEDS = link('8d0jucfdgn');
export var HOW_TO_LINK_TO_EXTERNAL_URLS = link('h39qgbktr3');
export var HOW_TO_USE_KEEP_SCROLL_POSITION = link('rwd62pznmd');
export var HOW_TO_CREATE_GOAL_BASE_USER_TESTS = link('thzak94r9c');
export var SENDING_USER_TEST_TO_IPHONE_AND_IPAD_DEVICES = link('r04cy4l8ku');
export var HOW_TO_USE_FOLDERS = link('lrji00er4h');
