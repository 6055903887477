import React from 'react';
import { useCurrentUser } from '@marvelapp/marvel-3-application';
import MarvelTourJS from './index';
export default function MarvelTour() {
    var role = useCurrentUser().role;
    var showTours = false;
    if (!showTours || role === 'ANONYMOUS') {
        return null;
    }
    return React.createElement(MarvelTourJS, null);
}
