import { ANONYMOUS, PlanLabel, UserRole } from '@marvelapp/core';
export function createPermissions(user, subscription) {
    var _a, _b;
    var isUserAuthenticated = user.role !== ANONYMOUS;
    var planLabel = isUserAuthenticated
        ? user.company.billing.plan.label
        : null;
    var planSlug = isUserAuthenticated ? subscription === null || subscription === void 0 ? void 0 : subscription.plan.slug : null;
    // This is workaround for:
    // https://marvelapp.atlassian.net/browse/UT-2002
    //
    // The invoice section must be visible for customers that downgraded to free.
    // Unfortunately the invoice list is not exposed via GQL, so there is no way
    // to check if it's empty or not.
    // As alternative we check for `customerId` instead, which will have a value
    // in it if the company ever had a Stripe subscription
    var hasInvoicesToView = isUserAuthenticated
        ? !!user.company.billing.customerId && planLabel !== PlanLabel.Enterprise
        : false;
    // We always try to declare by whitelisting roles rather than blacklisting
    // as it means you're more likely to stop something from doing something they
    // should be able to do than allow someone do something they're not supposed to
    var isOneOf = function () {
        var whitelist = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            whitelist[_i] = arguments[_i];
        }
        return whitelist.some(function (w) { return user.role === w; });
    };
    var canViewInvoices = hasInvoicesToView && isOneOf(UserRole.Owner);
    var editorsCanInvite = (_b = (_a = user.company) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.editorsCanInvite;
    var canArchiveProjects = function (projects) {
        var ownsAllProjects = projects.every(function (project) { return project.owner.pk === user.pk; });
        return (isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor) ||
            (isOneOf(UserRole.ExternalEditor) && ownsAllProjects));
    };
    return {
        // General
        canViewAuthedRoutes: function () { return isUserAuthenticated; },
        // Account settings
        canViewBilling: function () { return user.role === UserRole.Owner; },
        canViewInvoices: function () { return canViewInvoices; },
        canEditInvoiceDetails: function () {
            return canViewInvoices && planLabel !== PlanLabel.Free;
        },
        // Subscription
        canEditSubscription: function (isSubscribingToEntLite) {
            if (planLabel === PlanLabel.Free) {
                return isOneOf(UserRole.Owner, UserRole.Editor, UserRole.Admin);
                // eslint-disable-next-line no-else-return
            }
            else if (isSubscribingToEntLite || (planSlug === null || planSlug === void 0 ? void 0 : planSlug.includes('ent-lite'))) {
                return isOneOf(UserRole.Owner);
            }
            return isOneOf(UserRole.Owner) && planLabel !== PlanLabel.Enterprise;
        },
        // Folders
        canViewFolders: function () { return true; },
        canEditFolders: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor);
        },
        // Projects
        canViewProjects: function () { return true; },
        canCreateProjects: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor);
        },
        canEditProjects: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor);
        },
        canArchiveProjects: canArchiveProjects,
        canUnarchiveProjects: canArchiveProjects,
        canDownloadProjects: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor);
        },
        canBeTransferredProjects: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor);
        },
        // Team & Groups
        canViewPeople: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.InternalReviewer);
        },
        canEditTeamSettings: function () { return isOneOf(UserRole.Owner, UserRole.Admin); },
        canInvitePeopleToCompany: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin) ||
                (isOneOf(UserRole.Editor) && editorsCanInvite);
        },
        canDownloadCSV: function () { return isOneOf(UserRole.Owner, UserRole.Admin); },
        canCreateGroups: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor);
        },
        canEditGroups: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor);
        },
        // User Testing
        canCreateUserTest: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor);
        },
        canCustomizeUserTest: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor);
        },
        canEditUserTestResults: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor);
        },
        // Sharing
        canSearchCollaboratorsList: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor);
        },
        canEditCollaborators: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor);
        },
        canEditSharePassword: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.ExternalEditor);
        },
        canEditVisibility: function () {
            return planLabel !== PlanLabel.Free &&
                isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor);
        },
        // Team libraries
        canEditTeamLibraries: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor);
        },
        // Design Tool
        canDownloadDesignPNG: function () { return planLabel !== PlanLabel.Free; },
        // Ballpark specific
        canRecruitParticipants: function () {
            return isOneOf(UserRole.Owner, UserRole.Admin, UserRole.Editor, UserRole.InternalReviewer);
        },
    };
}
