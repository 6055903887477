import { formatDuration as _formatDuration, differenceInCalendarDays, differenceInCalendarMonths, differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, format, formatDistance, fromUnixTime, getUnixTime, isSameYear, } from 'date-fns';
export function isLessThanNDaysAgo(date, numberOfDays, now) {
    if (now === void 0) { now = new Date(); }
    return differenceInDays(now, date) <= numberOfDays;
}
export function isLessThanNHoursAgo(date, numberOfHours, now) {
    if (now === void 0) { now = new Date(); }
    return differenceInHours(now, date) <= numberOfHours;
}
export function isLessThanNMinutesAgo(date, numberOfMinutes, now) {
    if (now === void 0) { now = new Date(); }
    return differenceInMinutes(now, date) <= numberOfMinutes;
}
export function getDaysToDate(date) {
    return differenceInCalendarDays(date, Date.now());
}
export function formatDate(date, currentDate) {
    if (currentDate === void 0) { currentDate = new Date(); }
    var isOlderThanOneMonth = differenceInCalendarMonths(currentDate, date) > 1;
    var isWithinSameYear = isSameYear(date, currentDate);
    if (isOlderThanOneMonth) {
        return "".concat(format(date, "dd MMM".concat(!isWithinSameYear ? ' yy' : '')));
    }
    var isLessThan30SecondsAgo = differenceInSeconds(currentDate, date) <= 30;
    if (isLessThan30SecondsAgo) {
        return "Just now";
    }
    var distance = formatDistance(date, currentDate)
        .replace(/about /, '')
        .replace(/^a minute$/, '1 min')
        .replace(/ minutes?$/, ' min')
        .replace(/ hours?$/, 'h')
        .replace(/ days?$/, 'd')
        .replace(/ months?$/, ' mo');
    return "".concat(distance, " ago");
}
export function formatTimestamp(date) {
    var currentDate = new Date();
    var isWithinSameYear = isSameYear(date, currentDate);
    if (isWithinSameYear) {
        return "Updated ".concat(format(date, 'do MMM @ HH:mm a'));
    }
    return "Updated ".concat(format(date, 'do MMM yy @ HH:mm a'));
}
export function formatDateAndTime(date, dateFormat) {
    if (dateFormat === void 0) { dateFormat = 'd MMMM y'; }
    var dateString = format(date, dateFormat);
    var timeString = format(date, 'HH:mm');
    return { date: dateString, time: timeString };
}
export function formatDuration(seconds) {
    var days = Math.floor(seconds / (3600 * 24));
    var hours = Math.floor((seconds % (3600 * 24)) / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    var remainingSeconds = Math.floor(seconds % 60);
    return _formatDuration({ days: days, hours: hours, minutes: minutes, seconds: remainingSeconds }, { format: ['days', 'hours', 'minutes', 'seconds'] });
}
export { getUnixTime, fromUnixTime };
