import { __assign, __read, __rest, __spreadArray } from "tslib";
import { useQuery } from '@apollo/react-hooks';
import { cloneDeep, get, once } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { NetworkStatusCode } from '@marvelapp/core';
/** @type any */
export var createPaginationUpdateQuery = function (getDataConnection) {
    return function (previousResult, _a) {
        var _b;
        var fetchMoreResult = _a.fetchMoreResult;
        var newConnection = getDataConnection(fetchMoreResult);
        var nextResult = cloneDeep(previousResult);
        var nextConnection = getDataConnection(nextResult);
        nextConnection.pageInfo = newConnection.pageInfo;
        (_b = nextConnection.edges).push.apply(_b, __spreadArray([], __read(newConnection.edges), false));
        return nextResult;
    };
};
export function usePaginatedQuery(query, _a) {
    var dataConnection = _a.dataConnection, _b = _a.getVariables, getVariables = _b === void 0 ? function (_a) {
        var endCursor = _a.pageInfo.endCursor;
        return ({
            cursor: endCursor,
        });
    } : _b, queryOptions = __rest(_a, ["dataConnection", "getVariables"]);
    var getDataConnection = useCallback(function (data) {
        return get(data, dataConnection, {
            edges: undefined,
            pageInfo: {
                hasNextPage: undefined,
            },
        });
    }, [dataConnection]);
    var queryResult = useQuery(query, queryOptions);
    var data = queryResult.data, error = queryResult.error, fetchMore = queryResult.fetchMore, networkStatus = queryResult.networkStatus;
    var connection = getDataConnection(data);
    var edges = connection.edges, hasNextPage = connection.pageInfo.hasNextPage;
    var isFirstLoad = networkStatus === NetworkStatusCode.Loading;
    var isLoading = isFirstLoad || networkStatus === NetworkStatusCode.SetVariables;
    var isLoadingMore = networkStatus === NetworkStatusCode.FetchMore;
    var loadNextPage = useCallback(once(function () {
        if (edges === undefined || error || !hasNextPage)
            return Promise.resolve();
        return fetchMore({
            variables: getVariables(connection),
            updateQuery: createPaginationUpdateQuery(getDataConnection),
        });
        // only create a new loadNextPage function if variables will change to prevent
        // multiple of the same page being loaded before the networkStatus is updated
    }), __spreadArray([error, hasNextPage], __read(Object.values(getVariables(connection))), false));
    return __assign({ data: data, edges: edges, getDataConnection: getDataConnection, hasNextPage: hasNextPage, isLoading: isLoading, isLoadingMore: isLoadingMore, isFirstLoad: isFirstLoad, loadNextPage: loadNextPage }, queryResult);
}
// useQuery has a bug where an error in refetch does not change its error property
// https://github.com/apollographql/react-apollo/issues/3571
export function useRetryableQuery(query, options) {
    if (options === void 0) { options = {}; }
    var _a = __read(useState(undefined), 2), refetchError = _a[0], setRefetchError = _a[1];
    var result = useQuery(query, __assign({}, options));
    var variables = options.variables || {};
    useEffect(function () {
        setRefetchError(undefined);
    }, Object.values(variables));
    return __assign(__assign({}, result), { networkStatus: result.error || refetchError
            ? NetworkStatusCode.Error
            : result.networkStatus, error: refetchError || result.error, refetch: function () {
            setRefetchError(undefined);
            return result.refetch().catch(function (err) {
                setRefetchError(err);
                return Promise.reject(err);
            });
        } });
}
