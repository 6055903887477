import { __assign, __read, __rest } from "tslib";
import React, { Fragment, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useOnClickOutside } from '@marvelapp/hooks';
import { useCurrentUser, usePermissions, userSelectors, } from '@marvelapp/marvel-3-application';
import { Box } from '@marvelapp/ui';
import NavLink from './NavLink';
import UpgradeBadge from './UpgradeBadge';
import { getHelpLinks, getMainLinks } from './links';
function NavLinks(_a) {
    var onLinkClick = _a.onLinkClick, location = _a.location, props = __rest(_a, ["onLinkClick", "location"]);
    var pathname = location.pathname;
    var user = useCurrentUser();
    var isEnterprise = userSelectors.isEnterprise(user);
    var isFree = userSelectors.isFree(user);
    var permissions = usePermissions();
    var mainLinks = getMainLinks({ permissions: permissions, isEnterprise: isEnterprise });
    var mainLinksCanView = mainLinks.filter(function (link) { return link.canView; });
    var email = user.email, username = user.username;
    var helpLinks = getHelpLinks({ email: email, username: username, isEnterprise: isEnterprise });
    var ref = useRef();
    var _b = __read(useState(null), 2), openSubmenu = _b[0], setOpenSubmenu = _b[1];
    useOnClickOutside(ref, function () {
        setOpenSubmenu(null);
    });
    return (React.createElement(Box, __assign({}, props),
        isFree && (React.createElement(Box, { marginBottom: 4, px: 3 },
            React.createElement(UpgradeBadge, null))),
        mainLinksCanView.map(function (link) { return (React.createElement(Fragment, { key: link.name },
            React.createElement(NavLink, { active: openSubmenu === null &&
                    (link.name === 'Folders'
                        ? pathname.includes('/folder/') ||
                            pathname.includes('/folders/')
                        : pathname.includes(link.to)), kind: pathname.includes(link.to) ? 'licorice' : link.kind, mt: link.mt ? link.mt : 0, onClick: onLinkClick, to: link.to }, link.name),
            pathname.includes(link.to) &&
                link.sublinks
                    .filter(function (sublink) { return sublink.canView; })
                    .map(function (sublink, index, array) { return (React.createElement(NavLink, { activeClassName: "active", isSublink: true, key: sublink.name, marginBottom: index === array.length - 1 ? 3 : 1, onClick: onLinkClick, to: sublink.to }, sublink.name)); }))); }),
        React.createElement(NavLink, { kind: "black", to: helpLinks[0].to }, "Help")));
}
export default withRouter(NavLinks);
