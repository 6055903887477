import React from 'react';
import { Link } from '@marvelapp/ui';
import { useVideoOverlay } from '../../VideoOverlayContext';
import Item from '../Item';
import VideoIcon from './Icon';
var ItemVideo = function (_a) {
    var item = _a.item, onClick = _a.onClick;
    var show = useVideoOverlay().show;
    return (React.createElement(Link, { display: "block", onClick: function () {
            show(item);
            onClick();
        } },
        React.createElement(Item, { icon: React.createElement(VideoIcon, null), item: item }, item.title)));
};
export default ItemVideo;
