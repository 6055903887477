import { noop } from 'lodash-es';
import React, { Fragment } from 'react';
import theme, { CLOSE_ICON, SIDEBAR_WIDTH } from '@marvelapp/theme';
import CircleButton from '../CircleButton';
import Icon from '../Icon';
import MenuButton from '../MenuButton';
import Position from '../Position';
import { SidebarElement } from './styles';
function Sidebar(_a) {
    var _b = _a.bg, bg = _b === void 0 ? 'white' : _b, _c = _a.boxShadow, boxShadow = _c === void 0 ? 'borders.right' : _c, buttonBg = _a.buttonBg, buttonColor = _a.buttonColor, buttonText = _a.buttonText, children = _a.children, height = _a.height, isOpen = _a.isOpen, maxHeight = _a.maxHeight, onMenuButtonClick = _a.onMenuButtonClick, overflow = _a.overflow, sidebarWidth = _a.sidebarWidth;
    var translateWidth = isOpen
        ? "translateX(".concat(sidebarWidth, "px)")
        : 'translateX(-1px)';
    var transform = [
        translateWidth,
        translateWidth,
        translateWidth,
        'translateX(0)',
    ];
    var left = [-sidebarWidth, -sidebarWidth, -sidebarWidth, 0];
    var display = ['block', 'block', 'block', 'none'];
    return (React.createElement(Fragment, null,
        React.createElement(SidebarElement, { bg: bg, boxShadow: boxShadow, height: height, left: left, maxHeight: maxHeight, overflow: overflow, top: 0, transform: transform, width: sidebarWidth, zIndex: "sidebar" },
            children,
            React.createElement(Position, { display: display, padding: 12, position: "absolute", right: 0 },
                React.createElement(CircleButton, { onClick: onMenuButtonClick },
                    React.createElement(Icon, { paths: CLOSE_ICON })))),
        React.createElement(MenuButton, { bg: buttonBg, buttonText: buttonText, color: buttonColor, display: display, onClick: onMenuButtonClick })));
}
Sidebar.defaultProps = {
    buttonBg: 'white',
    buttonColor: 'slate',
    buttonText: 'Menu',
    closeTop: 0,
    isOpen: false,
    onMenuButtonClick: noop,
    sidebarWidth: SIDEBAR_WIDTH,
    theme: theme,
};
export default Sidebar;
