import { __makeTemplateObject } from "tslib";
import { motion } from 'framer-motion';
import styled, { css } from '@marvelapp/styled';
import Box from '../Box';
export var Container = styled(motion.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  pointer-events: none;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  pointer-events: none;\n"])));
export var Cover = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: ", "px;\n  pointer-events: all;\n\n  ", ";\n\n  ", ";\n"], ["\n  position: absolute;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: ", "px;\n  pointer-events: all;\n\n  ", ";\n\n  ", ";\n"])), function (props) { return props.distance; }, function (props) {
    return props.position === 'top' && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      bottom: -", "px;\n    "], ["\n      bottom: -", "px;\n    "])), props.distance);
}, function (props) {
    return props.position === 'bottom' && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      top: -", "px;\n    "], ["\n      top: -", "px;\n    "])), props.distance);
});
export var PopoverElement = styled(Box)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  position: absolute;\n  pointer-events: all;\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"], ["\n  position: absolute;\n  pointer-events: all;\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"])), function (props) {
    return props.align === 'left' && css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      left: 0;\n    "], ["\n      left: 0;\n    "])));
}, function (props) {
    return props.align === 'right' && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      right: 0;\n    "], ["\n      right: 0;\n    "])));
}, function (props) {
    return props.align === 'center' && css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      left: 50%;\n      transform: translateX(-50%);\n    "], ["\n      left: 50%;\n      transform: translateX(-50%);\n    "])));
}, function (props) {
    return props.position === 'top' && css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      bottom: 100%;\n      margin-bottom: ", "px;\n    "], ["\n      bottom: 100%;\n      margin-bottom: ", "px;\n    "])), props.distance);
}, function (props) {
    return props.position === 'bottom' && css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n      top: 100%;\n      margin-top: ", "px;\n    "], ["\n      top: 100%;\n      margin-top: ", "px;\n    "])), props.distance);
}, function (props) {
    return props.position === 'bottomLeft' && css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n      top: 100%;\n      margin-top: ", "px;\n      left: 0;\n      transform: none;\n    "], ["\n      top: 100%;\n      margin-top: ", "px;\n      left: 0;\n      transform: none;\n    "])), props.distance);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
