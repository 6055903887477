import { get } from 'lodash-es';
import DetachedProjects from '../components/multiple/DetachedProjects';
import FloatingRoleSurvey from '../components/multiple/FloatingRoleSurvey';
import HBDashboardWhiteboadBeta from '../components/multiple/HBDashboardWhiteboadBeta';
import HBPrototypeTutorial from '../components/multiple/HBPrototypeTutorial';
import HBUserTestingTutorial from '../components/multiple/HBUserTestingTutorial';
import NBBallpark from '../components/multiple/NBBallpark';
import NBEnterpriseRenew from '../components/multiple/NBEnterpriseRenew';
import NBFreeTrial from '../components/multiple/NBFreeTrial';
import NBHitProjectLimits from '../components/multiple/NBHitProjectLimits';
import NBStunning from '../components/multiple/NBStunning';
import TUCreateFirstPrototype from '../components/multiple/TUCreateFirstPrototype';
import { getPLGUpsellContent } from '../components/multiple/TUPLGContent';
import TUUserTestingEbook from '../components/multiple/TUUserTestingEbook';
import HBSwitchToYearly, { HBSwitchToYearlyAccount, } from '../components/owner/HBSwitchToYearly';
import HBSwitchToYearlySpecial, { HBSwitchToYearlySpecialAccount, } from '../components/owner/HBSwitchToYearlySpecial';
import HBSwitchToYearlySpecialTeam, { HBSwitchToYearlySpecialAccountTeam, } from '../components/owner/HBSwitchToYearlySpecialTeam';
import * as id from '../identifiers';
import * as position from '../positions';
export default function upsells(_a) {
    var _b;
    var userInfo = _a.userInfo, stunning = _a.stunning;
    var hasHitPrototypeLimit = userInfo.hasHitPrototypeLimit, hasHitUserTestLimit = userInfo.hasHitUserTestLimit, hitAnyProjectsLimit = userInfo.hitAnyProjectsLimit, hotspotsOwnedCount = userInfo.hotspotsOwnedCount, isAccountLocked = userInfo.isAccountLocked, isEnterprise = userInfo.isEnterprise, isPro = userInfo.isPro, isTeam = userInfo.isTeam, isTeamPlus = userInfo.isTeamPlus, nextPaymentWithinExpiryWindow = userInfo.nextPaymentWithinExpiryWindow, planIsYearly = userInfo.planIsYearly, planPayment = userInfo.planPayment, prototypeProjectCount = userInfo.prototypeProjectCount, prototypeProjectsOwnedCount = userInfo.prototypeProjectsOwnedCount, remainingTrialDays = userInfo.remainingTrialDays, screensOwnedCount = userInfo.screensOwnedCount, occupation = userInfo.occupation, userTestProjectsOwnedCount = userInfo.userTestProjectsOwnedCount, monthsSinceSignup = userInfo.monthsSinceSignup, isWhiteboardEnabled = userInfo.isWhiteboardEnabled;
    var canSwitchToYearly = !planIsYearly && !isEnterprise;
    return _b = {},
        _b[position.FLOATING] = [
            {
                identifier: id.ROLE_QUESTION,
                component: FloatingRoleSurvey,
                isClosable: true,
                condition: occupation === null,
                daysUntilReshownAfterClose: 30,
            },
        ],
        _b[position.FREE_TRIAL_NOTIFICATION_BAR] = [
            {
                identifier: id.FREE_TRIAL,
                component: NBFreeTrial,
                isClosable: false,
                condition: remainingTrialDays > 0,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.PROTOTYPE_HINT_BAR] = [
            {
                identifier: id.PROTOTYPE_TUTORIAL,
                component: HBPrototypeTutorial,
                isClosable: true,
                condition: screensOwnedCount < 2 || hotspotsOwnedCount === 0,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.DASHBOARD_NOTIFICATION_BAR] = [
            {
                identifier: id.ENTERPRISE_RENEW,
                component: NBEnterpriseRenew,
                isClosable: false,
                condition: !userInfo.paysWithStripe &&
                    isEnterprise &&
                    (isAccountLocked || nextPaymentWithinExpiryWindow),
                daysUntilReshownAfterClose: 365,
            },
            {
                identifier: id.STUNNING,
                component: NBStunning,
                isClosable: false,
                condition: get(stunning, 'response.status') === 'expiring' ||
                    get(stunning, 'response.status') === 'expired',
                daysUntilReshownAfterClose: 365,
            },
            {
                identifier: id.DASHBOARD_REACHED_PROJECT_LIMIT,
                component: NBHitProjectLimits,
                isClosable: false,
                condition: !remainingTrialDays && hitAnyProjectsLimit,
                daysUntilReshownAfterClose: 365,
            },
            {
                identifier: id.DASHBOARD_BALLPARK,
                component: NBBallpark,
                isClosable: true,
                condition: !isEnterprise,
                daysUntilReshownAfterClose: 30,
            },
        ],
        _b[position.ACCOUNT_HINT_BAR] = [
            {
                identifier: id.DASHBOARD_YEARLY_SPECIAL,
                component: HBSwitchToYearlySpecialAccount,
                isClosable: false,
                condition: isPro && planPayment === 1600 && canSwitchToYearly,
                daysUntilReshownAfterClose: 90,
            },
            {
                identifier: id.DASHBOARD_YEARLY_SPECIAL_TEAM,
                component: HBSwitchToYearlySpecialAccountTeam,
                isClosable: false,
                condition: isTeam && canSwitchToYearly,
                daysUntilReshownAfterClose: 90,
            },
            {
                identifier: id.ACCOUNT_YEARLY,
                component: HBSwitchToYearlyAccount,
                isClosable: false,
                condition: (isTeamPlus || (isPro && planPayment !== 1600)) && canSwitchToYearly,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.DASHBOARD_TINY_UPSELLER] = [
            {
                identifier: id.DASHBOARD_FIRST_PROJECT,
                component: TUCreateFirstPrototype,
                isClosable: true,
                condition: !hasHitPrototypeLimit && prototypeProjectsOwnedCount === 0,
                daysUntilReshownAfterClose: 365,
            },
            getPLGUpsellContent({ monthsSinceSignup: monthsSinceSignup }),
            {
                identifier: id.DASHBOARD_USER_TESTING_EBOOK,
                component: TUUserTestingEbook,
                isClosable: true,
                condition: false,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.DASHBOARD_HINT_BAR] = [
            {
                identifier: id.DASHBOARD_YEARLY_SPECIAL_TEAM,
                component: HBSwitchToYearlySpecialTeam,
                isClosable: true,
                condition: isTeam && canSwitchToYearly,
                daysUntilReshownAfterClose: 90,
            },
            {
                identifier: id.DASHBOARD_YEARLY_SPECIAL,
                component: HBSwitchToYearlySpecial,
                isClosable: true,
                condition: isPro && planPayment === 1600 && canSwitchToYearly,
                daysUntilReshownAfterClose: 90,
            },
            {
                identifier: id.DASHBOARD_YEARLY,
                component: HBSwitchToYearly,
                isClosable: true,
                condition: (isTeamPlus || (isPro && planPayment !== 1600)) && canSwitchToYearly,
                daysUntilReshownAfterClose: 90,
            },
            {
                identifier: id.DASHBOARD_WHITEBOARD_BETA,
                component: HBDashboardWhiteboadBeta,
                isClosable: true,
                condition: isWhiteboardEnabled,
                daysUntilReshownAfterClose: 30,
            },
            {
                identifier: id.USER_TESTING_TUTORIAL,
                component: HBUserTestingTutorial,
                isClosable: true,
                condition: prototypeProjectCount > 0 &&
                    userTestProjectsOwnedCount === 0 &&
                    !hasHitUserTestLimit,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.DASHBOARD_HINT_BAR_PERSONAL_PROJECTS] = [
            {
                identifier: id.DETACHED_PROJECTS,
                component: DetachedProjects,
                isClosable: false,
                condition: true,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b;
}
