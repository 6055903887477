import { __makeTemplateObject } from "tslib";
import { border, color, flexbox, layout, position, space, typography, } from 'styled-system';
import styled, { css } from '@marvelapp/styled';
import { cursor, fontVariantNumeric, hoverBoxShadow, textDecoration, textShadow, textTransform, transitionDuration, userSelect, whiteSpace, wordBreak, } from '../customProperties';
export var TextElement = styled.p(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    ", ";\n  }\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n\n  &:hover {\n    ", ";\n  }\n\n  ", ";\n\n  ", ";\n\n  ", ";\n"])), border, color, cursor, flexbox, fontVariantNumeric, layout, position, space, textDecoration, textShadow, textTransform, transitionDuration, typography, userSelect, whiteSpace, wordBreak, hoverBoxShadow, function (props) {
    return props.truncated && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n    "], ["\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n    "])));
}, function (props) {
    return props.truncatedTwo && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      overflow: hidden;\n      text-overflow: ellipsis;\n      display: -webkit-box;\n      -webkit-line-clamp: 2;\n      -webkit-box-orient: vertical;\n    "], ["\n      overflow: hidden;\n      text-overflow: ellipsis;\n      display: -webkit-box;\n      -webkit-line-clamp: 2;\n      -webkit-box-orient: vertical;\n    "])));
}, function (props) {
    return props.wrap && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      word-break: break-word;\n    "], ["\n      word-break: break-word;\n    "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
