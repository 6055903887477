import { get } from 'lodash-es';
import DetachedProjects from '../components/multiple/DetachedProjects';
import FloatingRoleSurvey from '../components/multiple/FloatingRoleSurvey';
import NBBallpark from '../components/multiple/NBBallpark';
import NBStunning from '../components/multiple/NBStunning';
import { getPLGUpsellContent } from '../components/multiple/TUPLGContent';
import TUUserTestingEbook from '../components/multiple/TUUserTestingEbook';
import * as id from '../identifiers';
import * as position from '../positions';
export default function upsells(_a) {
    var _b;
    var userInfo = _a.userInfo, stunning = _a.stunning;
    var isEnterprise = userInfo.isEnterprise, occupation = userInfo.occupation, monthsSinceSignup = userInfo.monthsSinceSignup;
    return _b = {},
        _b[position.FLOATING] = [
            {
                identifier: id.ROLE_QUESTION,
                component: FloatingRoleSurvey,
                isClosable: true,
                condition: occupation === null,
                daysUntilReshownAfterClose: 30,
            },
        ],
        _b[position.DASHBOARD_NOTIFICATION_BAR] = [
            {
                identifier: id.DASHBOARD_BALLPARK,
                component: NBBallpark,
                isClosable: true,
                condition: !isEnterprise,
                daysUntilReshownAfterClose: 30,
            },
            {
                identifier: id.STUNNING,
                component: NBStunning,
                isClosable: false,
                condition: get(stunning, 'response.status') === 'expiring' ||
                    get(stunning, 'response.status') === 'expired',
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.DASHBOARD_TINY_UPSELLER] = [
            getPLGUpsellContent({ monthsSinceSignup: monthsSinceSignup }),
            {
                identifier: id.DASHBOARD_USER_TESTING_EBOOK,
                component: TUUserTestingEbook,
                isClosable: true,
                condition: false,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b[position.DASHBOARD_HINT_BAR_PERSONAL_PROJECTS] = [
            {
                identifier: id.DETACHED_PROJECTS,
                component: DetachedProjects,
                isClosable: false,
                condition: true,
                daysUntilReshownAfterClose: 365,
            },
        ],
        _b;
}
