import { __assign, __rest } from "tslib";
import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@marvelapp/ui';
export default function IconCircle(_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Box, __assign({ alignItems: "center", border: "1px solid", borderColor: "rgba(255,255,255,0.4)", borderRadius: 12, display: "flex", height: 24, justifyContent: "center", width: 24 }, props), children));
}
IconCircle.propTypes = {
    children: PropTypes.any,
};
