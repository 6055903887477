import { __extends } from "tslib";
import PropTypes from 'prop-types';
import { Component } from 'react';
var EscKeyHandler = /** @class */ (function (_super) {
    __extends(EscKeyHandler, _super);
    function EscKeyHandler() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.escFunction = function (event) {
            var isEscKey = event.key === 'escape' || event.keyCode === 27;
            if (isEscKey && _this.props.onEscapeKey) {
                _this.props.onEscapeKey();
            }
        };
        return _this;
    }
    EscKeyHandler.prototype.componentDidMount = function () {
        document.addEventListener('keydown', this.escFunction, false);
    };
    EscKeyHandler.prototype.componentWillUnmount = function () {
        document.removeEventListener('keydown', this.escFunction, false);
    };
    EscKeyHandler.prototype.render = function () {
        return null;
    };
    return EscKeyHandler;
}(Component));
EscKeyHandler.propTypes = {
    onEscapeKey: PropTypes.func,
};
export default EscKeyHandler;
