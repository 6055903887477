import { __makeTemplateObject } from "tslib";
import { border, flexbox, layout, opacity, space } from 'styled-system';
import styled, { css, keyframes } from '@marvelapp/styled';
var BACKGROUND_SIZE = 468;
var placeHolderShimmer = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%{\n    background-position: -", "px 0\n  }\n  100%{\n    background-position: ", "px 0\n  }\n"], ["\n  0%{\n    background-position: -", "px 0\n  }\n  100%{\n    background-position: ", "px 0\n  }\n"])), BACKGROUND_SIZE, BACKGROUND_SIZE);
/** @type {any} */
export var ContentLoader = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  animation-duration: 1600ms;\n  animation-fill-mode: forwards;\n  animation-iteration-count: infinite;\n  animation-name: ", ";\n  animation-timing-function: ease-in-out;\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  background-size: 800px 104px;\n"], ["\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  animation-duration: 1600ms;\n  animation-fill-mode: forwards;\n  animation-iteration-count: infinite;\n  animation-name: ", ";\n  animation-timing-function: ease-in-out;\n\n  ", ";\n\n  ", ";\n\n  ", ";\n\n  background-size: 800px 104px;\n"])), border, layout, opacity, space, flexbox, placeHolderShimmer, function (props) {
    return props.kind === 'light' && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      background: ", ";\n      background: linear-gradient(\n        to right,\n        ", " 8%,\n        ", " 18%,\n        ", " 33%\n      );\n    "], ["\n      background: ", ";\n      background: linear-gradient(\n        to right,\n        ", " 8%,\n        ", " 18%,\n        ", " 33%\n      );\n    "])), props.theme.colors.snow, props.theme.colors.snow, props.theme.colors.snowDark, props.theme.colors.snow);
}, function (props) {
    return props.kind === 'medium' && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background: ", ";\n      background: linear-gradient(\n        to right,\n        ", " 8%,\n        ", " 18%,\n        ", " 33%\n      );\n    "], ["\n      background: ", ";\n      background: linear-gradient(\n        to right,\n        ", " 8%,\n        ", " 18%,\n        ", " 33%\n      );\n    "])), props.theme.colors.snowDark, props.theme.colors.snowDark, props.theme.colors.snowExtraDark, props.theme.colors.snowDark);
}, function (props) {
    return props.kind === 'dark' && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      background: ", ";\n      background: linear-gradient(\n        to right,\n        ", " 8%,\n        #4a5664 18%,\n        ", " 33%\n      );\n    "], ["\n      background: ", ";\n      background: linear-gradient(\n        to right,\n        ", " 8%,\n        #4a5664 18%,\n        ", " 33%\n      );\n    "])), props.theme.colors.slate, props.theme.colors.slate, props.theme.colors.slate);
});
ContentLoader.defaultProps = {
    kind: 'light',
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
