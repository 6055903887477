import * as id from '../../../identifiers';
import containers from './images/containers.jpg';
import containers2x from './images/containers@2x.jpg';
import designled from './images/design-led.jpg';
import designled2x from './images/design-led@2x.jpg';
import designthinking from './images/design-thinking.jpg';
import designthinking2x from './images/design-thinking@2x.jpg';
import presenting from './images/presenting.jpg';
import presenting2x from './images/presenting@2x.jpg';
import zoom from './images/zoom.jpg';
import zoom2x from './images/zoom@2x.jpg';
export var articles = [
    {
        id: id.DASHBOARD_CONTENT_CAROUSELS,
        title: 'Create carousels and scrolling sidebars with containers!',
        url: '/blog/create-carousels-and-scrolling-sidebars-with-containers/',
        image: containers,
        image2x: containers2x,
    },
    {
        id: id.DASHBOARD_CONTENT_REMOTE_USABILITY,
        title: 'How to run remote usability tests using Zoom or Hangouts',
        url: '/blog/run-remote-usability-tests-using-marvel-zoom-google-hangouts/',
        image: zoom,
        image2x: zoom2x,
    },
    {
        id: id.DASHBOARD_CONTENT_DESIGN_THINKING,
        title: 'How to run a remote Design Thinking workshop',
        url: '/blog/how-to-run-a-remote-design-thinking-workshop/',
        image: designthinking,
        image2x: designthinking2x,
    },
    {
        id: id.DASHBOARD_CONTENT_PITCH_PRESENT,
        title: 'How to pitch and present ideas using prototypes',
        url: '/blog/pitch-present-ideas-using-prototypes-clients-stakeholders/',
        image: presenting,
        image2x: presenting2x,
    },
    {
        id: id.DASHBOARD_CONTENT_DESIGN_LED,
        title: 'How to drive a design led culture',
        url: '/blog/start-your-design-led-journey/',
        image: designled,
        image2x: designled2x,
    },
];
