import { __read, __spreadArray } from "tslib";
import throttle from 'lodash/throttle';
import { useEffect, useState } from 'react';
export var on = function (obj) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return obj.addEventListener.apply(obj, __spreadArray([], __read(args), false));
};
export var off = function (obj) {
    var args = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        args[_i - 1] = arguments[_i];
    }
    return obj.removeEventListener.apply(obj, __spreadArray([], __read(args), false));
};
var defaultEvents = [
    'mousemove',
    'mousedown',
    'resize',
    'keydown',
    'touchstart',
    'wheel',
];
var useIdle = function (ms, initialState, events) {
    if (ms === void 0) { ms = 15000; }
    if (initialState === void 0) { initialState = false; }
    if (events === void 0) { events = defaultEvents; }
    var _a = __read(useState(initialState), 2), state = _a[0], setState = _a[1];
    useEffect(function () {
        var mounted = true;
        var timeout;
        var localState = state;
        var set = function (newState) {
            if (mounted) {
                localState = newState;
                setState(newState);
            }
        };
        var onEvent = throttle(function () {
            if (localState) {
                set(false);
            }
            clearTimeout(timeout);
            timeout = setTimeout(function () { return set(true); }, ms);
        }, 50);
        var onVisibility = function () {
            if (!document.hidden)
                onEvent();
        };
        for (var i = 0; i < events.length; i++) {
            on(window, events[i], onEvent);
        }
        on(document, 'visibilitychange', onVisibility);
        timeout = setTimeout(function () { return set(true); }, ms);
        return function () {
            mounted = false;
            for (var i = 0; i < events.length; i++) {
                off(window, events[i], onEvent);
            }
            off(document, 'visibilitychange', onVisibility);
        };
    }, [events, ms, state]);
    return state;
};
export default useIdle;
