export var URL = 'https://help.marvelapp.com/hc/en-us';
var link = function (id) { return "".concat(URL, "/articles/").concat(id); };
export var NEW_TICKET = "".concat(URL, "/requests/new");
export var BILLING_REMOVING_MEMBERS = link(360002743757);
export var HOW_PLAY_PROTOTYPE = link(360002730717);
export var CREATE_FIRST_PROTOTYPE = link(360002536038);
export var GETTING_STARTED_HANDOFF = link(360002794538);
export var DETACHED_PROJECTS = link(36000485047);
export var PROTOTYPING_LAYERS = link(209299205);
export var USER_TESTING_INTRODUCTION = link(360002558237);
export var USER_TESTING_TROUBLESHOOTING = link(360001834078);
export var HOW_TO_INVITE_USERS = link(360002561038);
export var MANAGING_MEMBERS_AND_GUESTS = link(360003166018);
export var SWITCH_MEMBERS_GUESTS = link(360004864397);
export var CHANGE_TEAM_MEMBERS_PERMISSION = link(360009863757);
export var REMOVE_MEMBERS = link(360004864457);
export var SWITCH_OWNERS = link(360003212158);
export var GROUPS = link(360008844537);
export var SIGNIN_PROTECTED_PROTOTYPES = link(360003212218);
export var TEAM_MEMBERS_INVITING = link(360009942478);
export var HOW_TO_CHANGE_TEAM_NAME = link(360003229437);
export var HOW_TO_CHANGE_TEAM_ICON = link(360003229197);
export var SUBDOMAINS = link(360000626869);
export var ARCHIVING_PROJECTS = link(360010395997);
export var COPY_PROJECTS = link(360007955278);
export var DELETE_PROJECTS = link(360010395357);
export var RENAME_PROJECTS = link(360002855617);
export var USING_FOLDERS = link(360002522538);
export var SHARE_FOLDER = link(360002520757);
export var PHOTOSHOP = link(360018729478);
export var ADD_REMOVE_FOLDER_PROJECTS = link(360002522598);
export var FOLDER_DESCRIPTION = link(360004948677);
export var SORT_FOLDER_PROJECTS = link(360002855717);
export var CHANGE_DEVICE = link(360002748358);
export var CHANGE_BACKGROUND = link(360002747217);
export var DISABLE_RESIZING = link(360002746878);
export var HIDE_HOTSPOT_HINTS = link(360002746798);
export var HIDE_RETINA = link(360002729958);
export var SHARE_PROTOTYPE = link(360002646138);
export var ADD_COLLABORATORS = link(360002646178);
export var INVITE_ONLY = link(360007914597);
export var PASSWORD_PROTECT = link(360002851757);
export var STUDENTS = link(360003458197);
export var PROTOTYPING_CONTAINERS = link(360014699118);
export var USER_TESTING_STATUS = link(360016632057);
export var ENABLE_CAMERA_CHROME = link(4403383831185);
export var ENABLE_CAMERA_SAFARI_MAC = link(4403383377041);
export var ENABLE_CAMERA_FIREFOX = link(4403567877649);
export var ENABLE_SCREEN_SHARING_SAFARI_MAC = link(4403548711441);
export var ENABLE_SCREEN_ACCESS_MAC = link(4403376006673);
export var ENABLE_CAMERA_ACCESS_MAC = link(4403375731089);
export var ENABLE_CAMERA_OPERA = link(4403384249617);
export var ENABLE_CAMERA_EDGE = link(4403545692177);
export var CREATE_UT_FIGMA = link(4403727902481);
