import React from 'react';
function appRoute(_a) {
    var loader = _a.loader, path = _a.path, flag = _a.flag;
    return {
        path: path,
        flag: flag,
        // We take advantage of webpack memoizing what already being fetched
        // to do dynamic preloading
        preload: loader,
        Application: React.lazy(function () {
            return loader().then(function (module) {
                if (!module.Marvel3ReactApp) {
                    var message = 'Application must export `Marvel3ReactApp`';
                    throw new Error(message);
                }
                return {
                    default: module.Marvel3ReactApp,
                };
            });
        }),
    };
}
export var routingMap = [
    appRoute({
        path: '/(login|auth)',
        loader: function () {
            return import(
            /* webpackChunkName: "auth-ui" */
            '@marvelapp/auth-ui');
        },
    }),
    appRoute({
        path: '/(build|company/switch|company/sign-in|folder/public|staticui)',
        flag: 'marvel-3-static-ui',
        loader: function () {
            return import(
            /* webpackChunkName: "static-ui" */
            '@marvelapp/static-ui');
        },
    }),
    appRoute({
        path: '/(university)',
        loader: function () {
            return import(
            /* webpackChunkName: "static-ui" */
            '@marvelapp/university-ui');
        },
    }),
    appRoute({
        path: '/folder/:id/add-to-folder',
        flag: 'marvel-3-static-ui',
        loader: function () {
            return import(
            /* webpackChunkName: "static-ui" */
            '@marvelapp/static-ui');
        },
    }),
    appRoute({
        path: '/folder/:id/add-links',
        flag: 'marvel-3-static-ui',
        loader: function () {
            return import(
            /* webpackChunkName: "static-ui" */
            '@marvelapp/static-ui');
        },
    }),
    appRoute({
        path: '/(projects|folders?|archive)',
        loader: function () {
            return import(/* webpackChunkName: "dashboard-ui" */ '@marvelapp/dashboard-ui');
        },
    }),
    appRoute({
        path: '/(user-test|start-user-test)',
        loader: function () {
            return import(
            /* webpackChunkName: "user-testing-ui" */ '@marvelapp/user-testing-ui');
        },
    }),
    appRoute({
        path: '/company',
        loader: function () {
            return import(/* webpackChunkName: "people-ui" */ '@marvelapp/people-ui');
        },
    }),
    appRoute({
        path: '/project/:projectPk/design',
        flag: 'design-ui-v-3',
        loader: function () {
            return import(/* webpackChunkName: "design-ui" */ '@marvelapp/design-ui');
        },
    }),
    appRoute({
        path: '/whiteboard/:shareId',
        // flag: 'whiteboard-ui',
        loader: function () {
            return import(
            /* webpackChunkName: "whiteboard-ui" */ '@marvelapp/whiteboard-ui');
        },
    }),
    appRoute({
        path: '/project/:projectPk/design',
        loader: function () {
            return import(
            /* webpackChunkName: "legacy-design-ui" */
            '@marvelapp/legacy-design-ui');
        },
    }),
    appRoute({
        path: '/project/:projectPk/screen/:screenPk',
        loader: function () {
            return import(
            /* webpackChunkName: "prototype-editor-ui" */ '@marvelapp/prototype-editor-ui');
        },
    }),
    appRoute({
        path: '/project/:projectPk(/settings|/download)?',
        loader: function () {
            return import(
            /* webpackChunkName: "prototype-project-ui" */ '@marvelapp/prototype-project-ui');
        },
    }),
    appRoute({
        // This will match a URL starting with /prototype,
        // one or more word characters identifying the prototype ID,
        // and any other character after the ID.
        // The full regex is defined in prototype-viewer/src/utils/location.js
        path: '/prototype/:prototypeId(\\w+):rest(.*)?',
        loader: function () {
            return import(
            /* webpackChunkName: "prototype-viewer-ui" */ '@marvelapp/prototype-viewer-ui');
        },
    }),
    appRoute({
        path: '/user/account',
        loader: function () {
            return import(
            /* webpackChunkName: "account-ui" */
            '@marvelapp/account-ui');
        },
    }),
    appRoute({
        path: '/invite',
        loader: function () {
            return import(
            /* webpackChunkName: "invites-ui" */
            '@marvelapp/invites-ui');
        },
    }),
    appRoute({
        path: '/(plans|switch|upgrade|upgrade-success|update-credit-card|switch-to-yearly-plan|downgrade|plan-pricing)',
        loader: function () {
            return import(
            /* webpackChunkName: "subscription-ui" */
            '@marvelapp/subscription-ui');
        },
    }),
    appRoute({
        path: '/onboarding',
        loader: function () {
            return import(
            /* webpackChunkName: "onboarding-ui" */
            '@marvelapp/onboarding-ui');
        },
    }),
];
