import { __assign, __read, __rest } from "tslib";
import React, { useEffect, useState } from 'react';
import { loadImage } from '../utils';
import { ImageElement } from './styles';
/** @param {any} */
function Image(_a) {
    var alt = _a.alt, disabled = _a.disabled, finishedLoading = _a.finishedLoading, _b = _a.hasTransition, hasTransition = _b === void 0 ? true : _b, _c = _a.preloadImage, preloadImage = _c === void 0 ? true : _c, src = _a.src, src2x = _a.src2x, testId = _a.testId, rest = __rest(_a, ["alt", "disabled", "finishedLoading", "hasTransition", "preloadImage", "src", "src2x", "testId"]);
    var _d = __read(useState(false), 2), isCancelled = _d[0], setIsCancelled = _d[1];
    var _e = __read(useState(false), 2), imageLoaded = _e[0], setImageLoaded = _e[1];
    var fetchImage = function () {
        if (!src || !hasTransition) {
            return;
        }
        loadImage(src)
            .then(function () {
            if (!isCancelled) {
                setImageLoaded(true);
                if (finishedLoading) {
                    finishedLoading({ success: true });
                }
            }
        })
            .catch(function () {
            if (finishedLoading) {
                finishedLoading({ success: false });
            }
        });
    };
    var cleanup = function () {
        setIsCancelled(true);
    };
    useEffect(function () {
        fetchImage();
        return cleanup;
    });
    var transitionProps = getOpacityProps({
        imageLoaded: imageLoaded,
        hasTransition: hasTransition,
        disabled: disabled,
    });
    var disabledProps = disabled && {
        filter: 'grayscale(1)',
    };
    return (React.createElement(ImageElement, __assign({ alt: alt, "data-testid": testId, draggable: false, preloadImage: preloadImage, src: src, srcSet: src2x && "".concat(src2x, " 2x") }, disabledProps, transitionProps, rest)));
}
function getOpacityProps(_a) {
    var imageLoaded = _a.imageLoaded, hasTransition = _a.hasTransition, disabled = _a.disabled;
    if (disabled) {
        return { opacity: 0.5 };
    }
    if (hasTransition && !disabled) {
        return { opacity: imageLoaded ? 1 : 0 };
    }
    if (!hasTransition && !disabled) {
        return { opacity: 1 };
    }
}
export default Image;
