import { __makeTemplateObject, __read } from "tslib";
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import update from 'immutability-helper';
import { get } from 'lodash-es';
import emoji from 'node-emoji';
import React, { useEffect, useRef, useState } from 'react';
import { heapTrackEvent } from '@marvelapp/core';
import { useDebouncedState } from '@marvelapp/hooks';
import { Box, Flex, Semibold, SidebarDialog, SidebarHeading, } from '@marvelapp/ui';
import { NoItems, SimpleSearchInput, showErrorNotification, } from '@marvelapp/ui-internal';
import CommentTabs, { ALL_TAB, UNREAD_TAB } from '../CommentTabs';
import CommentsEmptyState from '../CommentsEmptyState';
import CommentList from './CommentList';
export var GET_COMMENTS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query getComments(\n    $cursor: String\n    $project: Int\n    $excludeSelf: Boolean\n    $unreadOnly: Boolean\n    $searchQuery: String\n  ) {\n    unreadCommentCount(project: $project)\n    comments(\n      first: 25\n      after: $cursor\n      project: $project\n      unreadOnly: $unreadOnly\n      excludeSelf: $excludeSelf\n      searchQuery: $searchQuery\n    ) @connection(key: \"comments\", filter: [\"unreadOnly\", \"project\"]) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          author {\n            userPk\n            username\n            avatarUrl\n          }\n          createdAt\n          pk\n          message\n          private\n          project {\n            name\n          }\n          screen {\n            displayName\n          }\n          status {\n            resolved\n          }\n          unread\n          url\n        }\n      }\n    }\n  }\n"], ["\n  query getComments(\n    $cursor: String\n    $project: Int\n    $excludeSelf: Boolean\n    $unreadOnly: Boolean\n    $searchQuery: String\n  ) {\n    unreadCommentCount(project: $project)\n    comments(\n      first: 25\n      after: $cursor\n      project: $project\n      unreadOnly: $unreadOnly\n      excludeSelf: $excludeSelf\n      searchQuery: $searchQuery\n    ) @connection(key: \"comments\", filter: [\"unreadOnly\", \"project\"]) {\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      edges {\n        node {\n          author {\n            userPk\n            username\n            avatarUrl\n          }\n          createdAt\n          pk\n          message\n          private\n          project {\n            name\n          }\n          screen {\n            displayName\n          }\n          status {\n            resolved\n          }\n          unread\n          url\n        }\n      }\n    }\n  }\n"])));
export var MARK_ALL_READ = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation ($input: MarkCommentsAsReadInput) {\n    markCommentsAsRead(input: $input) {\n      ok\n    }\n  }\n"], ["\n  mutation ($input: MarkCommentsAsReadInput) {\n    markCommentsAsRead(input: $input) {\n      ok\n    }\n  }\n"])));
export default function Sidebar(_a) {
    var _b, _c;
    var isOpen = _a.isOpen, projectPk = _a.projectPk, toggleSidebar = _a.toggleSidebar;
    var _d = __read(useState(UNREAD_TAB), 2), activeTab = _d[0], setActiveTab = _d[1];
    var _e = __read(useState(false), 2), isDirty = _e[0], setIsDirty = _e[1];
    var _f = __read(useDebouncedState(''), 3), debouncedSearchTerm = _f[0], setSearchTerm = _f[1], latestSearchTerm = _f[2];
    var _g = useQuery(GET_COMMENTS, {
        variables: {
            excludeSelf: activeTab === UNREAD_TAB,
            unreadOnly: activeTab === UNREAD_TAB,
            project: projectPk,
            searchQuery: emoji.unemojify(debouncedSearchTerm),
        },
        skip: !isOpen,
        onError: function () {
            showErrorNotification({
                autoClose: 5000,
                content: 'There was a problem fetching comments',
                toastId: 'failedToFetchComments',
            });
        },
        onCompleted: function () {
            heapTrackEvent('Searched Comment Notifications');
        },
    }), data = _g.data, loading = _g.loading, fetchMore = _g.fetchMore, error = _g.error;
    var isDisabled = (!((_b = data === null || data === void 0 ? void 0 : data.comments) === null || _b === void 0 ? void 0 : _b.edges.length) && !isDirty) || (loading && !isDirty);
    var unreadCommentCount = get(data, 'unreadCommentCount');
    useEffect(function () {
        if (unreadCommentCount === 0 && activeTab !== ALL_TAB) {
            setActiveTab(ALL_TAB);
        }
        // eslint-disable-next-line
    }, [unreadCommentCount]);
    var latestCommentPk = get(data, "comments.edges[0].node.pk");
    var _h = __read(useMarkCommentsRead(projectPk, latestCommentPk, activeTab), 1), markAllRead = _h[0];
    var onAllClick = function () {
        setActiveTab(ALL_TAB);
    };
    var onUnreadClick = function () {
        setActiveTab(UNREAD_TAB);
    };
    var onSideBarToggle = function () {
        setSearchTerm('');
        toggleSidebar();
    };
    var container = useRef(null);
    var showNoSearchResults = !((_c = data === null || data === void 0 ? void 0 : data.comments) === null || _c === void 0 ? void 0 : _c.edges.length) && isDirty;
    return (React.createElement(SidebarDialog, { isOpen: isOpen, isRight: projectPk, trigger: onSideBarToggle },
        React.createElement(Flex, { flexDirection: "column", height: "100%", justifyContent: "stretch" },
            React.createElement(SidebarHeading, { heading: React.createElement(SimpleSearchInput, { "data-testid": "search-notifications", id: "search-notifications", isDisabled: isDisabled, onChange: function (e) { return setSearchTerm(emoji.emojify(e.target.value)); }, onClear: function () { return setSearchTerm(''); }, onFocus: function () { return setIsDirty(true); }, placeholder: "Search through notifications", showClearButton: !!latestSearchTerm, value: latestSearchTerm }), onCloseClick: onSideBarToggle },
                React.createElement(CommentTabs, { activeTab: activeTab, onAllClick: onAllClick, onMarkAllClick: markAllRead, onUnreadClick: onUnreadClick, searchQuery: latestSearchTerm, unreadCount: unreadCommentCount })),
            React.createElement(Box, { flex: 1, overflow: "auto", ref: container },
                React.createElement(CommentList, { container: container, data: data, emptyState: showNoSearchResults ? (React.createElement(NoSearchResults, { searchTerm: latestSearchTerm })) : (React.createElement(CommentsEmptyState, { showAll: activeTab === UNREAD_TAB && onAllClick })), error: error, fetchMore: fetchMore, loading: loading, projectPk: projectPk, searchQuery: latestSearchTerm })))));
}
function NoSearchResults(_a) {
    var searchTerm = _a.searchTerm;
    return (React.createElement(NoItems, { "data-testid": "no-search-results-comments", height: "calc(100% - 20px)", marginTop: 0, subtitle: React.createElement(React.Fragment, null,
            "No results found for ",
            React.createElement(Semibold, null, searchTerm)), title: "Try another search" }));
}
function useMarkCommentsRead(projectPk, latestCommentPk, activeTab) {
    return useMutation(MARK_ALL_READ, {
        optimisticResponse: {
            markCommentsAsRead: { ok: true, __typename: 'MarkCommentsAsRead' },
        },
        variables: {
            input: { until: latestCommentPk, project: projectPk },
        },
        onError: function () {
            showErrorNotification({
                autoClose: 5000,
                content: 'There was a problem marking all comments as read',
                toastId: 'markCommentsRead',
            });
        },
        update: function (cache, _a) {
            var ok = _a.data.markCommentsAsRead.ok;
            if (!ok) {
                showErrorNotification({
                    autoClose: 5000,
                    content: 'There was a problem marking all comments as read',
                    toastId: 'markCommentsRead',
                });
                return;
            }
            if (activeTab === UNREAD_TAB) {
                clearCachedUnreadComments(projectPk, cache);
            }
            else {
                markCachedCommentsRead(projectPk, cache);
            }
        },
    });
}
function clearCachedUnreadComments(projectPk, cache) {
    var cachedData = cache.readQuery({
        query: GET_COMMENTS,
        variables: {
            unreadOnly: true,
            project: projectPk,
        },
    });
    if (!cachedData.comments) {
        return;
    }
    cache.writeQuery({
        query: GET_COMMENTS,
        variables: { unreadOnly: true, project: projectPk },
        data: update(cachedData, {
            unreadCommentCount: { $set: 0 },
            comments: {
                pageInfo: { hasNextPage: { $set: false } },
                edges: { $set: [] },
            },
        }),
    });
}
function markCachedCommentsRead(projectPk, cache) {
    var cachedData = cache.readQuery({
        query: GET_COMMENTS,
        variables: {
            unreadOnly: false,
            project: projectPk,
        },
    });
    if (!cachedData.comments) {
        return;
    }
    cache.writeQuery({
        query: GET_COMMENTS,
        variables: {
            unreadOnly: false,
            project: projectPk,
        },
        data: update(cachedData, {
            unreadCommentCount: { $set: 0 },
            comments: {
                edges: {
                    $apply: function (edges) {
                        return edges.map(function (edge) {
                            return update(edge, { node: { unread: { $set: false } } });
                        });
                    },
                },
            },
        }),
    });
}
var templateObject_1, templateObject_2;
