import React from 'react';
import { Flex, Tab, UnreadBubble } from '@marvelapp/ui';
import { Link } from '@marvelapp/ui-internal';
export var UNREAD_TAB = 'Unread';
export var ALL_TAB = 'All';
export default function CommentTabs(_a) {
    var activeTab = _a.activeTab, onAllClick = _a.onAllClick, onMarkAllClick = _a.onMarkAllClick, onUnreadClick = _a.onUnreadClick, searchQuery = _a.searchQuery, unreadCount = _a.unreadCount;
    var showMarkAllAsRead = !!unreadCount && !searchQuery;
    return (React.createElement(Flex, { alignItems: "center", justifyContent: "space-between", pl: 2, pr: 3 },
        React.createElement(Flex, { marginBottom: "-1px" },
            React.createElement(Tab, { "data-testid": "unread-comments-tab", isActive: activeTab === UNREAD_TAB, marginRight: 2, onClick: onUnreadClick },
                React.createElement(Flex, { alignItems: "center", height: 18 },
                    "Unread",
                    ' ',
                    !!unreadCount && (React.createElement(UnreadBubble, { marginLeft: 2, shouldPulse: false }, unreadCount)))),
            React.createElement(Tab, { "data-testid": "all-comments-tab", isActive: activeTab === ALL_TAB, onClick: onAllClick },
                React.createElement(Flex, { alignItems: "center", height: 18 }, "All"))),
        showMarkAllAsRead && (React.createElement(Link, { "data-testid": "mark-all-read", fontSize: 2, fontWeight: 500, kind: "marvel", onClick: onMarkAllClick }, "Mark all as read"))));
}
