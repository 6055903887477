import { __read } from "tslib";
import React, { useRef, useState } from 'react';
import { EmailAddressType, PlanLabel } from '@marvelapp/core';
import { useOnClickOutside } from '@marvelapp/hooks';
import { useCurrentUser } from '@marvelapp/marvel-3-application';
import { QUESTION_ICON } from '@marvelapp/theme';
import { Box, Icon, Link, Popover, PulsatingButton, Text } from '@marvelapp/ui';
import useIdle from '../../hooks/useIdle';
import * as zendesk from '../../zendesk';
import ItemArticle from '../ItemArticle';
import ItemVideo from '../ItemVideo';
var HelpButton = function (_a) {
    var items = _a.items, onClickItem = _a.onClickItem, onClick = _a.onClick, isDark = _a.isDark, canPulse = _a.canPulse;
    var _b = __read(useState(false), 2), isActive = _b[0], setIsActive = _b[1];
    var isIdle = useIdle();
    var ref = useRef();
    useOnClickOutside(ref, function () {
        setIsActive(false);
    });
    var triggerDriftPlaybook = function (playbookId) {
        if (window.drift) {
            window.drift.api.startInteraction({
                interactionId: playbookId,
                goToConversation: true,
            });
            setIsActive(false);
        }
    };
    var currentUser = useCurrentUser();
    var isCurrentUserPaid = currentUser.company.billing.plan.label !== PlanLabel.Free;
    var isCurrentUserCompanyEmail = currentUser.emailAddressType === EmailAddressType.COMPANY_EMAIL;
    var isSketch = currentUser.isSketch;
    var showChatButton = isCurrentUserCompanyEmail || isCurrentUserPaid || isSketch;
    return (React.createElement(Box, { bottom: 20, "data-testid": "floating-help-button", position: "fixed", ref: ref, right: 20, zIndex: "helpBubble" },
        React.createElement(PulsatingButton, { alignItems: "center", display: "flex", height: 30, justifyContent: "center", kind: isDark ? 'slate' : 'ghost', onClick: function () {
                setIsActive(!isActive);
                onClick();
            }, px: 0, shouldPulse: isIdle && canPulse, size: 1, tag: "div", width: 30 },
            React.createElement(Icon, { display: "block", height: 14, paths: QUESTION_ICON, width: 14 })),
        React.createElement(Popover, { align: "right", isActive: isActive, position: "top", showOn: "custom" },
            React.createElement(Box, { width: 280 },
                React.createElement(Text, { borderBottom: "1px solid", borderColor: "smoke", color: "licorice", fontSize: 2, fontWeight: 600, mx: 18, py: 14 }, "Help for this page"),
                React.createElement(Box, { maxHeight: 260, overflowY: "scroll", py: "6px" }, items.map(function (item) {
                    return item.type === 'video' ? (React.createElement(ItemVideo, { item: item, key: item.title, onClick: function () { return onClickItem(item); } })) : (React.createElement(ItemArticle, { item: item, key: item.title, onClick: function () { return onClickItem(item); } }));
                })),
                React.createElement(Box, { borderTop: "1px solid", 
                    // eslint-disable-next-line
                    borderColor: "smoke", mx: 18, py: 10 },
                    React.createElement(Link, { fontSize: 1, fontWeight: 500, href: zendesk.URL, kind: "marvel", target: "_blank" }, "Visit help center")),
                window.drift && showChatButton && (React.createElement(Box, { borderTop: "1px solid", 
                    // eslint-disable-next-line
                    borderColor: "smoke", mx: 18, py: 10 },
                    React.createElement(Link, { fontSize: 1, fontWeight: 500, kind: "marvel", onClick: function () {
                            triggerDriftPlaybook(264648);
                        }, target: "_blank" }, "Start Chat")))))));
};
export default HelpButton;
