var pluralSuffixMap = {
    y: { matchSuffix: 'y', replaceWith: 'ies' },
    ay: { matchSuffix: 'ay', replaceWith: 'ays' },
    s: { matchSuffix: 's', replaceWith: 'ses' },
    ch: { matchSuffix: 'ch', replaceWith: 'ches' },
    sh: { matchSuffix: 'sh', replaceWith: 'shes' },
};
export var pluralize = function (count, noun) {
    if (count === 1) {
        return noun;
    }
    // We try and get the correct plural suffix by the last 2 chars first.
    var lastChar = noun.slice(-1);
    var last2Chars = noun.slice(-2);
    var replaceData = pluralSuffixMap[last2Chars] || pluralSuffixMap[lastChar];
    if (replaceData) {
        var regExp = new RegExp("".concat(replaceData.matchSuffix, "$"));
        var pluralized = noun.replace(regExp, replaceData.replaceWith);
        return pluralized;
    }
    return "".concat(noun, "s");
};
