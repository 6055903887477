import { __assign, __rest } from "tslib";
import React from 'react';
import { Svg } from './styles';
/** @param {any} */
function Icon(_a) {
    var paths = _a.paths, children = _a.children, props = __rest(_a, ["paths", "children"]);
    return (React.createElement(Svg, __assign({}, props), paths ? paths.map(function (path) { return React.createElement("path", { d: path, key: path }); }) : children));
}
/** @type {any} */
Icon.defaultProps = {
    fill: 'currentColor',
    height: 24,
    viewBox: '0 0 24 24',
    width: 24,
};
export default Icon;
