import React from 'react';
import { Box, Button, Flex, Heading, Image, Text } from '@marvelapp/ui';
import emptyState from './images/emptyState.png';
import emptyStateRetina from './images/emptyState@2x.png';
export default function CommentEmptyState(_a) {
    var showAll = _a.showAll;
    return (React.createElement(Flex, { alignItems: "center", height: "100%", justifyContent: "center" },
        React.createElement(Box, null,
            React.createElement(Image, null),
            React.createElement(Box, { textAlign: "center" },
                React.createElement(Image, { alt: "No notifications yet", maxWidth: 320, src: emptyState, src2x: emptyStateRetina }),
                React.createElement(Heading, { fontSize: 4, marginTop: 3 }, "No notifications yet"),
                React.createElement(Text, { fontSize: 3, marginTop: 1 }, "Nothing to read for now. Take a break!"),
                showAll && (React.createElement(Button, { "data-testid": "view-all-comments", kind: "ghost", mt: 3, onClick: showAll }, "View all comments"))))));
}
