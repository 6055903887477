import { __assign, __rest } from "tslib";
import React from 'react';
import Flex from '../Flex';
import Logo from '../Logo';
import Text from '../Text';
function LogoText(_a) {
    var text = _a.text, props = __rest(_a, ["text"]);
    return (React.createElement(Flex, __assign({ alignItems: "center" }, props),
        React.createElement(Logo, { color: "marvel", display: "block", hasTransition: false, height: 25, mr: "3px", width: 60 }),
        React.createElement(Text, { color: "smokeExtraDark", fontSize: 0, fontWeight: "500", letterSpacing: 2, mb: 1, textTransform: "uppercase" }, text)));
}
export default LogoText;
