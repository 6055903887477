import { __assign, __rest } from "tslib";
import React from 'react';
import { zendesk } from '@marvelapp/help';
import { Button, Image, Link, TinyUpseller } from '@marvelapp/ui';
import image from './image.jpg';
import image2x from './image@2x.jpg';
function TUCreateFirstPrototype(_a) {
    var onClickClose = _a.onClickClose, clickedCTA = _a.clickedCTA, rest = __rest(_a, ["onClickClose", "clickedCTA"]);
    return (React.createElement(TinyUpseller, __assign({ callToAction: React.createElement(Link, { href: zendesk.CREATE_FIRST_PROTOTYPE, onClick: clickedCTA, target: "_blank" },
            React.createElement(Button, { kind: "withShadow" }, "Get started")), image: React.createElement(Image, { alt: "Learn creating your first prototype", display: "block", src: image, src2x: image2x, width: "100%" }), onClickClose: onClickClose, title: "Learn how to create your first prototype" }, rest)));
}
export default TUCreateFirstPrototype;
