import { userSelectors } from '@marvelapp/marvel-3-application';
import * as wistia from './wistia';
import * as zendesk from './zendesk';
export function createArticlesAndVideos(props) {
    var user = props.user;
    var prototypeProjectCount = userSelectors.prototypeProjectCount(user);
    var prototypeProjectsOwnedCount = userSelectors.prototypeProjectCount(user);
    var screensOwnedCount = userSelectors.screensOwnedCount(user);
    var hotspotsOwnedCount = userSelectors.hotspotsOwnedCount(user);
    var hasHitPrototypeLimit = userSelectors.hasHitPrototypeLimit(user);
    var hasHitUserTestLimit = userSelectors.hasHitUserTestLimit(user);
    var userTestProjectsOwnedCount = userSelectors.userTestProjectsOwnedCount(user);
    return [
        {
            type: 'video',
            title: 'How to create Groups',
            link: wistia.CREATE_GROUPS,
            recommended: false,
            urls: ['/company/groups'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to use comments and annotations',
            link: wistia.COMMENTS_ANNOTATIONS,
            recommended: false,
            urls: ['/project/:id'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'Creating your first prototype',
            link: wistia.CREATE_FIRST_PROTOTYPE,
            recommended: screensOwnedCount < 2 || hotspotsOwnedCount === 0,
            urls: ['/projects/*', '/project/:id'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to design and wireframe in Marvel',
            link: wistia.DESIGN_WIREFRAME,
            recommended: screensOwnedCount === 0,
            urls: ['/project/:id'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to use Sections',
            link: wistia.SECTIONS,
            recommended: false,
            urls: ['/project/:id'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to make a copy of your designs',
            link: wistia.COPY_SCREENS,
            recommended: false,
            urls: ['/project/:id'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'Download your prototype as a PDF',
            link: wistia.DOWNLOAD_PDF,
            recommended: false,
            urls: ['/project/:id', '/project/:id/download'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to add Groups to projects and folders',
            link: wistia.ADD_GROUPS_TO_PROJECTS_AND_FOLDERS,
            recommended: false,
            urls: ['/project/:id'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to invite collaborators to projects',
            link: wistia.INVITE_COLLABS,
            recommended: false,
            urls: ['/project/:id'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to embed prototypes in Wordpress (or any website)',
            link: wistia.HOW_TO_EMBED_PROTOTYPES,
            recommended: false,
            urls: ['/project/:id'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to download your prototype offline (ZIP)',
            link: wistia.HOW_TO_DOWNLOAD_YOUR_PROTOTYPE_OFFLINE,
            recommended: false,
            urls: ['/project/:id', '/project/:id/download'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to link your designs using hotspots',
            link: wistia.HOW_TO_LINK_YOUR_DESIGNS_USING_HOTSPOTS,
            recommended: hotspotsOwnedCount === 0,
            urls: ['/project/*/screen/*'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to use Anchors',
            link: wistia.HOW_TO_USE_ANCHORS,
            recommended: false,
            urls: ['/project/*/screen/*'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to use Video Embeds',
            link: wistia.HOW_TO_USE_VIDEO_EMBEDS,
            recommended: false,
            urls: ['/project/*/screen/*'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to link to External URLs',
            link: wistia.HOW_TO_LINK_TO_EXTERNAL_URLS,
            recommended: false,
            urls: ['/project/*/screen/*'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to use keep Scroll Position',
            link: wistia.HOW_TO_USE_KEEP_SCROLL_POSITION,
            recommended: false,
            urls: ['/project/*/screen/*'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'How to create goal-based user tests',
            link: wistia.HOW_TO_CREATE_GOAL_BASE_USER_TESTS,
            recommended: prototypeProjectsOwnedCount > 0 &&
                userTestProjectsOwnedCount === 0 &&
                !hasHitUserTestLimit,
            urls: ['/projects/*', '/user-test/*'],
            autoShow: false,
        },
        {
            type: 'video',
            title: 'Sending user tests to iPhone and iPad devices',
            link: wistia.SENDING_USER_TEST_TO_IPHONE_AND_IPAD_DEVICES,
            recommended: false,
            urls: ['/user-test/*'],
            autoShow: false,
        },
        {
            type: 'article',
            title: 'How to invite users to your team',
            link: zendesk.HOW_TO_INVITE_USERS,
            recommended: false,
            urls: ['/company/people/*'],
        },
        {
            type: 'article',
            title: 'What is the difference between team members and guests?',
            link: zendesk.MANAGING_MEMBERS_AND_GUESTS,
            recommended: false,
            urls: ['/company/people/*'],
        },
        {
            type: 'article',
            title: 'How to switch team members to guests and vice versa',
            link: zendesk.SWITCH_MEMBERS_GUESTS,
            recommended: false,
            urls: ['/company/people/*'],
        },
        {
            type: 'article',
            title: 'How to change a team member’s permission',
            link: zendesk.CHANGE_TEAM_MEMBERS_PERMISSION,
            recommended: false,
            urls: ['/company/people/*'],
        },
        {
            type: 'article',
            title: 'How to remove team members and guests',
            link: zendesk.REMOVE_MEMBERS,
            recommended: false,
            urls: ['/company/people/*'],
        },
        {
            type: 'article',
            title: 'How do I switch owners for my account?',
            link: zendesk.SWITCH_OWNERS,
            recommended: false,
            urls: ['/company/people/*'],
        },
        {
            type: 'article',
            title: 'How to use Groups',
            link: zendesk.GROUPS,
            recommended: false,
            urls: ['/company/groups'],
        },
        {
            type: 'article',
            title: 'How to require users to log in to view prototypes',
            link: zendesk.SIGNIN_PROTECTED_PROTOTYPES,
            recommended: false,
            urls: ['/company/settings'],
        },
        {
            type: 'article',
            title: 'Allow Team Members to invite other members to your workspace',
            link: zendesk.TEAM_MEMBERS_INVITING,
            recommended: false,
            urls: ['/company/settings'],
        },
        {
            type: 'article',
            title: 'How to change your team name',
            link: zendesk.HOW_TO_CHANGE_TEAM_NAME,
            recommended: false,
            urls: ['/company/settings'],
        },
        {
            type: 'article',
            title: 'How to change your team icon',
            link: zendesk.HOW_TO_CHANGE_TEAM_ICON,
            recommended: false,
            urls: ['/company/settings'],
        },
        {
            type: 'article',
            title: 'How to change your sub-domain',
            link: zendesk.SUBDOMAINS,
            recommended: false,
            urls: ['/company/settings'],
        },
        {
            type: 'article',
            title: 'How to play your prototype in desktop, tablet and mobile browsers',
            link: zendesk.HOW_PLAY_PROTOTYPE,
            recommended: false,
            urls: ['/project/:id'],
        },
        {
            type: 'article',
            title: 'A guide to creating your first prototype in Marvel',
            link: zendesk.CREATE_FIRST_PROTOTYPE,
            recommended: (prototypeProjectsOwnedCount === 0 && !hasHitPrototypeLimit) ||
                (prototypeProjectCount === 0 && !hasHitPrototypeLimit) ||
                screensOwnedCount === 0,
            urls: ['/project/:id', '/projects/*'],
        },
        {
            type: 'article',
            title: 'How to archive and unarchive your projects',
            link: zendesk.ARCHIVING_PROJECTS,
            recommended: false,
            urls: ['/projects/*', '/archive/*'],
        },
        {
            type: 'article',
            title: 'How to copy projects',
            link: zendesk.COPY_PROJECTS,
            recommended: false,
            urls: ['/projects/*'],
        },
        {
            type: 'article',
            title: 'How do I delete a project?',
            link: zendesk.DELETE_PROJECTS,
            recommended: false,
            urls: ['/projects/*'],
        },
        {
            type: 'article',
            title: 'How to rename a project or folder',
            link: zendesk.RENAME_PROJECTS,
            recommended: false,
            urls: ['/projects/*'],
        },
        {
            type: 'video',
            title: 'A guide to using Folders',
            link: wistia.HOW_TO_USE_FOLDERS,
            recommended: false,
            urls: ['/folders/*', '/folder/:id'],
            autoShow: false,
        },
        {
            type: 'article',
            title: 'A guide to using Folders',
            link: zendesk.USING_FOLDERS,
            recommended: false,
            urls: ['/folders/*', '/folder/:id'],
        },
        {
            type: 'article',
            title: 'How to share a link to a folder',
            link: zendesk.SHARE_FOLDER,
            recommended: false,
            urls: ['/folder/:id/share'],
        },
        {
            type: 'article',
            title: 'How to add and remove projects in a folder',
            link: zendesk.ADD_REMOVE_FOLDER_PROJECTS,
            recommended: false,
            urls: ['/folder/:id'],
        },
        {
            type: 'article',
            title: 'How to add a description to a folder',
            link: zendesk.FOLDER_DESCRIPTION,
            recommended: false,
            urls: ['/folder/:id'],
        },
        {
            type: 'article',
            title: 'How do I sort the projects in a folder?',
            link: zendesk.SORT_FOLDER_PROJECTS,
            recommended: false,
            urls: ['/folder/:id'],
        },
        {
            type: 'article',
            title: 'How to change the prototype device to website, iPhone, Apple Watch, iPad or Android',
            link: zendesk.CHANGE_DEVICE,
            recommended: false,
            urls: ['/project/:id/settings'],
        },
        {
            type: 'article',
            title: 'How to change the background colour of your prototypes to match your designs',
            link: zendesk.CHANGE_BACKGROUND,
            recommended: false,
            urls: ['/project/:id/settings'],
        },
        {
            type: 'article',
            title: 'How to disable resizing designs when the browser changes size',
            link: zendesk.DISABLE_RESIZING,
            recommended: false,
            urls: ['/project/:id/settings'],
        },
        {
            type: 'article',
            title: 'How to hide hotspot hints when playing prototypes',
            link: zendesk.HIDE_HOTSPOT_HINTS,
            recommended: false,
            urls: ['/project/:id/settings'],
        },
        {
            type: 'article',
            title: 'How to scale down retina images for non-retina desktop devices',
            link: zendesk.HIDE_RETINA,
            recommended: false,
            urls: ['/project/:id/settings'],
        },
        {
            type: 'article',
            title: 'How to share the link to your prototype via SMS or Email',
            link: zendesk.SHARE_PROTOTYPE,
            recommended: false,
            urls: ['/project/:id/share'],
        },
        {
            type: 'article',
            title: 'How to add collaborators to your prototype',
            link: zendesk.ADD_COLLABORATORS,
            recommended: false,
            urls: ['/project/:id/share'],
        },
        {
            type: 'article',
            title: 'How to make projects or folders invite-only',
            link: zendesk.INVITE_ONLY,
            recommended: false,
            urls: ['/project/:id/share'],
        },
        {
            type: 'article',
            title: 'How to password protect projects and folders',
            link: zendesk.PASSWORD_PROTECT,
            recommended: false,
            urls: ['/project/:id/share'],
        },
    ];
}
