import { __read } from "tslib";
import { differenceInCalendarDays, parseISO } from 'date-fns/esm';
import { partition, range } from 'lodash-es';
import React from 'react';
import { PaginatedList } from '@marvelapp/ui-internal';
import CommentMessage from '../CommentMessage';
import CommentsDate from '../CommentsDate';
function filterCommentsByDate(commentList) {
    var _a = __read(partition(commentList, function (_a) {
        var comment = _a.node;
        var commentAgeInDays = differenceInCalendarDays(new Date(), parseISO(comment.createdAt));
        return commentAgeInDays >= 7;
    }), 2), olderComments = _a[0], recentComments = _a[1];
    return { recentComments: recentComments, olderComments: olderComments };
}
export default function CommentList(_a) {
    var container = _a.container, data = _a.data, error = _a.error, fetchMore = _a.fetchMore, loading = _a.loading, projectPk = _a.projectPk, emptyState = _a.emptyState, searchQuery = _a.searchQuery;
    return (React.createElement(PaginatedList, { containerEl: container.current, data: data, error: error, fetchMore: fetchMore, getDataConnection: function (project) { return project === null || project === void 0 ? void 0 : project.comments; }, isEmpty: function (project) { return !(project === null || project === void 0 ? void 0 : project.comments); }, loading: loading, renderInitialLoadingState: function () { return React.createElement(Loader, null); }, renderList: function (_a) {
            var edges = _a.edges;
            var _b = filterCommentsByDate(edges), recentComments = _b.recentComments, olderComments = _b.olderComments;
            return (React.createElement(React.Fragment, null,
                recentComments &&
                    recentComments.map(function (_a) {
                        var comment = _a.node;
                        return (React.createElement("div", { "data-testid": "recent-comment", key: comment.pk },
                            React.createElement(Comment, { comment: comment, projectPk: projectPk, searchQuery: searchQuery })));
                    }),
                olderComments.length > 0 && (React.createElement(React.Fragment, null,
                    React.createElement(CommentsDate, null, "Older than a week"),
                    olderComments.map(function (_a) {
                        var comment = _a.node;
                        return (React.createElement("div", { "data-testid": "older-comment", key: comment.pk },
                            React.createElement(Comment, { comment: comment, projectPk: projectPk, searchQuery: searchQuery })));
                    }))),
                loading && React.createElement(Loader, null)));
        }, 
        // When switching to the 'All' tab data.comments is an empty list _even on
        // the initial load_ (is this because there's already a 'comments' key in
        // the cache from loading unread comments?) This breaks an assumption of
        // PaginatedList, which assumes data is always empty on initial load, and
        // the result is the initial loading state is never shown. Instead the
        // empty state shows until comments load in.
        //
        // We work around this here by showing the loading state for the empty
        // state if we are still loading data.
        renderNoItems: function () { return (loading ? React.createElement(Loader, null) : emptyState); } }));
}
function Comment(_a) {
    var projectPk = _a.projectPk, comment = _a.comment, searchQuery = _a.searchQuery;
    return (React.createElement(CommentMessage, { avatar: comment.author.avatarUrl, commentUrl: comment.url, createdAt: comment.createdAt, isPrivate: comment.private, isResolved: comment.status.resolved, isUnread: comment.unread, message: comment.message, projectName: comment.project.name, projectPk: projectPk, screenName: comment.screen.displayName, searchQuery: searchQuery, username: comment.author.username }));
}
function Loader() {
    return range(8).map(function (i) { return React.createElement(CommentMessage, { isLoading: true, key: i }); });
}
