export var BAR_HEIGHT = 50;
export var DASHBOARD_CARD_GUTTER = 4 * 8;
export var DASHBOARD_CARD_WIDTH = 26 * 8;
export var SIDEBAR_WIDTH = 23 * 8;
export var PATTERNS = {
    BIG_BLOBS: [
        'M78.82,302.25A107.83,107.83,0,0,0,0,198.53v57.6a54.42,54.42,0,0,1,25.58,46.12A54.41,54.41,0,0,1,0,348.36V406A107.83,107.83,0,0,0,78.82,302.25Z',
        'M158.05,943.4C145.27,929,127.64,918,108.71,923c-21.91,5.76-31.28,29.64-33.65,51.42H179.4c-2.1-3.39-4.35-6.61-5.57-8.74A133.34,133.34,0,0,0,158.05,943.4Z',
        'M0,685.74v57.6a54.42,54.42,0,0,1,25.58,46.12A54.41,54.41,0,0,1,0,835.57v57.6A107.83,107.83,0,0,0,78.82,789.46,107.83,107.83,0,0,0,0,685.74Z',
        'M345.63,903.14c-17.51-16.65-39.82-18.81-56.11-5-19.67,16.66-25.36,42.47-25.36,42.47-8-13.2-21.59-36.47-37.68-40-14.77-3.27-25.61,5.49-32.45,16-9.11,14-10.42,38.61-10.08,57.89h183c3-2.49,4.58-3.86,4.58-3.86C368.78,946.73,365.11,921.66,345.63,903.14Z',
        'M124.6,182.42C176.23,159.56,337.65,24.63,366.91,0H184c.07,4,.21,7.83.37,11.25-.14-3-2.43-7.23-4.92-11.25H75.06a110.64,110.64,0,0,0-.63,11.44c0,25.2,7.1,50.22,12.55,75C95.75,126.33,124.6,182.42,124.6,182.42Z',
        'M991.07,182.42c51.63-22.86,213-157.79,242.31-182.42h-183c.07,4,.22,7.83.37,11.25-.13-3-2.42-7.23-4.92-11.25H941.53a107.92,107.92,0,0,0-.63,11.44c0,25.2,7.1,50.22,12.55,75C962.22,126.33,991.07,182.42,991.07,182.42Z',
        'M173.83,478.47a133.34,133.34,0,0,0-15.78-22.28c-12.78-14.38-30.41-25.38-49.34-20.4-21.91,5.76-31.28,29.64-33.65,51.42a110.64,110.64,0,0,0-.63,11.44c0,25.2,7.1,50.22,12.55,75,8.77,39.88,37.62,96,37.62,96,51.63-22.86,213.05-157.79,242.31-182.42l4.58-3.86c-2.71-23.83-6.38-48.9-25.86-67.42-17.51-16.65-39.82-18.81-56.11-5-19.67,16.66-25.36,42.47-25.36,42.47-8-13.2-21.59-36.48-37.68-40-14.77-3.27-25.61,5.49-32.45,16-9.11,13.95-10.42,38.61-10.08,57.89.07,4,.21,7.83.37,11.25-.14-3-2.43-7.23-4.92-11.25C177.3,483.82,175.05,480.6,173.83,478.47Z',
        'M1040.3,478.47a133.92,133.92,0,0,0-15.78-22.28c-12.78-14.38-30.41-25.38-49.35-20.4-21.9,5.76-31.27,29.64-33.64,51.42a105.33,105.33,0,0,0-.63,11.44c0,25.2,7.1,50.22,12.55,75,8.77,39.88,37.62,96,37.62,96,51.63-22.86,213-157.79,242.31-182.42l4.58-3.86c-2.71-23.83-6.38-48.9-25.86-67.42-17.51-16.65-39.82-18.81-56.11-5-19.67,16.66-25.36,42.47-25.36,42.47-8-13.2-21.59-36.48-37.68-40-14.77-3.27-25.61,5.49-32.46,16-9.1,13.95-10.41,38.61-10.08,57.89.07,4,.22,7.83.37,11.25-.13-3-2.42-7.23-4.92-11.25C1043.77,483.82,1041.52,480.6,1040.3,478.47Z',
        'M1152.28,183.85a31.91,31.91,0,0,0,33.88,48.26l38.28-8.53,1,32.58a37.38,37.38,0,0,0,45.51,35.36l31.81-7.09,1,32.57a37.4,37.4,0,0,0,37.35,36.27h0a37.35,37.35,0,0,0,8.15-.9l69.49-15.5a31.91,31.91,0,0,0-13.9-62.29l-38.27,8.53-1-32.57a37.39,37.39,0,0,0-45.51-35.37l-31.81,7.1-1-32.58a37.38,37.38,0,0,0-45.5-35.37l-69.5,15.5A31.66,31.66,0,0,0,1152.28,183.85Z',
        'M562.42,766a31.64,31.64,0,0,0-24.06-4.21l-38.27,8.53-1-32.57a37.39,37.39,0,0,0-45.51-35.37l-31.81,7.1-1-32.58a37.39,37.39,0,0,0-45.51-35.37L305.8,657a31.91,31.91,0,1,0,13.89,62.29L358,710.79l1,32.58a37.38,37.38,0,0,0,45.51,35.36l31.81-7.09,1,32.57a37.4,37.4,0,0,0,37.35,36.27h0a37.26,37.26,0,0,0,8.14-.9l69.49-15.5A31.91,31.91,0,0,0,562.42,766Z',
        'M285.81,183.85a31.91,31.91,0,0,0,33.88,48.26L358,223.58l1,32.58a37.38,37.38,0,0,0,45.51,35.36l31.81-7.09,1,32.57a37.4,37.4,0,0,0,37.35,36.27h0a37.26,37.26,0,0,0,8.14-.9l69.49-15.5a31.91,31.91,0,0,0-13.9-62.29l-38.27,8.53-1-32.57a37.39,37.39,0,0,0-45.51-35.37l-31.81,7.1-1-32.58a37.39,37.39,0,0,0-45.51-35.37l-69.5,15.5A31.68,31.68,0,0,0,285.81,183.85Z',
        'M837.31,409.89a107.17,107.17,0,0,0,29.16-4V406a107.65,107.65,0,0,0,0-207.43v.09a107.65,107.65,0,1,0-29.16,211.27Zm0-162a54.12,54.12,0,0,1,29.16,8.49v-.21a54.37,54.37,0,0,1,0,92.23v-.21a54.39,54.39,0,1,1-29.16-100.3Z',
        'M654.7,868.84a32.59,32.59,0,0,0-42.34,18.26c-.59,1.48-14.6,36.76-28.37,72.79-2,5.15-3.8,10-5.52,14.53h69.74c7.53-19.62,16-41.23,24.73-63.25a32.61,32.61,0,0,0-18.24-42.33Z',
        'M1428.89,766a31.64,31.64,0,0,0-24.06-4.21l-38.27,8.53-1-32.57a37.39,37.39,0,0,0-45.51-35.37l-31.81,7.1-1-32.58a37.38,37.38,0,0,0-45.5-35.37l-69.5,15.5a31.91,31.91,0,1,0,13.89,62.29l38.28-8.53,1,32.58a37.38,37.38,0,0,0,45.51,35.36l31.81-7.09,1,32.57a37.4,37.4,0,0,0,37.35,36.27h0a37.35,37.35,0,0,0,8.15-.9l69.49-15.5A31.91,31.91,0,0,0,1428.89,766Z',
        'M1521.17,868.84a32.59,32.59,0,0,0-42.34,18.26c-.59,1.48-14.6,36.76-28.37,72.79-2,5.15-3.8,10-5.52,14.53h69.74c7.53-19.62,16-41.23,24.72-63.25a32.59,32.59,0,0,0-18.23-42.33Z',
        'M1549,571.91c-.17-.06-17.3-5.17-35.71-11.67-9.78-3.45-17.09-6.29-22.53-8.56,5.35-15.28,13.82-38.06,24-64.47,7.52-19.62,16-41.23,24.72-63.25a32.59,32.59,0,1,0-60.57-24.07c-.59,1.48-14.6,36.76-28.37,72.79-2,5.15-3.8,10-5.52,14.53-24.84,65.76-24.84,71.61-24.84,78.32,0,7,2.08,17.39,12,26.94,6.1,5.9,13.71,10.07,19.66,13A334.24,334.24,0,0,0,1485,619.36c22.37,8.17,44.45,14.75,45.38,15a32.53,32.53,0,0,0,9.29,1.35h0A32.78,32.78,0,0,0,1559,629.4a32.26,32.26,0,0,0,11.94-17A32.62,32.62,0,0,0,1549,571.91Z',
        'M1596.14,789.46a107.66,107.66,0,0,0,136.8,103.62V835.36a54.4,54.4,0,1,1,0-91.81V685.83a107.66,107.66,0,0,0-136.8,103.63Z',
        'M1703.78,194.6a107.65,107.65,0,1,0,29.16,211.27V348.15a54.4,54.4,0,1,1,0-91.81V198.62A107.17,107.17,0,0,0,1703.78,194.6Z',
        'M1549,84.7c-.17-.06-17.3-5.17-35.71-11.67-9.78-3.45-17.09-6.29-22.53-8.56,5.35-15.28,13.82-38.06,24-64.47h-69.74c-24.84,65.76-24.84,71.61-24.84,78.32,0,7,2.08,17.39,12,26.94,6.1,5.9,13.71,10.07,19.66,13A334.24,334.24,0,0,0,1485,132.15c22.37,8.17,44.45,14.75,45.38,15a32.53,32.53,0,0,0,9.29,1.35h0a32.78,32.78,0,0,0,19.29-6.34,32.26,32.26,0,0,0,11.94-17A32.62,32.62,0,0,0,1549,84.7Z',
        'M682.51,571.91c-.18-.06-17.31-5.17-35.72-11.67-9.78-3.45-17.09-6.29-22.52-8.56,5.34-15.28,13.81-38.06,23.94-64.47,7.53-19.62,16-41.23,24.73-63.25a32.59,32.59,0,1,0-60.58-24.07c-.59,1.48-14.6,36.76-28.37,72.79-2,5.15-3.8,10-5.51,14.53-24.84,65.76-24.84,71.61-24.84,78.32,0,7,2.07,17.39,12,26.94,6.09,5.9,13.7,10.07,19.65,13a334.24,334.24,0,0,0,33.31,13.84c22.37,8.17,44.45,14.75,45.38,15a32.58,32.58,0,0,0,9.29,1.35h0a32.78,32.78,0,0,0,19.29-6.34,32.26,32.26,0,0,0,11.94-17A32.63,32.63,0,0,0,682.51,571.91Z',
        'M682.51,84.7c-.18-.06-17.31-5.17-35.72-11.67-9.78-3.45-17.09-6.29-22.52-8.56C629.61,49.19,638.08,26.41,648.21,0H578.48c-24.84,65.76-24.84,71.61-24.84,78.32,0,7,2.07,17.39,12,26.94,6.09,5.9,13.7,10.07,19.65,13a334.24,334.24,0,0,0,33.31,13.84c22.37,8.17,44.45,14.75,45.38,15a32.58,32.58,0,0,0,9.29,1.35h0a32.78,32.78,0,0,0,19.29-6.34,32.26,32.26,0,0,0,11.94-17A32.63,32.63,0,0,0,682.51,84.7Z',
        'M1024.52,943.4C1011.74,929,994.11,918,975.17,923c-21.9,5.76-31.27,29.64-33.64,51.42h104.33c-2.09-3.39-4.34-6.61-5.56-8.74A133.34,133.34,0,0,0,1024.52,943.4Z',
        'M1212.1,903.14c-17.51-16.65-39.82-18.81-56.11-5-19.67,16.66-25.36,42.47-25.36,42.47-8-13.2-21.59-36.47-37.68-40-14.77-3.27-25.61,5.49-32.46,16-9.1,14-10.41,38.61-10.08,57.89h183l4.58-3.86C1235.25,946.73,1231.58,921.66,1212.1,903.14Z',
        'M729.67,789.46a107.66,107.66,0,0,0,136.8,103.62V835.36a54.4,54.4,0,1,1,0-91.81V685.83a107.66,107.66,0,0,0-136.8,103.63Z',
        'M866.47,685.74v57.6a54.37,54.37,0,0,1,0,92.23v57.6a107.65,107.65,0,0,0,0-207.43Z',
    ],
};
