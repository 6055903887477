import { __extends } from "tslib";
import React from 'react';
import ReactDOM from 'react-dom';
var MODAL_ID = 'root-modal';
var ModalPortal = /** @class */ (function (_super) {
    __extends(ModalPortal, _super);
    function ModalPortal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ModalPortal.prototype.componentDidMount = function () {
        this.portalDestinationElem = document.getElementById(MODAL_ID);
    };
    ModalPortal.prototype.render = function () {
        return this.portalDestinationElem
            ? ReactDOM.createPortal(this.props.children, this.portalDestinationElem)
            : null;
    };
    return ModalPortal;
}(React.Component));
export default ModalPortal;
export function ModalPortalDestination() {
    return React.createElement("div", { id: MODAL_ID });
}
