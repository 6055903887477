import React from 'react';
import { canBeShownAgain } from '../storage';
import { trackUpsellAppearance, trackUpsellCTA, trackUpsellClosing, } from '../tracking';
function getCurrrentUpsellForPosition(_a) {
    var position = _a.position, upsells = _a.upsells, closed = _a.closed;
    var upsell = upsells.find(function (item) {
        var condition = item.condition, identifier = item.identifier, isClosable = item.isClosable;
        // None closable upsells should always be shown as they are important
        if (!isClosable) {
            return condition;
        }
        // Ensures that in the position it only shows 1 banner every 24h
        var canShowAgain = canBeShownAgain({
            identifier: identifier,
            closed: closed,
            position: position,
        });
        return condition && canShowAgain;
    });
    return upsell;
}
function UpsellRenderer(_a) {
    var upsells = _a.upsells, stunning = _a.stunning, closed = _a.closed, position = _a.position, onClickClose = _a.onClickClose, userInfo = _a.userInfo;
    if (!upsells)
        return null;
    var upsell = getCurrrentUpsellForPosition({
        position: position,
        upsells: upsells,
        stunning: stunning,
        closed: closed,
    });
    if (!upsell)
        return null;
    // Found a upsell matching conditions to show
    var identifier = upsell.identifier, daysUntilReshownAfterClose = upsell.daysUntilReshownAfterClose;
    trackUpsellAppearance({ identifier: identifier, position: position });
    var clickedClose = function () {
        trackUpsellClosing({ identifier: identifier, position: position });
        onClickClose({ identifier: identifier, position: position, daysUntilReshownAfterClose: daysUntilReshownAfterClose });
    };
    var clickedCTA = function () {
        trackUpsellCTA({ identifier: identifier, position: position });
    };
    var UpsellComponent = upsell.component;
    return (React.createElement(UpsellComponent, { clickedCTA: clickedCTA, "data-testid": identifier, identifier: identifier, onClickClose: upsell.isClosable ? clickedClose : null, stunning: stunning, userInfo: userInfo }));
}
export default UpsellRenderer;
