import { __assign, __makeTemplateObject, __read, __rest } from "tslib";
import { themeGet } from '@styled-system/theme-get';
import emoji from 'node-emoji';
import React, { useState } from 'react';
import { useFeatureFlag } from '@marvelapp/marvel-3-application';
import styled, { css } from '@marvelapp/styled';
import { COMMENT_MORE_ICON, COMMENT_REACT_ICON, COMMENT_REPLY_ICON, LOCK_ICON, } from '@marvelapp/theme';
import { Avatar, Badge, Box, Button, ContentLoader, Flex, Heading, Icon, PinBL, Text, Textarea, Tooltip, } from '@marvelapp/ui';
import { Highlighter, Link } from '@marvelapp/ui-internal';
import CommentFooter from './CommentFooter';
import LiveDate from './components';
export default function CommentMessage(_a) {
    var avatar = _a.avatar, commentUrl = _a.commentUrl, createdAt = _a.createdAt, isLoading = _a.isLoading, isPrivate = _a.isPrivate, isResolved = _a.isResolved, isUnread = _a.isUnread, message = _a.message, projectName = _a.projectName, projectPk = _a.projectPk, screenName = _a.screenName, searchQuery = _a.searchQuery, username = _a.username;
    var _b = __read(useState(false), 2), isReplyOpen = _b[0], setIsReplyOpen = _b[1];
    var toggleReply = function () {
        setIsReplyOpen(!isReplyOpen);
    };
    var hasStaticUi = useFeatureFlag('marvel-3-static-ui');
    if (isLoading) {
        return React.createElement(CommentLoader, null);
    }
    return (React.createElement(Comment, { bg: isUnread ? 'snow' : 'white' },
        React.createElement(Link, { "data-testid": "comment-component", display: "flex", to: getLink(commentUrl) },
            React.createElement(Box, { marginRight: 3 },
                React.createElement(Avatar, { size: 32, src: avatar })),
            React.createElement(Box, { borderBottom: "1px solid", borderColor: "smoke", flex: 1, maxWidth: "calc(100%)", paddingBottom: 3, paddingRight: 4 },
                React.createElement(Flex, { alignItems: "center", justifyContent: "space-between", marginBottom: 1, width: 1 },
                    React.createElement(Flex, { alignItems: "center" },
                        React.createElement(Username, null, username),
                        " ",
                        React.createElement(LiveDate, { date: createdAt })),
                    isResolved && (React.createElement(Badge, { bg: "white", fontSize: 10, kind: "ghost", paddingTop: "1px" }, "Resolved"))),
                React.createElement(Text, { "data-testid": "comment-message", fontSize: 2, marginBottom: 2, wrap: true },
                    React.createElement(Highlighter, { searchWords: [searchQuery], testId: "title", textToHighlight: emoji.emojify(message) })),
                React.createElement(CommentFooter, { commentUrl: commentUrl, isUnread: isUnread, projectName: projectName, projectPk: projectPk, screenName: screenName }),
                hasStaticUi && isReplyOpen && (React.createElement(Textarea, { bg: "white", marginTop: 3, maxHeight: 120, minHeight: 60, placeholder: "Quick reply...", py: 12, resize: "vertical", width: 1 })))),
        isPrivate && (React.createElement(PinBL, { padding: "17px", zIndex: 0 },
            React.createElement(Tooltip, { content: "Private comment", position: "topLeft" },
                React.createElement(Box, { paddingBottom: "1px", paddingLeft: "3px" },
                    React.createElement(Icon, { "data-testid": "private-comment-icon", display: "block", height: 16, paths: LOCK_ICON, width: 16 }))))),
        hasStaticUi && (React.createElement(ReplyBox, null,
            React.createElement(Flex, { bg: "white", borderRadius: 2, boxShadow: "button.ghost", px: "4px", py: "3px" },
                React.createElement(IconButton, null,
                    React.createElement(SmallIcon, { paths: COMMENT_REACT_ICON })),
                React.createElement(IconButton, { onClick: toggleReply },
                    React.createElement(SmallIcon, { paths: COMMENT_REPLY_ICON })),
                React.createElement(IconButton, null,
                    React.createElement(SmallIcon, { paths: COMMENT_MORE_ICON })))))));
}
function CommentLoader() {
    return (React.createElement(Comment, { bg: "snow", isLoading: true },
        React.createElement(Flex, null,
            React.createElement(Box, { marginRight: 3 },
                React.createElement(ContentLoader, { borderRadius: "50%", height: 32, kind: "medium", width: 32 })),
            React.createElement(Box, { borderBottom: "1px solid", borderColor: "smoke", flex: 1, py: 2 },
                React.createElement(ContentLoader, { borderRadius: 4, height: 8, kind: "medium", width: "30%" }),
                React.createElement(Box, { my: 3 },
                    React.createElement(ContentLoader, { borderRadius: 4, height: 8, kind: "medium", marginBottom: 2, width: "80%" }),
                    React.createElement(ContentLoader, { borderRadius: 4, height: 8, kind: "medium", marginBottom: 2, width: "60%" }),
                    React.createElement(ContentLoader, { borderRadius: 4, height: 8, kind: "medium", width: "40%" }))))));
}
function IconButton(_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return (React.createElement(Button, __assign({ bg: "white", borderRadius: 1, color: "slate", height: 20, hoverBg: "snowDark", hoverColor: "licorice", px: 0, translateOnHover: false, width: 24 }, props), children));
}
function SmallIcon(_a) {
    var paths = _a.paths;
    return (React.createElement(Icon, { display: "block", height: 20, paths: paths, viewBox: "0 0 24 20", width: 24 }));
}
var Username = styled(Heading).attrs({
    fontSize: 14,
    marginRight: 2,
    maxWidth: 180,
    truncated: true,
})(templateObject_1 || (templateObject_1 = __makeTemplateObject([""], [""])));
var ReplyBox = styled(Box).attrs({ padding: 3 })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: none;\n"], ["\n  position: absolute;\n  top: 0;\n  right: 0;\n  display: none;\n"])));
var Comment = styled(Box).attrs({
    pl: 3,
    pt: 3,
    position: 'relative',
})(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    return !props.isLoading && css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      cursor: pointer;\n\n      &:hover {\n        background-color: ", ";\n\n        ", " {\n          display: block;\n        }\n      }\n    "], ["\n      cursor: pointer;\n\n      &:hover {\n        background-color: ", ";\n\n        ", " {\n          display: block;\n        }\n      }\n    "])), themeGet('colors.snowDark'), ReplyBox);
});
function getLink(commentUrl) {
    if (commentUrl.indexOf('/project/') !== -1) {
        // This is a link to the editor which we want to navigate to client-side.
        // commentUrl is a full link including the domain. This doesn't work with
        // ui-internal/Link and it doesn't work with react-router-dom/Link. So I
        // need to split off just the path part. I'd like to use URL but IE doesn't
        // implement it. This lead to discussion around polyfilling, legacy bundles
        // and general yak shaving. So I'm just going to do it old skool.
        return getPathname(commentUrl);
    }
    // This link is to the prototype-viewer which should open in a new tab
    return commentUrl;
}
function getPathname(url) {
    var a = document.createElement('a');
    a.href = url;
    return a.pathname;
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
