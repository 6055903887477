import { zendesk } from '@marvelapp/help';
var getConfluxLink = function (_a) {
    var email = _a.email, username = _a.username;
    return "https://marvelapp.cnflx.io/public?email=".concat(email, "&fullName=").concat(username);
};
var getUserLink = function () { return '/user/account/personal'; };
function getMainLinks(_a) {
    var permissions = _a.permissions, isEnterprise = _a.isEnterprise;
    return [
        {
            name: 'Projects',
            to: '/projects',
            sublinks: [],
            kind: 'slate',
            canView: true,
        },
        {
            name: 'Folders',
            to: '/folders',
            sublinks: [],
            kind: 'slate',
            canView: permissions.canViewFolders(),
        },
        {
            name: 'Archive',
            to: '/archive',
            sublinks: [],
            kind: 'slate',
            canView: true,
        },
        {
            name: 'Team',
            to: '/company',
            kind: 'slate',
            sublinks: [
                {
                    name: 'People',
                    kind: 'slate',
                    to: '/company/people',
                    canView: true,
                },
                {
                    name: 'Groups',
                    to: '/company/groups',
                    canView: permissions.canEditGroups(),
                },
                {
                    name: 'Settings',
                    kind: 'slate',
                    to: '/company/settings',
                    canView: permissions.canEditTeamSettings(),
                },
                {
                    name: 'SSO',
                    kind: 'slate',
                    to: '/company/sso',
                    canView: !isEnterprise,
                },
            ],
            canView: permissions.canViewPeople(),
            mt: 34,
        },
        {
            name: 'Team',
            to: '/company/guest',
            kind: 'slate',
            canView: !permissions.canViewPeople(),
            sublinks: [],
            mt: 34,
        },
    ];
}
var getHelpLinks = function (_a) {
    var email = _a.email, username = _a.username, isEnterprise = _a.isEnterprise;
    var helpLinks = [
        {
            name: 'Help center',
            kind: 'slate',
            to: zendesk.URL,
        },
        {
            name: 'Feature ideas',
            to: getConfluxLink({ email: email, username: username }),
        },
    ];
    if (isEnterprise) {
        helpLinks.push({
            name: 'Contact us',
            kind: 'slate',
            to: zendesk.NEW_TICKET,
        });
    }
    return helpLinks;
};
var getUserBlockLinks = function (_a) {
    var isFree = _a.isFree, hasStaticUi = _a.hasStaticUi;
    var userLink = getUserLink();
    var userBlockLinks = [
        {
            name: 'My account',
            to: userLink,
        },
        {
            name: 'Download Sketch plugin',
            to: 'https://marvelapp.com/sketch',
            target: '_blank',
        },
    ];
    if (hasStaticUi) {
        userBlockLinks.push({
            name: 'Switch workspace',
            to: '/company/switch',
            target: '_self',
        });
    }
    if (!isFree) {
        userBlockLinks.push({
            name: 'Contact support',
            to: zendesk.NEW_TICKET,
            target: '_blank',
        });
    }
    return userBlockLinks;
};
export { getUserLink, getHelpLinks, getMainLinks, getUserBlockLinks };
