import { __assign, __rest } from "tslib";
import React, { forwardRef } from 'react';
import Loader from '../Loader';
import Position from '../Position';
import { inputKinds } from './kinds';
import { inputSizes, loaderSizes } from './sizes';
import { InputElement, LoaderWrapper, inputStyles } from './styles';
/** @type {any} */
var Input = forwardRef(function (_a, ref) {
    var _b = _a.border, border = _b === void 0 ? 'none' : _b, disabled = _a.disabled, display = _a.display, _c = _a.hasTransition, hasTransition = _c === void 0 ? true : _c, _d = _a.hideArrows, hideArrows = _d === void 0 ? false : _d, isLoading = _a.isLoading, kind = _a.kind, positionWidth = _a.positionWidth, required = _a.required, size = _a.size, tag = _a.tag, props = __rest(_a, ["border", "disabled", "display", "hasTransition", "hideArrows", "isLoading", "kind", "positionWidth", "required", "size", "tag"]);
    var stateProps = (isLoading || disabled) && __assign(__assign({}, inputKinds.disabled), { disabled: true, pointerEvents: 'none' });
    /* Order of props is important here because the last two objects contain
     * stateful props which should overwrite all the rest */
    var allProps = __assign(__assign(__assign(__assign({}, inputSizes[size]), inputKinds[kind]), props), stateProps);
    return (React.createElement(Position, { display: display, position: "relative", width: positionWidth },
        React.createElement(InputElement, __assign({ as: tag, border: border, hasTransition: hasTransition, hideArrows: hideArrows, ref: ref, required: required }, allProps, { display: display })),
        isLoading && (React.createElement(LoaderWrapper, { height: inputSizes[size].height, width: inputSizes[size].height },
            React.createElement(Loader, __assign({}, loaderSizes[size]))))));
});
/** @type {any} */
Input.defaultProps = {
    disabled: false,
    display: 'block',
    fontFamily: 0,
    fontWeight: 400,
    isLoading: false,
    kind: 'default',
    size: 1,
};
export default Input;
export { inputStyles };
