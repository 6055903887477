/* TODO: As I don't want to add @marvelapp/ui as a dep, but I need to use this
         util, I'm hardcoding it for now until we have marvel-core */
import { __read } from "tslib";
export function hexToRGB(hex, alpha) {
    var r = parseInt(hex.slice(1, 3), 16);
    var g = parseInt(hex.slice(3, 5), 16);
    var b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
        return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(alpha, ")");
    }
    return "rgb(".concat(r, ", ").concat(g, ", ").concat(b, ")");
}
export function rgbToHex(rgb) {
    var _a = __read(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/.exec(rgb), 4), 
    // eslint-disable-next-line no-unused-vars
    _ = _a[0], r = _a[1], g = _a[2], b = _a[3];
    return "#".concat([r, g, b]
        .map(function (c) {
        var hex = parseInt(c).toString(16);
        return hex.length === 1 ? "0".concat(hex) : hex;
    })
        .join('')).toUpperCase();
}
