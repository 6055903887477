import { __assign, __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { ABTest, UserRole } from '@marvelapp/core';
import { useABTest, useCurrentSubscription, useCurrentUser, } from '@marvelapp/marvel-3-application';
import { getUserInfo } from '../helpers';
import editor from '../roles/editor';
import externalReviewer from '../roles/external-reviewer';
import internalReviewer from '../roles/internal-reviewer';
import owner from '../roles/owner';
import { getClosedUpsells, setClosedUpsell } from '../storage';
import { useStunning } from '../stunning';
import UpsellRenderer from './UpsellRenderer';
function Upsell(_a) {
    var position = _a.position;
    var _b = __read(useState(null), 2), closed = _b[0], setClosed = _b[1];
    var user = useCurrentUser();
    var _c = __read(useCurrentSubscription(), 1), subscription = _c[0];
    var isWhiteboardEnabled = useABTest(ABTest.WHITEBOARD_UI);
    var userInfo = getUserInfo(user, subscription, isWhiteboardEnabled);
    var stunning = useStunning();
    useEffect(function () {
        setClosed(getClosedUpsells({ userPk: user.pk }));
    }, [user.pk]);
    var onClickClose = function (_a) {
        var identifier = _a.identifier, daysUntilReshownAfterClose = _a.daysUntilReshownAfterClose;
        setClosed(setClosedUpsell({
            identifier: identifier,
            userPk: user.pk,
            position: position,
            daysUntilReshownAfterClose: daysUntilReshownAfterClose,
        }));
    };
    if (closed === null) {
        return null;
    }
    var props = {
        onClickClose: onClickClose,
        closed: closed,
        position: position,
        userInfo: userInfo,
        stunning: stunning,
    };
    switch (user.role) {
        case UserRole.Owner:
            return (React.createElement(UpsellRenderer, __assign({ upsells: owner({ userInfo: userInfo, stunning: stunning })[position] }, props)));
        case UserRole.Admin:
            return (React.createElement(UpsellRenderer, __assign({ upsells: owner({ userInfo: userInfo, stunning: stunning })[position] }, props)));
        case UserRole.Editor:
            return (React.createElement(UpsellRenderer, __assign({ upsells: editor({ userInfo: userInfo, stunning: stunning })[position] }, props)));
        case UserRole.InternalReviewer:
            return (React.createElement(UpsellRenderer, __assign({ upsells: internalReviewer({ userInfo: userInfo })[position] }, props)));
        case UserRole.ExternalReviewer:
            return (React.createElement(UpsellRenderer, __assign({ upsells: externalReviewer({ userInfo: userInfo })[position] }, props)));
        default:
            return null;
    }
}
export default Upsell;
