import { __read } from "tslib";
import React, { useEffect, useState } from 'react';
import { trackPlayVideo } from '../../tracking';
var WistiaEmbed = function (_a) {
    var link = _a.link, embedOptions = _a.embedOptions, onHasData = _a.onHasData;
    var _b = __read(useState(undefined), 2), video = _b[0], setVideo = _b[1];
    var parts = link ? link.split('/') : [];
    var id = parts[parts.length - 1];
    useEffect(function () {
        if (!document.getElementById('wistia_script')) {
            var wistiaScript = document.createElement('script');
            wistiaScript.id = 'wistia_script';
            wistiaScript.type = 'text/javascript';
            wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
            wistiaScript.async = true;
            document.body.appendChild(wistiaScript);
        }
        if (video) {
            video.replaceWith(id, { transition: 'crossfade' });
            video.bind('play', function () {
                trackPlayVideo({ link: link });
            });
        }
    }, [id, link, video]);
    window._wq = window._wq || [];
    window._wq.push({
        id: id,
        options: embedOptions,
        onHasData: function (v) {
            setVideo(v);
            var name = video.name();
            onHasData({ name: name || '' });
        },
    });
    return (React.createElement("div", { className: "wistia_responsive_padding", style: { padding: '56.25% 0 0 0', position: 'relative' } },
        React.createElement("div", { className: "wistia_responsive_wrapper", style: {
                height: '100%',
                left: '0',
                position: 'absolute',
                top: '0',
                width: '100%',
            } },
            React.createElement("div", { className: "wistia_embed wistia_async_".concat(id, " videoFoam=true settingsControl=false"), style: { height: '100%', width: '100%' } }, "\u00A0"))));
};
export default WistiaEmbed;
